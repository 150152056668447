import { combineReducers } from "redux";
import productsReducer from "./productReducers/productsReducer";
import collectionsReducer from "./productReducers/collectionsReducer";
import subCollectionsReducer from "./productReducers/subCollectionsReducer";
import customProductsReducer from "./productReducers/customProductsReducer";
import singleProductReducer from "./productReducers/singleProductReducer";
import checkoutReducer from "./checkoutReducers/checkoutReducer";
import cartReducer from "./cartReducers/cartReducer";
import vendorReducer from "./vendorReducers/vendorReducer";
import shopReducer from "./shopReducers/shopReducer";
import searchProductsReducer from "./productReducers/searchProductsReducer";
import productVariationsReducer from "./productReducers/productVariationsReducer";
import recommendedProductsReducer from "./productReducers/recommendedProductsReducer";

export default combineReducers({
    products: productsReducer,
    collections: collectionsReducer,
    subCollections: subCollectionsReducer,
    customProducts: customProductsReducer,
    singleProduct: singleProductReducer,
    checkout: checkoutReducer,
    cart: cartReducer,
    vendor: vendorReducer,
    shop: shopReducer,
    search: searchProductsReducer,
    productVariations: productVariationsReducer,
    recommendedProducts: recommendedProductsReducer
});