import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import TextTruncate from "react-text-truncate";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import { withTranslation } from "react-i18next";
import { FaAngleRight, FaSearch } from "react-icons/fa";

import TabBar from "../tabBar/tabBar";
import EmptySearch from "../infoScreens/emptySearch";
import ModalWithBgImg from "../modals/radicoolModal/index";

import { fetchProductsByCollection, fetchSearchCategoryProducts } from "../../actions/products";
import { fetchSubCollections, fetchSubCollectionsWithTopOffers } from "../../actions/categories/index";
import { addRemoveItem } from "../../actions/cart";
import history from "../history";
import { DynamicPriceFontSize, onSaleTo, calcPercentage, removeUnnecessaryDecimals } from "../../globalFunctions";
import { fetchCheckout } from "../../actions/checkout";
import analyticsEvents from "../../analyticsEvents";

import noImg from "../catalog/assets/img/no_img.png";

import "react-loading-skeleton/dist/skeleton.css";
import "./assets/css/category.css";

class Category extends React.Component {

    collectionId = null;
    targetElement = null;
    searchParams = null;

    constructor() {
        super();
        this.state = {
            page_number: 1,
            searchValue: "",
            searching: false,
            show_popup: false
        }
    }

    async componentDidMount() {
        this.searchParams = new URLSearchParams(window.location.search);
        if (this.searchParams && this.searchParams.has("show-popup")) {
            this.setState({
                show_popup: true
            });
        }
        this.collectionId = this.props.match.params.id;
        this.fromWhere = null;
        const { cart, shop } = this.props;
        const onSale = shop.data.category?.id === 3 ? false : true;
        await this.props.fetchProductsByCollection(this.collectionId, 0, 10, onSale);
        if (cart.lineItems.length === 0) {
            this.props.fetchCheckout();
        }
        if ((shop?.data?.store_type === "Woocommerce") && this.collectionId) {
            const query = new URLSearchParams(window.location.search);
            if (query.get("from") && (query.get("from") === "home")) {
                this.fromWhere = query.get("from");
                this.props.fetchSubCollectionsWithTopOffers(this.collectionId);
            } else {
                this.props.fetchSubCollections(this.collectionId);
            }
        }
        if (localStorage.getItem("search_category") && (shop?.data?.store_type === "Woocommerce")) {
            const searchValue = JSON.parse(localStorage.getItem("search_category"));
            this.setState({
                searching: true,
                searchValue
            }, () => {
                this.props.fetchSearchCategoryProducts(searchValue, this.collectionId, 0, onSale);
            });
        }
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        this.props.fetchProductsByCollection(this.collectionId, false);
        this.props.fetchSubCollections(this.collectionId, false);
    }

    fetchMoreProducts = () => {
        this.setState((state) => ({
            page_number: state.page_number + 1
        }), () => {
            const { shop } = this.props;
            const onSale = shop.data.category?.id === 3 ? false : true;
            this.props.fetchProductsByCollection(this.collectionId, this.state.page_number, 10, onSale);
        });
    }

    renderProducts() {
        const SkeletonData = [1, 2, 3, 4, 5, 6].map((i) => {
            return {
                id: i
            }
        }),
            { LOADING, ERROR, products_data } = this.props.products,
            products = LOADING ? SkeletonData : products_data.products || [],
            { cart, shop } = this.props,
            { custom_theme, shop_color, discount_price_color, reqular_price_color } = shop.data;

        if (LOADING) {
            return <div>Loading...</div>;
        }
        if (ERROR) {
            return <div>Error while getting products</div>;
        }
        if (this.collectionId && (products.length > 0)) {
            return products.map((prod) => {
                if (prod.variants) {
                    prod.variants[0].product = {
                        collections: [{
                            id: this.collectionId,
                            title: products_data.category_title
                        }]
                    }
                }
                const isOnTheCart = (!LOADING && cart.lineItems && (cart.lineItems.length > 0)) ? cart.lineItems.find(p => p.variant.id === prod.variants[0].id) : false;
                return (
                    <div className="categoryProductBox" key={prod.id}>
                        <div className="body_container">
                            <Link
                                className="productLink"
                                to={`/product/${prod.id}/${this.collectionId}`}
                                style={LOADING ? { pointerEvents: "none" } : null}
                            >
                                <div className="productCategoryTitle">
                                    {LOADING
                                        ? (<Skeleton containerClassName="category_title_skeleton" />)
                                        : (
                                            <>
                                                <p className="vendor">{prod.vendor || ""}</p>
                                                <TextTruncate
                                                    line={2}
                                                    truncateText="..."
                                                    text={prod.title}
                                                    className="title"
                                                />
                                            </>
                                        )
                                    }
                                </div>
                                <div className="categoryImg">
                                    {LOADING
                                        ? (<Skeleton containerClassName="category_image_skeleton" />)
                                        : (
                                            <>
                                                <img
                                                    src={prod?.images[0]?.src || noImg}
                                                    alt="category_image"
                                                />
                                                {(prod.date_on_sale_from_gmt || prod.date_on_sale_to_gmt) && (
                                                    onSaleTo(
                                                        {
                                                            from: prod.date_on_sale_from_gmt,
                                                            to: prod.date_on_sale_to_gmt
                                                        },
                                                        shop.data
                                                    )
                                                )}
                                            </>
                                        )
                                    }
                                </div>
                            </Link>
                        </div>
                        <div className="categoryPriceContainer">
                            <div className="vendor-price-wrapper">
                                {LOADING
                                    ? (<Skeleton className="category_price_skeleton" />)
                                    : (
                                        <>
                                            {((prod.variants[0].compareAtPrice != null) && (prod.variants[0].compareAtPrice > prod.variants[0].price)) ? (
                                                <div
                                                    className="discountPercentage"
                                                    style={(custom_theme && reqular_price_color) ? { backgroundColor: reqular_price_color } : {}}
                                                >
                                                    {calcPercentage(prod.variants[0].compareAtPrice, prod.variants[0].price)}
                                                </div>
                                            ) : (
                                                <div style={{ height: "30px" }}></div>
                                            )}
                                            <div
                                                className="productPrice"
                                                style={(custom_theme && discount_price_color) ? { backgroundColor: discount_price_color } : {}}
                                            >
                                                {DynamicPriceFontSize(
                                                    46,
                                                    removeUnnecessaryDecimals(prod.variants[0].price),
                                                    17
                                                )}
                                                <span>{prod.variants[0].priceV2.currencyCode}</span>
                                            </div>
                                            {((prod.variants[0].compareAtPrice != null) && (prod.variants[0].compareAtPrice > prod.variants[0].price)) && (
                                                <div
                                                    className="comparePrice"
                                                    style={(custom_theme && reqular_price_color) ? { backgroundColor: reqular_price_color } : {}}
                                                >
                                                    {DynamicPriceFontSize(
                                                        32,
                                                        removeUnnecessaryDecimals(prod.variants[0].compareAtPrice),
                                                        11
                                                    )}
                                                    <span>{prod.variants[0].priceV2.currencyCode}</span>
                                                </div>
                                            )}
                                        </>
                                    )
                                }
                            </div>
                            {LOADING
                                ? (
                                    <div className="category-toCart-skeleton-wrapper">
                                        <Skeleton containerClassName="category-addToCart-skeleton" />
                                    </div>
                                )
                                : (
                                    <div
                                        className={`categoryCartButton ${isOnTheCart ? "added" : ""}`}
                                        style={(custom_theme && shop_color && isOnTheCart) ? { background: shop_color } : {}}
                                    >
                                        <button
                                            className="addButton"
                                            onClick={() => {
                                                if (isOnTheCart) {
                                                    analyticsEvents.removeProductFromCart(shop.data.id, prod.id);
                                                    this.props.addRemoveItem("remove", isOnTheCart);
                                                } else {
                                                    analyticsEvents.addProductToCart(shop.data.id, 2, prod.id);
                                                    this.props.addRemoveItem("add", { product: prod, quantity: 1 });
                                                }
                                            }}
                                        >
                                            <div
                                                className={isOnTheCart ? "checkmark_icon" : "plus_icon"}
                                                style={(custom_theme && shop_color && !isOnTheCart) ? { backgroundColor: shop_color } : {}}
                                            ></div>
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            });
        }
        return <div>No products</div>;
    }

    renderSubCollections = () => {
        const { subCollections_data, LOADING } = this.props.subCollections,
            SkeletonData = [1, 2].map((i) => {
                return {
                    id: i,
                    skeleton: true,
                    products: [i]
                }
            }),
            subCollections = (subCollections_data?.subCollections.length === 0) ? SkeletonData : subCollections_data?.subCollections.slice(0, 2) || [];
        return (
            subCollections.map((collection) => {
                return (
                    <div
                        key={collection.id}
                        className="subcategory_wrapper"
                        onClick={() => {
                            if (!LOADING) {
                                window.location.href = `${collection.id}${this.fromWhere ? "?from=" + this.fromWhere : ""}`;
                                console.log(window)
                            }
                            return false;
                        }}
                    >
                        <div className="subcategory_title" style={LOADING ? { minHeight: "auto" } : {}}>
                            {LOADING
                                ? (<Skeleton containerClassName="title_skeleton" />)
                                : (
                                    <TextTruncate
                                        line={2}
                                        truncateText="..."
                                        text={collection.title}
                                    />
                                )
                            }
                        </div>
                        <div className="subcategory_img_wrapper">
                            {LOADING
                                ? (
                                    <Skeleton
                                        containerClassName="image_skeleton"
                                    />
                                )
                                : (
                                    <img
                                        className="subcategory_img"
                                        src={collection.image ? collection.image.src : noImg}
                                        alt="Subcategory"
                                    />
                                )
                            }
                        </div>
                    </div>
                );
            })
        )
    }

    renderSearchProducts() {
        const SkeletonData = [1, 2, 3, 4, 5, 6].map((i) => {
            return {
                id: i
            }
        }),
            { LOADING, ERROR, search_data } = this.props.search,
            products = LOADING ? SkeletonData : search_data.products || [],
            { cart, shop } = this.props,
            { custom_theme, shop_color, discount_price_color, reqular_price_color } = shop.data;

        if (!LOADING && ERROR) {
            return <div>Error while getting products</div>;
        } else if (this.collectionId && (products.length > 0)) {
            return products.map((prod) => {
                const isOnTheCart = (!LOADING && cart.lineItems && (cart.lineItems.length > 0)) ? cart.lineItems.find(p => p.variant.id === prod.variants[0].id) : false;
                return (
                    <div className="categoryProductBox" key={prod.id}>
                        <div className="body_container">
                            <Link
                                className="productLink"
                                to={`/product/${prod.id}/${this.collectionId}`}
                                style={LOADING ? { pointerEvents: "none" } : null}
                            >
                                <div className="productCategoryTitle">
                                    {LOADING
                                        ? (<Skeleton containerClassName="category_title_skeleton" />)
                                        : (
                                            <>
                                                <p className="vendor">{prod.vendor || ""}</p>
                                                <TextTruncate
                                                    line={2}
                                                    truncateText="..."
                                                    text={prod.title}
                                                    className="title"
                                                />
                                            </>
                                        )
                                    }
                                </div>
                                <div className="categoryImg">
                                    {LOADING
                                        ? (<Skeleton containerClassName="category_image_skeleton" />)
                                        : (
                                            <>
                                                <img
                                                    src={prod?.images[0]?.src || noImg}
                                                    alt="category_image"
                                                />
                                                {(prod.date_on_sale_from_gmt || prod.date_on_sale_to_gmt) && (
                                                    onSaleTo(
                                                        {
                                                            from: prod.date_on_sale_from_gmt,
                                                            to: prod.date_on_sale_to_gmt
                                                        },
                                                        shop.data
                                                    )
                                                )}
                                            </>
                                        )
                                    }
                                </div>
                            </Link>
                        </div>
                        <div className="categoryPriceContainer">
                            <div className="vendor-price-wrapper">
                                {LOADING
                                    ? (<Skeleton className="category_price_skeleton" />)
                                    : (
                                        <>
                                            {((prod.variants[0].compareAtPrice != null) && (prod.variants[0].compareAtPrice > prod.variants[0].price)) ? (
                                                <div
                                                    className="discountPercentage"
                                                    style={(custom_theme && reqular_price_color) ? { backgroundColor: reqular_price_color } : {}}
                                                >
                                                    {calcPercentage(prod.variants[0].compareAtPrice, prod.variants[0].price)}
                                                </div>
                                            ) : (
                                                <div style={{ height: "30px" }}></div>
                                            )}
                                            <div
                                                className="productPrice"
                                                style={(custom_theme && discount_price_color) ? { backgroundColor: discount_price_color } : {}}
                                            >
                                                {DynamicPriceFontSize(
                                                    46,
                                                    removeUnnecessaryDecimals(prod.variants[0].price),
                                                    17
                                                )}
                                                <span>{prod.variants[0].priceV2.currencyCode}</span>
                                            </div>
                                            {((prod.variants[0].compareAtPrice != null) && (prod.variants[0].compareAtPrice > prod.variants[0].price)) && (
                                                <div
                                                    className="comparePrice"
                                                    style={(custom_theme && reqular_price_color) ? { backgroundColor: reqular_price_color } : {}}
                                                >
                                                    {DynamicPriceFontSize(
                                                        32,
                                                        removeUnnecessaryDecimals(prod.variants[0].compareAtPrice),
                                                        11
                                                    )}
                                                    <span>{prod.variants[0].priceV2.currencyCode}</span>
                                                </div>
                                            )}
                                        </>
                                    )
                                }
                            </div>
                            {LOADING
                                ? (
                                    <div className="category-toCart-skeleton-wrapper">
                                        <Skeleton containerClassName="category-addToCart-skeleton" />
                                    </div>
                                )
                                : (
                                    <div
                                        className={`categoryCartButton ${isOnTheCart ? "added" : ""}`}
                                        style={(custom_theme && shop_color && isOnTheCart) ? { background: shop_color } : {}}
                                    >
                                        <button
                                            className="addButton"
                                            onClick={() => {
                                                if (isOnTheCart) {
                                                    analyticsEvents.removeProductFromCart(shop.data.id, prod.id);
                                                    this.props.addRemoveItem("remove", isOnTheCart);
                                                } else {
                                                    analyticsEvents.addProductToCart(shop.data.id, 2, prod.id);
                                                    this.props.addRemoveItem("add", { product: prod, quantity: 1 });
                                                }
                                            }}
                                        >
                                            <div
                                                className={isOnTheCart ? "checkmark_icon" : "plus_icon"}
                                                style={(custom_theme && shop_color && !isOnTheCart) ? { backgroundColor: shop_color } : {}}
                                            ></div>
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            });
        } else {
            return <EmptySearch
                shop_color={shop_color}
                custom_theme={custom_theme}
                t={this.props.t}
            />;
        }
    }

    fetchMoreSearchProducts = () => {
        const { shop, search } = this.props;
        this.setState((state) => ({
            page_number: (shop.data.store_type === "Woocommerce") ? state.page_number + 1 : search.search_data.total_pages
        }), () => {
            const onSale = shop.data.category?.id === 3 ? false : true;
            this.props.fetchSearchCategoryProducts(this.state.searchValue, this.collectionId, this.state.page_number, onSale);
        });
    }

    render() {
        const { products, subCollections, shop, t, search } = this.props,
            { shop_color } = shop.data,
            change_color = (shop.data.custom_theme && shop_color) || false,
            { searchValue, searching, show_popup } = this.state;
        return (
            <>
                <div className="category_container">
                    <div className="category-header">
                        <div className="go_back" onClick={() => history.push("/")}>
                            <div
                                className="angle_left_icon"
                                style={change_color ? { background: shop_color } : {}}
                            ></div>
                            <span
                                style={change_color ? { color: shop_color } : {}}
                            >
                                {t("btn_back")}
                            </span>
                        </div>
                    </div>
                    <div className="body_wrapper">
                        {((shop?.data?.store_type === "Woocommerce") && this.collectionId) && (
                            (subCollections.LOADING || subCollections?.subCollections_data?.subCollections?.length > 0) && (
                                <div className="sub_categories_container">
                                    <div className="title_wrapper">
                                        <h2 className="title">{t("sub_categories_title")}</h2>
                                        {(subCollections?.subCollections_data?.subCollections?.length > 2) && (
                                            <Link
                                                to={`/subcategories/${this.collectionId}`}
                                                className="all_subcategories"
                                                style={(change_color && shop_color) ? { color: shop_color } : {}}
                                            >
                                                {t("see_all")}<FaAngleRight />
                                            </Link>
                                        )}
                                    </div>
                                    <div className="sub_categories">
                                        {this.renderSubCollections()}
                                    </div>
                                </div>
                            )
                        )}
                        <h2 className="categoriesTitle">
                            {products.LOADING
                                ? (<Skeleton containerClassName="category_title_skeleton" />)
                                : (
                                    products.products_data.category_title
                                        ? products.products_data.category_title.replace("HeysMed-", "")
                                        : ''
                                )
                            }
                        </h2>
                        {(shop?.data?.store_type === "Woocommerce") && (
                            <div className="search_box_container">
                                <input
                                    type="text"
                                    className="search_box"
                                    name="search"
                                    placeholder={t("search_products")}
                                    onChange={(e) => this.setState({ searchValue: e.target.value })}
                                    value={searchValue}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            const onSale = shop.data.category?.id === 3 ? false : true;
                                            if (searchValue.length > 0) {
                                                this.setState({
                                                    searching: true
                                                }, () => {
                                                    analyticsEvents.productSearched(shop.data.id);
                                                    this.props.fetchSearchCategoryProducts(searchValue, this.collectionId, 0, onSale);
                                                    localStorage.setItem("search_category", JSON.stringify(searchValue));
                                                });
                                            } else {
                                                this.setState({
                                                    searching: false
                                                }, () => {
                                                    localStorage.removeItem("search_category");
                                                    if (products?.products_data?.products?.length === 0) {

                                                        this.props.fetchProductsByCollection(this.collectionId, 0, 10, onSale);
                                                    }
                                                });
                                            }
                                        }
                                    }}
                                />
                                <FaSearch className="search_icon" />
                            </div>
                        )}
                        {searching ? (
                            <InfiniteScroll
                                dataLength={search.LOADING ? 0 : search?.search_data?.products?.length || 0}
                                next={this.fetchMoreSearchProducts}
                                hasMore={(search.search_data.total_pages !== 0)}
                                loader={<div className="products_loader"></div>}
                                scrollThreshold={1}
                                className="categoryWrapper"
                            >
                                {this.renderSearchProducts()}
                            </InfiniteScroll>
                        ) : (
                            <InfiniteScroll
                                dataLength={products?.products_data?.products?.length || 0}
                                next={this.fetchMoreProducts}
                                hasMore={(products.products_data.total_pages !== 0)}
                                loader={<div className="products_loader"></div>}
                                scrollThreshold={1}
                                className="categoryWrapper"
                            >
                                {this.renderProducts()}
                            </InfiniteScroll>
                        )}
                    </div>
                </div>
                <TabBar />
                {show_popup && (
                    <ModalWithBgImg
                        has_products={(products.products_data.products.length > 0) ? true : false}
                        closeModal={() => this.setState({ show_popup: false })}
                        product={this.searchParams.get("show-popup")}
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        products: state.products,
        subCollections: state.subCollections,
        cart: state.cart,
        checkout: state.checkout,
        shop: state.shop,
        search: state.search
    };
};

export default connect(mapStateToProps, {
    fetchProductsByCollection,
    fetchSubCollectionsWithTopOffers,
    fetchSubCollections,
    addRemoveItem,
    fetchCheckout,
    fetchSearchCategoryProducts
})(withRouter(withTranslation()(Category)));