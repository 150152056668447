import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
    getToken,
    getMessaging,
    onMessage,
    isSupported
} from "firebase/messaging";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyD_pkPr0V4Du_S0zc_TZOzPnFuTA3ShuXk",
    authDomain: "feather-cbb74.firebaseapp.com",
    projectId: "feather-cbb74",
    storageBucket: "feather-cbb74.appspot.com",
    messagingSenderId: "721563980499",
    appId: "1:721563980499:web:49b52b3c0beceea8fe7a26",
    measurementId: "G-8E1NQZV6G3"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const messaging = isSupported().then((res) => {
    if (res) {
        const messagingApp = getMessaging(app);
        return {
            onMsgListener: onMessage,
            getMsg: messagingApp,
            getMessagingToken: async () => {
                let token = null;
                await getToken(messagingApp, { vapidKey: process.env.REACT_APP_MESSEGING_KEY }).then((currentToken) => {
                    if (currentToken) {
                        token = currentToken;
                    } else {
                        console.error('No registration token available. Request permission to generate one.');
                    }
                }).catch((err) => {
                    console.error('An error occurred while retrieving token. ', err);
                });
                return token;
            }
        }
    } else {
        return null;
    }
});