const initialState = {
    subCollections_data: {
        total_pages: 0,
        subCollections: []
    },
    LOADING: true,
}

const subCollectionsReducer = (state = initialState, action) => {
    if (action.type === "FETCH_SUBCOLLECTIONS"){
        return {...state, subCollections_data: action.payload };
    }

    if (action.type === "FETCHING_SUBCOLLECTIONS"){
        return { ...state, LOADING: action.payload };
    }

    return state;
}

export default subCollectionsReducer;