import React from "react";
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { db } from "../../../firebase";
import moment from "moment";
import { isValidPhoneNumber } from "react-phone-number-input/max";
import Input, { getCountryCallingCode } from "react-phone-number-input/input";

import { generateUUID } from "../../../apis/apiData";
import analyticsEvents from "../../../analyticsEvents";

import GifIcon from "./img/gift-icon.svg";
import CloseIcon from "./../../images/close-icon.svg";
import SuccessParticipateIcon from "./img/success-participate-icon.svg";
import ErrorIcon from "./img/error-icon.svg";

import "react-phone-number-input/style.css";
import "./style.css";

export default class GiveawayModal extends React.Component {

    constructor() {
        super();
        this.state = {
            phoneNumber: "",
            invalidNumber: false,
            showSuccessContent: false,
            showRegisteredError: false,
            showOtherError: false
        }
    }

    componentDidMount() {
        document.body.style.overflow = "hidden";
    }

    componentWillUnmount() {
        document.body.style.overflow = "auto";
    }

    userId = () => {
        let user_id = localStorage.getItem("UUID");
        if (!user_id || user_id.length < 3) {
            user_id = generateUUID();
        } else {
            user_id = JSON.parse(user_id);
        }

        return user_id;
    }

    onNumberChange = (value) => {
        const validation = /^[+389]{4}[7]{1}[0-9]{1}[\s|-]{0,1}[0-9]{3}[\s|-]{0,1}[0-9]{3}$/g;
        let invalidNumber = false;
        if (!value) {
            invalidNumber = true;
        } else if (!validation.test(value) || !isValidPhoneNumber(value)) {
            invalidNumber = true;
        }
        this.setState({
            invalidNumber,
            phoneNumber: value
        });
    }

    savePhoneNumber = () => {
        const { invalidNumber, phoneNumber } = this.state;
        const { shopId, giveaway } = this.props;
        analyticsEvents.giveawayAction(this.userId(), shopId, giveaway.giveaway_id, "enterGiveaway");
        if(invalidNumber || !phoneNumber) {
            this.setState({
                invalidNumber: true
            });
        } else {
            this.checkPhoneNumber(phoneNumber);
        }
    }

    doNotParticipate = async () => {
        this.registerUser("", true);
    }

    tryAgain = () => {
        const { shopId, giveaway } = this.props;
        analyticsEvents.giveawayReshown(this.userId(), shopId, giveaway.giveaway_id);
        this.setState({
            showRegisteredError: false,
            showOtherError: false
        });
    }

    checkPhoneNumber = (phone_number) => {
        const giveaway_id = this.props?.giveaway?.giveaway_id;
        getDocs(query(
            collection(db, "giveaway-users"),
            where("giveaway_id", "==", giveaway_id),
            where("phone_number", "==", phone_number)
        ))
        .then((querySnapshop) => {
            if (querySnapshop.empty) {
                this.registerUser(phone_number);
            } else {
                analyticsEvents.giveawayAction(this.userId(), this.props.shopId, giveaway_id, "enterGiveawayFailOnPhoneValidation");
                this.setState({
                    showRegisteredError: true,
                    showOtherError: false
                });
            }
        })
        .catch((e) => {
            console.error(e);
            analyticsEvents.giveawayAction(this.userId(), this.props.shopId, giveaway_id, "enterGiveawayFail");
            this.setState({
                showOtherError: true,
                showRegisteredError: false
            });
        });
    }

    registerUser = (phone_number, not_participate = false) => {
        const shopId = this.props?.shopId;
        const giveaway_id = this.props?.giveaway?.giveaway_id;
        const user_id = this.userId();
        if (!giveaway_id) {
            console.error("Giveaway id is missing");
        } else if (!shopId) {
            console.error("Shop id is missing");
        } else {
            setDoc(doc(
                collection(db, "giveaway-users"),
                `${shopId}-${giveaway_id}-${user_id}`
            ), {
                user_id,
                giveaway_id,
                phone_number,
                participating: not_participate ? false : true,
                date_answered: moment().toISOString()
            })
            .then(() => {
                if (not_participate) {
                    analyticsEvents.giveawayAction(user_id, shopId, giveaway_id, "giveawayIsDismissed");
                    this.props.closeModal();
                } else {
                    analyticsEvents.giveawayAction(user_id, shopId, giveaway_id, "enterGiveawaySuccess");
                    this.setState({
                        showSuccessContent: true
                    });
                }
            })
            .catch((e) => {
                console.error(e);
                analyticsEvents.giveawayAction(user_id, shopId, giveaway_id, "enterGiveawayFail");
                this.setState({
                    showOtherError: true
                });
            });
        }
    }

    render() {
        const { closeModal, translate, giveaway, shopId } = this.props;
        const { phoneNumber, invalidNumber, showSuccessContent, showRegisteredError, showOtherError } = this.state;
        return (
            <div id="giveaway_modal_overlay">
                <div className={`modal_wrapper ${(showRegisteredError || showOtherError) ? "error" : ""}`}>
                    <div className="header_wrapper">
                        <div className={`image ${(showRegisteredError || showOtherError) ? "error" : ""}`}>
                            <img src={
                                showSuccessContent
                                ? SuccessParticipateIcon
                                : (showRegisteredError || showOtherError)
                                    ? ErrorIcon
                                    : GifIcon
                                }
                                alt="Gift icon"
                            />
                        </div>
                        <div
                            className="close_btn"
                            onClick={() => {
                                analyticsEvents.giveawayAction(this.userId(), shopId, giveaway.giveaway_id, "giveawayIsClosed");
                                closeModal();
                            }}
                        >
                            <img src={CloseIcon} alt="Close button" />
                        </div>
                    </div>
                    <div className="body_wrapper">
                        {showSuccessContent ? (
                            <>
                                <div
                                    className="success_body_title"
                                    dangerouslySetInnerHTML={{__html: translate("giveaway_success_title")}}
                                >
                                </div>
                                <div className="success_body_text">{translate("giveaway_success_description")}</div>
                            </>
                        ) : (showRegisteredError || showOtherError) ? (
                            <>
                                <div
                                    className="error_body_title"
                                >
                                    {translate("giveaway_error_title")}
                                </div>
                                <div className="error_body_text">
                                    {showOtherError ? translate("giveaway_other_error_description") : translate("giveaway_registered_error_description")}
                                </div>
                                <button
                                    className="try_again_btn"
                                    onClick={this.tryAgain}
                                >
                                    {translate("btn_try_again")}
                                </button>
                            </>
                        ) : (
                            <>
                                <div
                                    className="body_title"
                                >
                                    {translate("giveaway_welcome_title")}
                                </div>
                                <div className="body_text">{giveaway?.description}</div>
                                <div className="content_wrapper">
                                    <div className="input_wrapper">
                                        <div className="input_container">
                                            <div
                                                className="prefix_number"
                                                style={(phoneNumber && (phoneNumber.length > 0)) ? {color: "#000000"} : {}}
                                            >+{getCountryCallingCode("MK")} |
                                            </div>
                                            <Input
                                                value={phoneNumber}
                                                onChange={(e) => {
                                                    this.onNumberChange(e);
                                                }}
                                                placeholder="7x xxx xxx"
                                                countries={["MK"]}
                                                defaultCountry="MK"
                                                international={true}
                                                maxLength={10}
                                                onFocus={() => analyticsEvents.giveawayAction(this.userId(), shopId, giveaway.giveaway_id, "giveawayPhoneStartEnter")}
                                                onBlur={() => analyticsEvents.giveawayAction(this.userId(), shopId, giveaway.giveaway_id, "giveawayPhoneIsEntered")}
                                            />
                                        </div>
                                        {invalidNumber && (
                                            <p className="error_message">{translate("giveaway_invalid_phone_number")}</p>
                                        )}
                                        <div className="info_message" dangerouslySetInnerHTML={{__html: translate("giveaway_warning_text")}}></div>
                                    </div>
                                    <div className="buttons_wrapper">
                                        <button
                                            className="save_btn"
                                            onClick={this.savePhoneNumber}
                                            disabled={!phoneNumber || (phoneNumber.length === 0) || invalidNumber}
                                        >
                                            {translate("btn_enter")}
                                        </button>
                                        <div
                                            className="dont_participate"
                                            onClick={this.doNotParticipate}
                                        >
                                            {translate("giveaway_dont_participate_text")}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
