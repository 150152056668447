import React from "react";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { connect } from "react-redux";
import { Router, Route, Switch } from "react-router";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { t } from "i18next";
import { withAlert } from "react-alert";
import { collection, doc, getDoc, getDocs, limit, query, where } from "firebase/firestore";
import Cookies from "universal-cookie";

// import moment locales file
import "moment/min/locales";

import CatalogDetails from "./components/catalog/index";
import Product from "./components/product/index";
import Category from "./components/category/index";
import Cart from "./components/cart/index";
import HomePage from "./components/landingPage/index";
import Info from "./components/info/index";
import SearchPage from "./components/searchPage/index";
import Conditions from "./components/landingPage/conditions";
import PrivacyPolicy from "./components/landingPage/privacyPolicy";
import CheckoutForm from "./components/cart/checkout/checkoutForm";
import OrderComplete from "./components/cart/checkout/orderComplete";
import Categories from "./components/categories/categories";
import subCategories from "./components/subCategories/index";
import orderArchive from "./components/orderArchive";
import archivedItems from "./components/orderArchive/archivedItems";
import DownloadAppBanner from "./components/banner/downloadApp";
import GiveawayModal from "./components/modals/giveawayModal/index";

import { fetchCheckout } from "./actions/checkout/index";
import { fetchProductsCustom } from "./actions/products/index";
import { fetchCollectionsWithTopOffers, fetchCollections } from "./actions/categories/index";
import history from "./components/history";
import { fetchShop } from "./actions/shop/index";
import { addToCart, CleanCart } from "./actions/cart/index";
import { setVendor } from "./actions/vendor/index";
import { setLanguage } from "./globalFunctions";
import {
  getAPIToken,
  notificationSubscribe,
  subscribeToTopic,
  unsubscribeFromTopic,
  NotificatinCheckoutTokens,
  generateUUID
} from "./apis/apiData";
import analyticsEvents from "./analyticsEvents";
import {
  messaging,
  db
} from "./firebase";

import "react-confirm-alert/src/react-confirm-alert.css";
import "./App.css";

const cookies = new Cookies();

class App extends React.Component {
  prevLng = sessionStorage.getItem("lng") || "en";
  constructor(props) {
    super(props);

    this.state = {
      showBanner: {
        show: false,
        device: "android"
      },
      showGiveawayModal: false,
      giveaway: null
    };

    this.checkLoading = this.checkLoading.bind(this);
    this.getCartCount = this.getCartCount.bind(this);
    this.checkLoadingCatalog = this.checkLoadingCatalog.bind(this);
  }

  async componentDidMount() {
    let UUID = localStorage.getItem("UUID");
    const { id, language } = this.props.shop?.data;
    const queryParams = new URLSearchParams(window.location.search);
    const shopId = queryParams.get("id");

    if (!UUID || (UUID.length < 3)) {
      UUID = generateUUID();
    }

    if ((id || shopId) && UUID && (window.location.pathname === "/")) {
      if (shopId) {
        analyticsEvents.promotionsShown(UUID, shopId);
      } else if (id) {
        analyticsEvents.promotionsShown(UUID, id);
      }
    }

    if (window.location.pathname !== "/") {
      await this.checkForToken();
    }
    if (isAndroid) {
      this.ShowAppBanner();
    }
    if (isIOS) {
      this.isiOSFacebookApp();
    }
    if (
      (window.location.pathname !== "/home") &&
      (window.location.pathname !== "/terms-conditions") &&
      (window.location.pathname !== "/privacy-policy")
    ) {
      this.checkForGiveaway(id, language);
    }
    await moment.locale(this.props.shop?.data?.language);
    await setLanguage(this.props.shop?.data?.language);
    await messaging.then(async (res) => {
      if (res) {
        await res.getMessagingToken().then(async (token) => {
          if (token && (token.length > 0)) {
            const messagingToken = localStorage.getItem("messagingToken");
            if (!messagingToken || (JSON.parse(messagingToken) !== token)) {
              notificationSubscribe(token);
            }
            localStorage.setItem("messagingToken", JSON.stringify(token));
          } else {
            localStorage.removeItem("messagingToken");
          }
        })
      }
    });

    messaging.then((res) => {
      if (res) {
        res.onMsgListener(res.getMsg, payload => {
          if (payload?.data?.type && (payload.data.type === "general")) {
            if (this.props.shop.data.id.toString() === payload.data.shop_id.toString()) {
              this.props.alert.show(
                payload.data, {
                type: "general_msg",
                position: "top center",
                timeout: 0
              }
              );
            }
          } else if (payload?.data?.shopId && (this.props.shop?.data?.id.toString() === payload.data.shopId.toString())) {
            switch (true) {
              case (payload?.data?.hasOwnProperty("custom_notificatin_type") && (payload?.data?.custom_notificatin_type === "new_user_join_card")):
                NotificatinCheckoutTokens(this.props.checkout?.data?.id, this.props.shop, false);
                break;
              case (window.location.pathname === "/cart"):
                if (this.props.checkout?.data?.id) {
                  confirmAlert({
                    closeOnClickOutside: false,
                    closeOnEscape: false,
                    customUI: ({ onClose }) => {
                      return (
                        <div className="reload_cart_custome_ui">
                          <p>{payload?.data?.body || ""}</p>
                          <div className="button-group">
                            <button
                              style={(this.props.shop?.data?.custom_theme && this.props.shop?.data?.shop_color) ? { color: this.props.shop.data.shop_color } : {}}
                              onClick={() => {
                                this.props.fetchCheckout(this.props.checkout.data.id);
                                onClose();
                              }}
                            >
                              {t("btn_reload")}
                            </button>
                          </div>
                        </div>
                      );
                    }
                  });
                }
                break;
              case ((this.props.checkout?.data?.id !== null) || (this.props.checkout?.data?.id !== undefined)):
                this.props.fetchCheckout(this.props.checkout.data.id);
                break;
              default:
                break;
            }
          }
        });
      }
    });
  }

  checkForGiveaway = (id, language) => {
    if (id) {
      getDocs(query(
        collection(db, "giveaways"),
        where("catalog_id", "==", id.toString()),
        where("active", "==", true),
        limit(1)
      ))
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            const giveaway = querySnapshot.docs[0].data();
            this.checkUserParticipation(giveaway, id, language || "en");
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  checkUserParticipation = (giveaway, shop_id, language) => {
    let user_id = localStorage.getItem("UUID");
    if (!user_id || (user_id.length < 3)) {
      user_id = generateUUID();
    } else {
      user_id = JSON.parse(user_id);
    }
    getDoc(doc(db, "giveaway-users", `${shop_id}-${giveaway.giveaway_id}-${user_id}`))
      .then((docSnap) => {
        if (!docSnap.exists()) {
          let giveawayData = {};
          giveawayData.giveaway_id = giveaway.giveaway_id;
          if (language) {
            giveawayData.description = giveaway[`description_${language}`];
          } else {
            giveawayData.description = giveaway["description_en"];
          }
          analyticsEvents.giveawayShown(user_id, shop_id, giveaway.giveaway_id);
          this.setState({
            showGiveawayModal: true,
            giveaway: giveawayData
          });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  getCartCount() {
    let cartCount = 0;

    this.props.fetchCheckout(this.props.checkout.data.id);

    if (this.props.cart.lineItems) {
      cartCount = this.props.cart.lineItems.length;
    }

    return cartCount;
  }

  setCatalogueOpenCookie = (shopId) => {
    analyticsEvents.catalogueOpen(shopId);
    var expireTime = moment(`${moment().format("DD.MM.YYYY")} 23:59:59`, "DD.MM.YYYY HH:mm:ss").utc().toDate();
    cookies.set(shopId, moment().format("DD.MM.YYYY"), {
      expires: expireTime,
      path: "/"
    });
  }

  async checkLoadingCatalog(shopId) {

    const { shop, checkout, cart } = this.props;
    let UUID = localStorage.getItem("UUID");
    if (!UUID || (UUID.length < 3)) {
      UUID = generateUUID();
    }

    await this.checkForToken();

    if (!isMobile) {
      const search = window.location.search;
      if (search) {
        history.push(`/home${search}`);
      } else {
        history.push("/home");
      }
      return;
    } else if ((shop?.data?.id || shopId) && UUID && (window.location.pathname === "/")) {
      if (shopId) {
        analyticsEvents.homeScreenShown(UUID, shopId);
      } else if (shop.data.id) {
        analyticsEvents.homeScreenShown(UUID, shop.data.id);
      }
    }

    const token = localStorage.getItem("messagingToken") ? JSON.parse(localStorage.getItem("messagingToken")) : null;
    if (token) {
      if (!shop?.data?.id || (shop?.data?.id.toString() !== shopId.toString())) {
        if (shop?.data?.id) {
          await unsubscribeFromTopic(token, shop?.data?.id);
        }
        await subscribeToTopic(token, shopId);
      }
    }

    if (shop.data.id && (shop.data.id.toString() !== shopId.toString())) {
      sessionStorage.removeItem("checkout_tokens_checked");
      localStorage.removeItem("notification_tokens");
      if ("caches" in window) {
        caches.open("cache_store").then((cache) => {
          cache.put("current_store", new Response(JSON.stringify(shopId)));
        });
      }
      if (shop?.data?.id) {
        sessionStorage.setItem("newShopId", JSON.stringify(shopId));
      }
    }


    if (!shop.data.id || (shop.data.id.toString() !== shopId.toString())) {
      localStorage.removeItem("search");
      this.props.CleanCart();
      if (shopId) {
        const catalogueOpenCookie = cookies.get(shopId);
        if (!catalogueOpenCookie || (catalogueOpenCookie !== moment().format("DD.MM.YYYY"))) {
          this.setCatalogueOpenCookie(shopId);
        }
      }
      if (shop?.data?.id) {
        sessionStorage.setItem("newShopId", JSON.stringify(shopId));
      }
      this.props.fetchShop(shopId)
        .then(async () => {
          const newShop = this.props.shop;
          this.checkForGiveaway(newShop?.data?.id || null, newShop?.data?.language);
          const language = sessionStorage.getItem("lng") || newShop?.data?.language || "en";
          this.prevLng = language;
          setLanguage(language);
          moment.locale(language);
          if ("caches" in window) {
            caches.open("cache_store").then((cache) => {
              cache.put("current_store", new Response(JSON.stringify(shopId)));
            });
          }
        });
    } else if (!shop.ERROR) {

      const language = sessionStorage.getItem("lng") || shop?.data?.language || "en";

      if (language && (language !== this.prevLng)) {
        sessionStorage.setItem("lng", language);
        this.prevLng = language;
        setLanguage(language);
        moment.locale(language);
      }

      this.props.fetchProductsCustom();
      if (shop.data.store_type === "Woocommerce") {
        if (shop.data.category && (shop.data.category.id === 3)) {
          this.props.fetchCollections();
        } else {
          this.props.fetchCollectionsWithTopOffers();
        }
      } else {
        this.props.fetchCollections();
      }

      if (checkout?.data?.id && (cart.lineItems.length === 0)) {
        this.props.fetchCheckout();
      }
    }

    if (!shopId || shop.ERROR) {
      this.prevLng = "en";
      sessionStorage.setItem("lng", "en");
      setLanguage("en");
      moment.locale("en");
    }

    // if (!shopId || shop.data.ERROR) {
    //   history.push('/home');
    // }
  }

  checkLoading(catalogVendor) {
    if (!isMobile) {
      history.push('/home');
    }

    if (!catalogVendor || catalogVendor.length === 0) {
      history.push('/home');
    } else {
      if (catalogVendor !== this.props.vendor) {
        this.props.setVendor(catalogVendor);
      }
    }
  }

  checkForToken = async () => {
    if (!sessionStorage.getItem("token")) {
      await getAPIToken();
    }
  }

  ShowAppBanner = (device = "android") => {
    this.setState({
      showBanner: {
        show: true,
        device
      }
    });
  }

  isiOSFacebookApp = () => {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    if (((ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1))) {
      this.ShowAppBanner("iOS");
    }
  }

  render() {
    const { showBanner, showGiveawayModal, giveaway } = this.state;
    const queryParams = new URLSearchParams(window.location.search);
    const { shop } = this.props;
    const shopId = queryParams.get("id");

    return (
      <div className="AppContainer">
        {(showBanner.show && shop?.data?.id) && (
          <DownloadAppBanner
            shop_name={shop?.data?.name || ""}
            shop_id={shopId || shop?.data?.id}
            shop_icon={(showBanner.device === "iOS" ? shop?.data?.image || null : null)}
            device={showBanner.device}
          />
        )}
        {showGiveawayModal && (
          <GiveawayModal
            closeModal={() => this.setState({ showGiveawayModal: false })}
            translate={t}
            giveaway={giveaway}
            shopId={shop?.data?.id}
          />
        )}
        <div className="root_wrapper" style={(showBanner.show && (shopId || shop?.data?.id)) ? { paddingTop: "65px" } : {}}>
          <Router history={history}>
            <Switch>
              <Route path="/" exact>
                <CatalogDetails
                  shopId={shopId}
                  getCartCount={this.getCartCount}
                  checkLoadingCatalog={this.checkLoadingCatalog}
                  checkLoading={this.checkLoading}
                />
              </Route>
              <Route path="/home" exact component={HomePage} />
              <Route path="/terms-conditions" exact component={Conditions} />
              <Route path="/privacy-policy" exact component={PrivacyPolicy} />
              <Route path="/product/:id/:category" component={Product} />
              <Route path="/category/:id" component={Category} />
              <Route path="/cart" exact><Cart checkLoading={this.checkLoading} shopId={shopId} checkForGiveaway={this.checkForGiveaway} /></Route>
              <Route path="/contact-info"><Info checkLoading={this.checkLoading} /></Route>
              <Route path="/search" component={SearchPage} />
              <Route path="/checkout" component={CheckoutForm} />
              <Route path="/order-complete" component={OrderComplete} />
              <Route path="/categories" component={Categories} />
              <Route path="/subcategories/:category" component={subCategories} />
              <Route path="/order-archive" exact component={orderArchive} />
              <Route path="/order-items" exact component={archivedItems} />
            </Switch>
          </Router>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    checkout: state.checkout,
    vendor: state.vendor.data,
    cart: state.cart,
    shop: state.shop,
  };
};

export default connect(mapStateToProps, {
  fetchCheckout,
  fetchProductsCustom,
  fetchCollections,
  fetchCollectionsWithTopOffers,
  addToCart,
  setVendor,
  fetchShop,
  CleanCart
})(withAlert()(App));
