import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en/translation.json";
import mk from "./mk/translation.json";
import cs from "./cs/translation.json";
import sr from "./sr/translation.json";
import de from "./de/translation.json";

const resources = {
    en: { translation: en },
    mk: { translation: mk },
    cs: { translation: cs },
    sr: { translation: sr },
    de: { translation: de }
}

i18n
.use(initReactI18next)
.init({
    resources,
    lng: sessionStorage.getItem("lng") || "en",
    fallbackLng: "en",
    debug: false,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    }
});

export default i18n;