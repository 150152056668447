import axios from "axios";
import { t } from "i18next";
import moment from "moment";
import { uuidv4 } from "@firebase/util";
import { messaging } from "../firebase";

const SendNotificationAPI = axios.create({
  baseURL: "https://fcm.googleapis.com/fcm",
  headers: {
    "Authorization": process.env.REACT_APP_NOTIFICATION_AUTHORIZATION
  }
});

export const apiData = axios.create({
  baseURL: "https://dashboard.featherapp.net/api/",
  headers: {
    token: process.env.REACT_APP_API_KEY,
    "Access-Control-Allow-Credentials": true
  }
});

// Generate UUID
export const generateUUID = () => {
  let UUID = localStorage.getItem("UUID");
  if (!UUID || (UUID.length < 3)) {
    UUID = uuidv4();
    localStorage.setItem("UUID", JSON.stringify(UUID));
  }
  return UUID;
}

// Get token from backend
export const getAPIToken = async () => {
  await apiData.post("v2/device/login", {
    guid: generateUUID(),
    os_type: 3,
    is_extension: false
  }).then((res) => {
    if (res.status === 200) {
      sessionStorage.setItem("token", JSON.stringify(res.data.token));
    } else {
      console.error(res.data.message);
    }
  }).catch((error) => {
    console.error(error);
  });
};

// Subscribe to checkout (cart)
export const subscribeToCheckout = (shop, checkout_id, order_id = null) => {
  const token = sessionStorage.getItem("token");
  if (token) {

    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    let DataToSend = null;

    if (order_id) {
      DataToSend = {
        checkout_id: checkout_id,
        is_order: true,
        order_id: order_id
      };
    } else {
      DataToSend = {
        checkout_id: checkout_id,
        shop_id: shop.id,
        shop_name: shop.name,
        is_order: false,
        list_name: `${shop.name} - ${new Date().getDate() + " " + monthNames[new Date().getMonth()].substring(0, 3) + " " + new Date().getFullYear()}`
      };
    }

    apiData.post("v2/order/checkout-subscribe", DataToSend, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      if (res.status !== 200) {
        console.error(res.data.message);
      }
    }).catch((error) => {
      console.error(error);
    });
  } else {
    getAPIToken().then(() => subscribeToCheckout(shop, checkout_id, order_id));
  }

}

// Set order for Shopify
export const setOrderForShopify = (checkout_id) => {
  const token = sessionStorage.getItem("token");
  if (token) {
    apiData.post("v2/order/set-order", {
      checkout_id: checkout_id
    }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      if (res.status !== 200) {
        console.error(res.data.message);
      }
    }).catch((error) => {
      console.error(error);
    });
  } else {
    getAPIToken().then(() => setOrderForShopify(checkout_id));
  }
}

// Order Notifications
export const orderNotifications = (checkout_id) => {
  const token = sessionStorage.getItem("token");
  if (token) {
    apiData.post("v2/notifications/cart-changes", {
      checkout_id: checkout_id,
      notification_type: 1
    }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      if (res.status !== 200) {
        console.error(res.data.message);
      }
    }).catch((error) => {
      console.error(error);
    });
  } else {
    getAPIToken().then(() => orderNotifications(checkout_id));
  }
}

// Notifications Subscribe
export const notificationSubscribe = (messaging_token = null) => {
  const token = sessionStorage.getItem("token");
  if (messaging_token && token) {
    apiData.post("v2/notifications/subscribe", {
      firebase_token: messaging_token,
      os_type: "3"
    }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      if (res.status !== 200) {
        console.error(res.data.message);
      }
    }).catch((error) => {
      console.error(error);
    });
  } else {
    if (!token) {
      getAPIToken().then(() => notificationSubscribe(messaging_token));
    } else if (!messaging_token) {
      messaging.then((res) => {
        if (res) {
          res.getMessagingToken().then((msg_token) => {
            notificationSubscribe(msg_token);
          });
        }
      });
    }
  }
}

// Notifications checkout tokens
export const NotificatinCheckoutTokens = (checkout_id = null, shop = null, send_notifications = true, new_user = false) => {
  const session_time = sessionStorage.getItem("checkout_tokens_checked");
  if ((session_time && (moment().diff(moment.unix(session_time), "minutes") > 1)) || !session_time || !send_notifications) {
    const token = sessionStorage.getItem("token");
    if (checkout_id && token) {
      apiData.get(`v2/notifications/checkout-tokens?checkout_id=${checkout_id}`,
        {
          headers: {
            "Authorization": `Bearer ${JSON.parse(token)}`
          }
        }).then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0) {
              sessionStorage.setItem("checkout_tokens_checked", moment().unix());
              localStorage.setItem("notification_tokens", JSON.stringify(res.data));
              if (send_notifications) {
                sendCartChangeNotification(shop, new_user);
              }
            }
          } else {
            console.error(res.data.message);
            sessionStorage.removeItem("checkout_tokens_checked");
          }
        }).catch((error) => {
          console.error(error);
          sessionStorage.removeItem("checkout_tokens_checked");
        });
    } else {
      if (!token) {
        getAPIToken().then(() => NotificatinCheckoutTokens(checkout_id, shop, send_notifications, new_user));
      } else if (!checkout_id) {
        console.error("No checkout ID");
      }
    }
  } else {
    sendCartChangeNotification(shop, new_user);
  }
}

// Send notification to device
export const sendCartChangeNotification = async (shop, new_user = false) => {
  const sendTo = {};
  const shop_name = shop?.data?.name || "";
  const shop_image = shop?.data?.image || "";
  const shop_id = shop?.data?.id || "";
  if (localStorage.getItem("notification_tokens")) {
    const notification_tokens = JSON.parse(localStorage.getItem("notification_tokens"));
    notification_tokens.forEach((token) => {
      switch (true) {
        case ((token.device_type === false) && (token.os_type === "1")):
          if (sendTo.iOS) {
            sendTo.iOS.push(token.firebase_token);
          } else {
            sendTo.iOS = [token.firebase_token];
          }
          break;
        case ((token.device_type === true) && (token.os_type === "1")):
          if (sendTo.iOSAppClip) {
            sendTo.iOSAppClip.push(token.firebase_token);
          } else {
            sendTo.iOSAppClip = [token.firebase_token];
          }
          break;
        case (token.os_type === "2"):
          if (sendTo.Android) {
            if (!sendTo.Android.includes(token.firebase_token)) {
              sendTo.Android.push(token.firebase_token);
            }
          } else {
            sendTo.Android = [token.firebase_token];
          }
          break;
        case (token.os_type === "3"):
          if (sendTo.Web) {
            if (!sendTo.Web.includes(token.firebase_token)) {
              sendTo.Web.push(token.firebase_token);
            }
          } else {
            sendTo.Web = [token.firebase_token];
          }
          break;
        default:
          break;
      }
    });
  }

  if (Object.keys(sendTo).length > 0) {
    Object.keys(sendTo).forEach(async (device) => {
      switch (true) {
        case (device === "iOS"):
          let iOS_Data = {
            "gcm_notification_shopId": shop_id,
            "gcm_notification_type": "1",
            "image": shop_image,
          }
          if (new_user) {
            iOS_Data.custom_notification_type = "new_user_join_card";
          }
          await SendNotificationAPI.post("/send", {
            "registration_ids": sendTo[device],
            "priority": "high",
            "mutable_content": true,
            "data": iOS_Data,
            "notification": {
              "title": shop_name,
              "body": new_user ? t("new_cart_user_body") : t("cart_updated_body"),
              "sound": "default"
            }
          }).catch((e) => {
            console.error(e);
          });
          break;
        case (device === "iOSAppClip"):
          let iOSAppClip_Data = {
            "gcm_notification_shopId": shop_id,
            "gcm_notification_type": "1",
            "image": shop_image,
            "target-content-id": "https://featherapp.net/?id=17"
          }
          if (new_user) {
            iOSAppClip_Data.custom_notification_type = "new_user_join_card";
          }
          await SendNotificationAPI.post("/send", {
            "registration_ids": sendTo[device],
            "priority": "high",
            "mutable_content": true,
            "data": iOSAppClip_Data,
            "notification": {
              "title": shop_name,
              "body": new_user ? t("new_cart_user_body") : t("cart_updated_body"),
              "sound": "default"
            }
          }).catch((e) => {
            console.error(e);
          });
          break;
        case (device === "Android"):
          let Android_Data = {
            "title": shop_name,
            "body": new_user ? t("new_cart_user_body") : t("cart_updated_body"),
            "type": "1",
            "image": shop_image,
            "shopId": shop_id
          }
          if (new_user) {
            Android_Data.custom_notification_type = "new_user_join_card";
          }
          await SendNotificationAPI.post("/send", {
            "registration_ids": sendTo[device],
            "data": Android_Data
          }).catch((e) => {
            console.error(e);
          });
          break;
        case (device === "Web"):
          let Web_Data = {
            "title": shop_name,
            "body": new_user ? t("new_cart_user_body") : t("cart_updated_body"),
            "type": "1",
            "image": shop_image,
            "shopId": shop_id
          }
          if (new_user) {
            Web_Data.custom_notification_type = "new_user_join_card";
          }
          await SendNotificationAPI.post("/send", {
            "registration_ids": sendTo[device],
            "data": Web_Data
          }).catch((e) => {
            console.error(e);
          });
          break;
        default:
          break;
      }
    });
  }
}

// Subscribe to topic
export const subscribeToTopic = (token, shop_id) => {
  fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/general_shop_${shop_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_NOTIFICATION_AUTHORIZATION
    }
  }).then((response) => {
    if (response.status < 200 || response.status >= 400) {
      console.error(`Error subscribing to topic: ${response.status} - ${response.text()}`);
    }
  }).catch((error) => {
    console.error("Error => ", error);
  });
}

// Unsubscribe from topic
export const unsubscribeFromTopic = (token, shop_id) => {
  fetch("https://iid.googleapis.com/iid/v1:batchRemove", {
    method: "POST",
    body: JSON.stringify({
      "to": `/topics/general_shop_${shop_id}`,
      "registration_tokens": [token]
    }),
    headers: {
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_NOTIFICATION_AUTHORIZATION
    }
  }).then((response) => {
    if (response.status < 200 || response.status >= 400) {
      console.error(`Error unsubscribing from topic: ${response.status} - ${response.text()}`);
    }
  }).catch((error) => {
    console.error("Error => ", error);
  });
}