import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import QRCode from "react-qr-code";
import { withTranslation } from "react-i18next";

import { fetchShop } from "../../actions/shop";

import featherLogo from "../info/assets/images/feather_logo.svg";

import "./assets/css/landing.css";

class HomePage extends React.Component {

    constructor() {
        super();
        this.state = {
            shop_id: null,
            qrCodeValue: null,
            no_image: false,
            shop_details: {
                LOADING: true,
                ERROR: false
            }
        };
    }

    componentDidMount() {
        const search = window.location.search;
        if (search && search.includes("id")) {
            const urlParams = new URLSearchParams(search);
            const id = urlParams.get("id");
            this.setState({
                shop_id: id
            });
            this.props.fetchShop(id)
                .then(() => {
                    if (this.props.shop.ERROR) {
                        this.setState({
                            shop_id: null
                        });
                    } else {
                        this.setState({
                            shop_details: this.props.shop,
                            no_image: false,
                            qrCodeValue: this.props.shop?.data?.id ? `https://featherapp.net?id=${this.props.shop?.data?.id}` : null
                        });
                    }
                })
                .catch(() => {
                    this.setState({
                        shop_id: null
                    });
                });
        }
    }

    featherLogo = (hide_logo = false) => {
        return(
            <div className="feather_logo_wrapper">
                {!hide_logo && (<img src={featherLogo} alt="hm_logo" className="hm_l" />)}
                <h3>Feather</h3>
                <p>Powered by <a href="https://echo.com.mk/">Echo</a></p>
            </div>
        )
    };

    render() {
        const { shop_id, shop_details, qrCodeValue, no_image } = this.state;
        const customColor = (shop_details?.data?.custom_theme && shop_details?.data?.shop_color) ? true : false;
        const { t } = this.props;
        return (
            <div className="landingHeader">
                <div
                    className="topLandingHeader"
                    style={shop_id ? {height: "calc(79%)"} : {}}
                >
                    {shop_id ? (
                        shop_details.LOADING ? (
                            <div className="loading">{t("loading")}</div>
                        ) : (
                            shop_details.ERROR ? (
                                this.featherLogo()
                            ) : (
                                <div className="market_wrapper">
                                    <div className="header">
                                        {!no_image && (
                                            <div
                                                className="image_wrapper"
                                                style={
                                                    (customColor)
                                                        ? {background: shop_details?.data?.shop_color}
                                                        : {}
                                                }
                                            >
                                                <img
                                                    src={shop_details?.data?.image}
                                                    onError={() => {
                                                        this.setState({
                                                            no_image: true
                                                        });
                                                    }}
                                                    alt="Shop icon"
                                                />
                                            </div>
                                        )}
                                        <div className="shop_name">
                                            {shop_details?.data?.name}
                                        </div>
                                    </div>
                                    <div className="text_style" dangerouslySetInnerHTML={{__html: t("scan_qr_code")}}></div>
                                    <div
                                        className="qr_code_wrapper"
                                        style={
                                            (customColor)
                                                ? {borderColor: shop_details?.data?.shop_color}
                                                : {}
                                        }
                                    >
                                        {qrCodeValue && (
                                            <QRCode
                                                fgColor={
                                                    (customColor)
                                                        ? shop_details?.data?.shop_color
                                                        : "#000000"
                                                }
                                                size={200}
                                                value={qrCodeValue}
                                            />
                                        )}
                                    </div>
                                </div>
                            )
                        )
                    ) : (
                        this.featherLogo()
                    )}
                </div>
                <div className="footerLanding">
                    {(shop_id && !shop_details.ERROR) && this.featherLogo(true)}
                    <Link to="/terms-conditions" className="terms_and_conditions">Terms & Conditions</Link>
                    <span style={{ color: "#09b492"}}> | </span>
                    <Link to="/privacy-policy" className="terms_and_conditions">Privacy Policy</Link>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        shop: state.shop,
    };
};

export default connect(mapStateToProps, {
    fetchShop
})(withTranslation()(HomePage));