const initialState = {
    data: {},
    LOADING: true,
    ERROR: false,
}

const shopReducer = (state = initialState, action) => {
    if (action.type === "FETCH_SHOP"){
        return {...state, data: action.payload, LOADING: action.loading, ERROR: action.error};
    }

    if (action.type === "FETCH_SHOP_LOADING"){
        return {...state, LOADING: action.loading, ERROR: action.error};
    }

    return state;
}

export default shopReducer;