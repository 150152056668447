const defaultValues = {
    products_data: [],
    ERROR: false,
    LOADING: true
};

const recommendedProductsReducer = (state = defaultValues, action) => {

    if (action.type === "RECOMMENDED_PRODUCTS"){
        return {...state, products_data: action.payload};
    }

    if (action.type === "RECOMMENDED_PRODUCTS_ERROR"){
        return { ...state, ERROR: action.payload };
    }

    if (action.type === "FETCHING_RECOMMENDED_PRODUCTS"){
        return { ...state, LOADING: action.payload };
    }

    return state;
}

export default recommendedProductsReducer;