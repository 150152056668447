import React from "react";
import "./spinner.css";

class Spinner extends React.Component{
    render (){
        return (
            <div className="loaderContainer">
                <div className="loader"></div>
            </div>
        )
    }
}

export default Spinner;