import React from "react";
import { withTranslation } from "react-i18next";

import ShopNotFoundImg from "./../images/shop-not-found.svg";

import "./assets/style.css";

class NoCatalogSelected extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div className="no_catalog_selected_container">
                <img src={ShopNotFoundImg} alt="No catalog selected" />
                <div className="title_wrapper">
                    <h1>{t("no_catalog_selected")}</h1>
                </div>
            </div>
        )
    }
}

export default withTranslation()(NoCatalogSelected);