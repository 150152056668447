import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import bHistory from "../../history";

import "./orderComplete.css";

class OrderComplete extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { history, t, shop } = this.props,
            { shop_color } = shop.data,
            change_color = (shop.data.custom_theme && shop_color) || false;
        return(
            <>
                <div className="orderCardHeader">
                    <div className="go_back" onClick={() => bHistory.push("/")}>
                        <div
                            className="angle_left_icon"
                            style={change_color ? {background: shop_color} : {}}
                        ></div>
                        <span
                            style={change_color ? {color: shop_color} : {}}
                        >
                            {t("btn_home")}
                        </span>
                    </div>
                </div>
                <div className="orderContainer">
                    <h2 className="orderPageTitle">
                        {t("order_complete")}
                    </h2>
                    <div className="orderDetails">{t("order_details")}</div>
                    <div className="orderTitle">{t("order_number")} {history.location.state.data.number}</div>
                    <div className="orderStatus">{t("order_status")} {history.location.state.data.status}</div>
                    <div className="ordertotal">
                        <p className="ordertotalText">
                            {t("total")} {history.location.state.data.total} {history.location.state.data.currency_symbol}
                        </p>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        shop: state.shop
    };
};

export default connect(mapStateToProps, {})(withTranslation()(OrderComplete));