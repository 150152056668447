import React from "react";
import { connect } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import TextTruncate from "react-text-truncate";
import Skeleton from "react-loading-skeleton";
import { withTranslation } from "react-i18next";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

import TabBar from "./../tabBar/tabBar";
import ShopNotFound from "../infoScreens/shopNotFound/index";
import EmptySearch from "../infoScreens/emptySearch";
import NoCatalogSelected from "../infoScreens/noCatalogSelected";
import ExpiredShop from "../infoScreens/expiredShop";

import {
    fetchProductsCustom,
    fetchSearchProducts
} from "../../actions/products";
import { fetchCollections } from "../../actions/categories/index";
import { addRemoveItem } from "../../actions/cart";
import history from "../history";
import { DynamicPriceFontSize, onSaleTo, calcPercentage, removeUnnecessaryDecimals } from "../../globalFunctions";
import { fetchCheckout } from "../../actions/checkout";
import analyticsEvents from "../../analyticsEvents";

import noImg from "./../catalog/assets/img/no_img.png";

import "react-loading-skeleton/dist/skeleton.css";
import "./assest/css/search-page.css";

class searchPage extends React.Component {
    targetElement = null;
    constructor() {
        super();
        this.state = {
            searchValue: "",
            searching: false,
            search_page_number: 1
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.fetchProductsCustom();
        this.props.fetchCollections();
        if (this.props.cart.lineItems.length === 0) {
            this.props.fetchCheckout();
        }
        if (localStorage.getItem("search")) {
            const searchValue = JSON.parse(localStorage.getItem("search"));
            this.setState({
                searching: true,
                searchValue
            }, () => {
                this.props.fetchSearchProducts(searchValue);
            });
        }
        this.targetElement = document.querySelector(".search_container");
    }

    componentWillUnmount() {
        this.props.fetchCollections(false);
        this.props.fetchSearchProducts(false);
        clearAllBodyScrollLocks();
    }

    showTargetElement = () => {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            disableBodyScroll(this.targetElement);
        }
    };

    hideTargetElement = () => {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            enableBodyScroll(this.targetElement);
        }
    };

    onSearch = (e) => {
        this.setState({
            searchValue: e.target.value
        });
    }

    renderProducts() {
        const SkeletonData = [1, 2, 3, 4, 5, 6].map((id) => {
            return {
                id
            }
        })
        const { LOADING, search_data } = this.props.search,
            products = LOADING ? SkeletonData : search_data.products || [],
            { shop } = this.props,
            { custom_theme, shop_color, reqular_price_color, discount_price_color } = shop.data;
        if (LOADING || (products && (products.length > 0))) {
            const { cart } = this.props;
            return products.map((prod, index) => {
                const { compareAtPrice, priceV2, price, id, product } = prod.variants ? prod.variants[0] : {},
                    isOnTheList = (cart.lineItems && (cart.lineItems.length > 0)) ? cart.lineItems.find(p => p.variant.id === id) : false;
                return (
                    <div className="searchProductBox" key={index}>
                        <Link
                            to={`/product/${prod.id}/${product?.collections[0]?.id || "null"}`}
                            className="body_container"
                            style={LOADING ? { pointerEvents: "none" } : null}
                        >
                            <div className="productLink">
                                <div className="productCategoryTitle">
                                    {LOADING
                                        ? (<Skeleton containerClassName="category_title_skeleton" />)
                                        : (
                                            <TextTruncate
                                                line={2}
                                                truncateText="..."
                                                text={prod.title}
                                            />
                                        )
                                    }
                                </div>
                                <div className="categoryImg">
                                    {LOADING
                                        ? (<Skeleton containerClassName="category_image_skeleton" />)
                                        : (
                                            <>
                                                <img
                                                    src={prod?.images[0]?.src || noImg}
                                                    alt="category_image"
                                                />
                                                {(prod.date_on_sale_from_gmt || prod.date_on_sale_to_gmt) && (
                                                    onSaleTo(
                                                        {
                                                            from: prod.date_on_sale_from_gmt,
                                                            to: prod.date_on_sale_to_gmt
                                                        },
                                                        shop.data
                                                    )
                                                )}
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </Link>
                        <div className="categoryPriceContainer">
                            <div className="vendor-price-wrapper">
                                {LOADING
                                    ? (<Skeleton containerClassName="category_price_skeleton" />)
                                    : (
                                        <>
                                            {((compareAtPrice != null) && (compareAtPrice > price)) ? (
                                                <div
                                                    className="discountPercentage"
                                                    style={(custom_theme && reqular_price_color) ? { backgroundColor: reqular_price_color } : {}}
                                                >
                                                    {calcPercentage(compareAtPrice, price)}
                                                </div>
                                            ) : (
                                                <div style={{ height: "30px" }}></div>
                                            )}
                                            <div
                                                className="productPrice"
                                                style={(custom_theme && discount_price_color) ? { backgroundColor: discount_price_color } : {}}
                                            >
                                                {DynamicPriceFontSize(
                                                    50,
                                                    removeUnnecessaryDecimals(price),
                                                    17
                                                )}
                                                <span>{priceV2.currencyCode}</span>
                                            </div>
                                            {((compareAtPrice != null) && (compareAtPrice > price)) && (
                                                <div
                                                    className="comparePrice"
                                                    style={(custom_theme && reqular_price_color) ? { backgroundColor: reqular_price_color } : {}}
                                                >
                                                    {DynamicPriceFontSize(
                                                        36,
                                                        removeUnnecessaryDecimals(compareAtPrice),
                                                        11
                                                    )}
                                                    <span>{priceV2.currencyCode}</span>
                                                </div>
                                            )}
                                        </>
                                    )
                                }
                            </div>
                            {LOADING
                                ? (
                                    <div className="category-toCart-skeleton-wrapper">
                                        <Skeleton containerClassName="category-addToCart-skeleton" />
                                    </div>
                                )
                                : (
                                    <div
                                        className={`categoryCartButton ${isOnTheList ? "added" : ""}`}
                                        style={(custom_theme && shop_color && isOnTheList) ? { background: shop_color } : {}}
                                    >
                                        <button
                                            className="addButton"
                                            onClick={() => {
                                                if (isOnTheList) {
                                                    analyticsEvents.removeProductFromCart(shop.data.id, prod.id);
                                                    this.props.addRemoveItem("remove", isOnTheList);
                                                } else {
                                                    analyticsEvents.addProductToCart(shop.data.id, 5, prod.id);
                                                    this.props.addRemoveItem("add", { product: prod, quantity: 1 });
                                                }
                                            }}
                                        >
                                            <div
                                                className={isOnTheList ? "checkmark_icon" : "plus_icon"}
                                                style={(custom_theme && shop_color && !isOnTheList) ? { backgroundColor: shop_color } : {}}
                                            ></div>
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            });
        } else {
            return <EmptySearch
                custom_theme={custom_theme}
                shop_color={shop_color}
            />
        }
    }

    fetchMoreProducts = () => {
        const { shop, search } = this.props;
        this.setState((state) => ({
            search_page_number: (shop.data.store_type === "Woocommerce") ? state.search_page_number + 1 : search.search_data.total_pages
        }), () => {
            this.props.fetchSearchProducts(this.state.searchValue, this.state.search_page_number);
        });
    }

    goToProductInfo = (product_id, category) => {
        history.push(`product/${product_id}/${category}`);
    }

    renderSuggestedProducts = () => {
        const { cart, customProducts, shop, t } = this.props,
            { custom_theme, shop_color } = shop.data,
            topProducts = customProducts.data || [];
        return (
            !topProducts.LOADING ? (
                topProducts.products.map((prod, index) => {
                    const { compareAtPrice, priceV2, price, id, product } = prod.variants[0],
                        isOnTheList = (cart?.lineItems?.length > 0) ? cart.lineItems.find(p => p.variant.id === id) : false;
                    return (
                        <div key={index} className="product_container">
                            <div
                                className="product_image"
                                onClick={async () => {
                                    await analyticsEvents.searchSuggestionTapped(shop.data.id);
                                    this.goToProductInfo(prod.id, product?.collections[0]?.id || null);
                                }}
                            >
                                <img src={prod?.images[0]?.src || noImg} alt="" />
                            </div>
                            <div className="product_info_container">
                                <div className="product_info" onClick={() => this.goToProductInfo(prod.id, product?.collections[0]?.id || null)}>
                                    <div className="product_title">
                                        {prod.title}
                                    </div>
                                    <div className="product_price_container">
                                        {(compareAtPrice > price) && (
                                            <div className="product_price">
                                                {compareAtPrice ? `${compareAtPrice} ${priceV2.currencyCode}` : ""}
                                            </div>
                                        )}
                                        <div
                                            className="compare_price"
                                        >
                                            {price ? `${price} ${priceV2.currencyCode}` : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="add_to_cart_container">
                                    <div
                                        className={`add_to_cart_btn ${isOnTheList ? "added" : ""}`}
                                        style={(custom_theme && shop_color && isOnTheList) ? { backgroundColor: shop_color } : {}}
                                        onClick={() => {
                                            if (isOnTheList) {
                                                analyticsEvents.removeProductFromCart(shop.data.id, prod.id);
                                                this.props.addRemoveItem("remove", isOnTheList);
                                            } else {
                                                analyticsEvents.addProductToCart(shop.data.id, 4, prod.id);
                                                this.props.addRemoveItem("add", { product: prod, quantity: 1 });
                                            }
                                        }}
                                    >
                                        <div
                                            className={isOnTheList ? "checkmark_icon" : "plus_icon"}
                                            style={(custom_theme && shop_color && !isOnTheList) ? { backgroundColor: shop_color } : {}}
                                        ></div>
                                    </div>
                                    <div className="btn_desc">
                                        {isOnTheList ? t("added_to_cart") : t("add_to_card")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            ) : (
                [1, 2, 3, 4, 5].map((i) => {
                    return (
                        <div key={i} className="product_container">
                            <div className="product_image">
                                <Skeleton containerClassName="suggested_item_image_skeleton" />
                            </div>
                            <div className="product_info_container">
                                <div className="product_info" style={{ flex: "0 0 60%" }}>
                                    <div className="product_title">
                                        <Skeleton containerClassName="suggested_item_title_skeleton" />
                                    </div>
                                    <div className="product_price_container">
                                        <div className="product_price">
                                            <Skeleton containerClassName="suggested_item_price_skeleton" />
                                        </div>
                                        <div className="compare_price">
                                            <Skeleton containerClassName="suggested_item_price_skeleton" />
                                        </div>
                                    </div>
                                </div>
                                <div className="add_to_cart_container" style={{ flex: "0 0 30%", maxWidth: "70px" }}>
                                    <Skeleton containerClassName="cart_btn_skeleton" />
                                    <Skeleton containerClassName="cart_desc_skeleton" />
                                </div>

                            </div>
                        </div>
                    )
                })
            )
        )
    }

    render() {
        const { collections, customProducts, search, shop, t } = this.props,
            topProducts = customProducts,
            { searchValue, searching } = this.state;
        return (
            <div className="search_container">
                <h3 className="page_title">{t("search")}</h3>
                {!shop?.data?.id ? (
                    <div className="search_info_msg_container">
                        <NoCatalogSelected />
                    </div>
                ) : shop.ERROR ? (
                    <div className="search_info_msg_container">
                        <ShopNotFound />
                    </div>
                ) : (shop?.data?.catalogue_limit_expired === true) ? (
                    <div className="search_info_msg_container">
                        <ExpiredShop/>
                    </div>
                ) : (
                    <>
                        <div className="search_box_container">
                            <input
                                type="text"
                                className={`search_box ${searching ? "searching" : ""}`}
                                name="search"
                                placeholder={t("search_products")}
                                onChange={this.onSearch}
                                value={searchValue}
                                onKeyPress={(e) => {
                                    if ((e.key === "Enter") && (searchValue.length > 0)) {
                                        analyticsEvents.productSearched(shop.data.id);
                                        this.setState({
                                            searching: true
                                        }, () => {
                                            const body = document.querySelector("body");
                                            body.style.removeProperty("overflow");
                                        });
                                        this.props.fetchSearchProducts(searchValue);
                                        localStorage.setItem("search", JSON.stringify(searchValue));
                                    }
                                }}
                                onFocus={() => this.showTargetElement()}
                                onBlur={() => this.hideTargetElement()}
                            />
                            <FaSearch className="search_icon" />
                            <div
                                className={`cancel_btn ${searching ? "searching" : ""}`}
                                style={
                                    (shop?.data?.custom_theme && shop?.data?.shop_color)
                                        ? { color: shop.data.shop_color } : {}
                                }
                                onClick={() => {
                                    this.setState({
                                        searchValue: "",
                                        searching: false
                                    });
                                    localStorage.removeItem("search");
                                }}
                            >
                                {t("btn_cancel")}
                            </div>
                        </div>
                        {(!searching && !collections.LOADING && !customProducts.LOADING && (collections?.collections_data?.collections?.length === 0) && (customProducts?.data?.products?.length === 0)) && (
                            <div className="no_promotions">
                                <EmptySearch
                                    custom_theme={shop?.data?.custom_theme}
                                    shop_color={shop?.data?.shop_color}
                                    no_promotions={true}
                                />
                            </div>
                        )}
                        {(!searching && (collections.LOADING || (collections.collections_data.collections && (collections.collections_data.collections.length > 0)))) && (
                            <div className="discover_container">
                                <h3 className="discover_title">
                                    {collections.LOADING
                                        ? <Skeleton containerClassName="discover_title_skeleton" />
                                        : t("discover")
                                    }
                                </h3>
                                <ul className="category_list">
                                    {!collections.LOADING ?
                                        (
                                            collections.collections_data.collections.slice(0, 3).map((cat, index) => {
                                                return (
                                                    <li key={index} onClick={() => history.push(`/category/${cat.id}`)}>
                                                        <div
                                                            style={
                                                                (shop?.data?.custom_theme && shop?.data?.shop_color)
                                                                    ? { color: shop.data.shop_color } : {}
                                                            }
                                                        >
                                                            {cat.title}
                                                        </div>
                                                    </li>
                                                );
                                            })
                                        ) :
                                        (
                                            [1, 2, 3].map((i) => {
                                                return <Skeleton key={i} containerClassName="discover_item_skeleton" />
                                            })
                                        )
                                    }
                                </ul>
                            </div>
                        )}
                        {(!searching && (topProducts.LOADING || (!topProducts.ERROR && (topProducts.data.products.length > 0)))) && (
                            <div className="suggestions_container">
                                <div className="suggestions_title">
                                    {topProducts.LOADING
                                        ? <Skeleton containerClassName="suggestions_title_skeleton" />
                                        : t("suggested")
                                    }
                                </div>
                                <div className="products_container">
                                    {this.renderSuggestedProducts()}
                                </div>
                            </div>
                        )}
                        {searching && (
                            <InfiniteScroll
                                dataLength={search.LOADING ? 0 : search?.search_data?.products?.length || 0}
                                next={this.fetchMoreProducts}
                                hasMore={(search.search_data.total_pages > "0")}
                                loader={<div className="search_products_loader"></div>}
                                scrollThreshold={1}
                                className="search_products_container"
                            >
                                {this.renderProducts()}
                            </InfiniteScroll>
                        )}
                    </>
                )
                }
                <TabBar activeTab="search" />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        collections: state.collections,
        shop: state.shop,
        customProducts: state.customProducts,
        cart: state.cart,
        checkout: state.checkout,
        search: state.search
    };
};

export default connect(mapStateToProps, {
    fetchCollections,
    fetchProductsCustom,
    addRemoveItem,
    fetchSearchProducts,
    fetchCheckout
})(withTranslation()(searchPage));