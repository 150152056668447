import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import history from "../../history";
import { setOrderForShopify } from "../../../apis/apiData";
import analyticsEvents from "../../../analyticsEvents";

import ShoppingCartIcon from "./assets/img/shopping_cart_icon.svg";

import "./assets/css/shop-options.css";

class ShopOptions extends React.Component {

    constructor() {
        super();
        this.state = {
            locations: [],
            showLocations: false
        }
    }

    completeCheckout = () => {
        const { shop, checkout, cart } = this.props,
            { current_price, currency } = this.props;

        analyticsEvents.orderPayingStarted(shop.data.id, checkout.data.id);
        if (shop.data.store_type === "Woocommerce") {
            history.push('/checkout', { total: `${current_price} ${currency}` });
        } else if (checkout.data.id && cart.lineItems && checkout.data.webUrl) {
            setOrderForShopify(checkout.data.id);
            window.open(checkout.data.webUrl, "_blank");
        }
    }

    openLocationOnMap = (location) => {
        if (parseFloat(location.lat) && parseFloat(location.lng)) {
            window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${parseFloat(location.lat)},${parseFloat(location.lng)}`);
        }
    }

    choseLocation = (locations) => {
        this.setState({
            showLocations: true,
            locations
        });
    }

    render() {
        const { cart, shop, t, regular_price, current_price, saved, currency, showNavigateOption, showPaymentOption, showApplePayOption } = this.props,
            { locations, showLocations } = this.state;
        return (
            <>
                {(showLocations && (locations.length > 0)) ? (
                    <div className="locations_container">
                        <div className="header">
                            <div className="title">{t("chose_location_title")}</div>
                            <div
                                className="close_btn"
                                onClick={() => this.setState({
                                    showLocations: false,
                                    location: []
                                })}
                            ></div>
                        </div>
                        <div className="body">
                            {locations.map((location, index) => {
                                return (
                                    <div key={index} className="location_wrapper">
                                        <div className="location_info">
                                            <div className="title">{location.name}</div>
                                            <div className="coordinates">{parseFloat(location.lat).toFixed(6)}, {parseFloat(location.lng).toFixed(6)}</div>
                                        </div>
                                        <div
                                            className="chose_location_btn"
                                            onClick={() => this.openLocationOnMap(location)}
                                        >
                                            {t("btn_chose")}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <div className="shop_options_container">
                        <div className="shop_options_header">
                            <div
                                className="shopping_cart_wrapper"
                                style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? { backgroundColor: shop.data.shop_color } : {}}
                            >
                                <img src={ShoppingCartIcon} alt="Shopping cart" />
                            </div>
                            <div className="items_value_container">
                                <div className="title">{t("total")}</div>
                                <div className="regular_price">
                                    {regular_price} {currency}
                                </div>
                                <div
                                    className="current_price"
                                    style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? { color: shop.data.shop_color } : {}}
                                >
                                    {current_price} {currency}
                                </div>
                            </div>
                            <div className="items_on_cart">
                                <div className="number_of_products">{cart.lineItems ? cart.lineItems.length : 0}</div>
                                <div className="desc">{t("products_in_cart")}</div>
                            </div>
                        </div>
                        <div className="shop_options_body">
                            <div className="saved">
                                <div className="title">{t("you_save")}</div>
                                <div className="value">{saved} {currency}</div>
                            </div>
                            <div className="shop_options" >
                                {(showNavigateOption) && (
                                    <div
                                        className="shop_option_navigate"
                                        onClick={() => {
                                            if (shop.data.locations.length === 1) {
                                                this.openLocationOnMap(shop.data.locations[0]);
                                            } else {
                                                this.choseLocation(shop.data.locations);
                                            }
                                        }}
                                    >
                                        <div
                                            className="icon_wrapper"
                                            style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? { backgroundColor: `${shop.data.shop_color}1a` } : {}}
                                        >
                                            <div
                                                className="icon"
                                                style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? { background: shop.data.shop_color } : {}}
                                            ></div>
                                        </div>
                                        <div className="title">{t("navigation")}</div>
                                    </div>
                                )}
                                {(shop?.data?.category?.name === "Menu") ? null : (
                                    <>
                                        {(showPaymentOption) && (
                                            <div
                                                className="shop_option_cart"
                                                onClick={() => {
                                                    this.completeCheckout();
                                                }}
                                            >
                                                <div
                                                    className="icon_wrapper"
                                                    style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? { backgroundColor: `${shop.data.shop_color}1a` } : {}}
                                                >
                                                    <div
                                                        className="icon"
                                                        style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? { background: shop.data.shop_color } : {}}
                                                    ></div>
                                                </div>
                                                <div className="title">{t("checkout")}</div>
                                            </div>
                                        )}
                                        {(showApplePayOption) && (
                                            <div
                                                className="shop_option_apple_pay"
                                                onClick={() => {
                                                    this.completeCheckout();
                                                }}
                                            >
                                                <div
                                                    className="icon_wrapper"
                                                    style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? { backgroundColor: `${shop.data.shop_color}1a` } : {}}
                                                >
                                                    <div
                                                        className="icon"
                                                    // style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? {background: shop.data.shop_color} : {}}
                                                    ></div>
                                                </div>
                                                <div className="title">{t("apple_pay")}</div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        checkout: state.checkout,
        cart: state.cart,
        shop: state.shop
    };
};

export default connect(mapStateToProps, {})(withTranslation()(ShopOptions));