const initialState = {
    lineItems: [],
    UPDATING_CART: false
}

const cartReducer = (state = initialState, action) => {
    if (action.type === "ADD_ITEM_TO_CART") {
        return {...state, lineItems: action.payload};
    }

    if (action.type === "ADDING_TO_CART") {
        return {...state, ADDING_TO_CART: action.payload}
    }

    if (action.type === "FETCH_CHECKOUT") {
        return {...state, lineItems: action.payload};
    }

    if (action.type === "UPDATING_CART") {
        return {...state, UPDATING_CART: action.payload}
    }

    if (action.type === "REMOVING_ITEM_FROM_CART") {
        return {...state, REMOVING_ITEM_FROM_CART: action.payload}
    }

    return state;
}

export default cartReducer;