import React from "react";
import { withTranslation } from "react-i18next";

import NoPromotionsImg from "./assets/img/no-promotions-img.svg";

import "./assets/style/style.css";

class NoPromotions extends React.Component {
    render() {
        const {
            custom_theme,
            shop_color,
            t
        } = this.props;
        return (
            <div className={`no_promotions_container ${custom_theme ? "custom_theme" : ""}`}>
                <div className="image_wrapper">
                    <div
                        className="bg_image"
                        style={(custom_theme && shop_color) ? {background: shop_color} : {}}
                    ></div>
                    <div className="promotion_img">
                        <img src={NoPromotionsImg} alt="No promotions" />
                    </div>    
                </div>
                <div className="message_container">
                    <h1>{t("no_active_promotions_title")}</h1>
                    <div className="message">
                        {t("no_active_promotions_desc")}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(NoPromotions);