const initialState = {
    collections_data: {
        total_pages: 0,
        collections: []
    },
    LOADING: true,
}

const collectionsReducer = (state = initialState, action) => {
    if (action.type === "FETCH_COLLECTIONS"){
        return {...state, collections_data: action.payload };
    }

    if (action.type === "FETCHING_COLLECTIONS"){
        return { ...state, LOADING: action.payload };
    }

    return state;
}

export default collectionsReducer;