const defaultValues = {
    products_data: {
        category_title: "",
        total_pages: 0,
        products: []
    },
    ERROR: false,
    LOADING: true
};

const productsReducer = (state = defaultValues, action) => {
    if (action.type === "FETCH_PRODUCTS") {
        return action.payload;
    }

    if (action.type === "FETCH_PRODUCTS_COLLECTION") {
        return { ...state, products_data: action.payload };
    }

    if (action.type === "FETCH_PRODUCTS_COLLECTION_ERROR") {
        return { ...state, ERROR: action.payload };
    }

    if (action.type === "FETCH_PRODUCTS_COLLECTION_LOADING") {
        return { ...state, LOADING: action.payload };
    }

    if (action.type === "FETCHING_PRODUCTS_BY_COLLECTION") {
        return { ...state, LOADING: action.payload };
    }

    return state;
}

export default productsReducer;