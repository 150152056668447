import React from "react";
import { withTranslation } from "react-i18next";

import strollerSupermarket from "./assets/img/stroller_supermarket.svg";

import "./assets/style/style.css";

class EmptyShoppingCart extends React.Component {
    render() {
        const {
            custom_theme,
            shop_color,
            t
        } = this.props;
        return (
            <div className={`empty_cart_container ${custom_theme ? "custom_theme" : ""}`}>
                <div className="image_wrapper">
                    <div
                        className="bg_image"
                        style={(custom_theme && shop_color) ? {background: shop_color} : {}}
                    ></div>
                    <div className="stroller_supermarket">
                        <img src={strollerSupermarket} alt="Stroller supermarket"/>    
                    </div>    
                </div>
                <div className="text_wrapper">
                    <h1>{t("empty_cart_title")}</h1>
                    <div className="subtitle">
                        {t("empty_cart_desc")}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(EmptyShoppingCart);