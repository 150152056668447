import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

// Components
import TabBar from "../tabBar/tabBar";
import EmptyArchivePage from "../infoScreens/emptyArchivePage/index";

import history from "../history";

import "./style.css";

class orderArchive extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    getTotalSum = (items, saved = false, total = false) => {
        const ItemsPrice = {
            current_price: 0,
            saved: 0,
            currency: ""
        };

        items.forEach((item) => {
            ItemsPrice.current_price += item.variant.priceV2.amount ? (parseFloat(item.variant.priceV2.amount) * item.quantity) : 0;
            if (item.variant.compareAtPrice) {
                ItemsPrice.saved += (item.variant.compareAtPrice * item.quantity) - (item.variant.priceV2.amount * item.quantity);
            }
            ItemsPrice.currency = item.variant.priceV2.currencyCode;
        });

        if (saved) {
            return ItemsPrice.saved.toFixed(2).replace(/\.00$/, '') + " " + ItemsPrice.currency;
        } else if (total) {
            return ItemsPrice.current_price.toFixed(2).replace(/\.00$/, '') + " " + ItemsPrice.currency;
        }
    }

    renderArchives = () => {
        const { shop, checkout, t } = this.props,
            archives = localStorage.getItem(shop.data.id);

        if (archives && (JSON.parse(archives).length > 0) && checkout?.data?.id) {
            const carts = JSON.parse(archives);
            return (
                <>
                    <div className="devider"></div>
                    {
                        carts.map((cart, index) => {
                            if (cart.completeItems && (cart.completeItems.length > 0)) {
                                return (
                                    <React.Fragment key={index}>
                                        <div
                                            className="archive_container"
                                            onClick={() => history.push("/order-items", { items: cart.completeItems })}
                                        >
                                            <div className="status">
                                                <div className="text">{t("status")} <span>{(checkout.data.id === cart.cart_id) ? t("active") : t("completed")}</span></div>
                                                <div
                                                    className="icon"
                                                    style={(shop.data.custom_theme && shop.data.shop_color) ? { background: shop.data.shop_color } : {}}
                                                ></div>
                                            </div>
                                            <div className="items">
                                                <div className="text">{t("items")}</div>
                                                <div className="number">{cart.completeItems.length}</div>
                                            </div>
                                            <div
                                                className="saved"
                                                style={(shop.data.custom_theme && shop.data.shop_color) ? { color: shop.data.shop_color } : {}}
                                            >
                                                <div className="text">{t("saved_money")}</div>
                                                <div className="number">{this.getTotalSum(cart.completeItems, true)}</div>
                                            </div>
                                            <div className="total">
                                                <div className="text">{t("total")}</div>
                                                <div
                                                    className="number"
                                                    style={(shop.data.custom_theme && shop.data.shop_color) ? { color: shop.data.shop_color } : {}}
                                                >
                                                    {this.getTotalSum(cart.completeItems, false, true)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="devider"></div>
                                    </React.Fragment>
                                );
                            } else {
                                return null;
                            }
                        })
                    }
                </>
            )
        } else {
            return <EmptyArchivePage
                custom_theme={shop.data.custom_theme}
                shop_color={shop.data.shop_color}
            />;
        }
    }

    render() {
        const { t, shop } = this.props,
            { shop_color, custom_theme } = shop.data;
        return (
            <>
                <div className="order_archive_container">
                    <div className="header">
                        <div className="go_back" onClick={() => history.goBack()}>
                            <div
                                className="angle_left_icon"
                                style={(custom_theme && shop_color) ? { background: shop_color } : {}}
                            ></div>
                            <span
                                style={(custom_theme && shop_color) ? { color: shop_color } : {}}
                            >
                                {t("btn_back")}
                            </span>
                        </div>
                        <h2 className="header_title">{t("order_archive_title")}</h2>
                    </div>
                    <div className="body_container">
                        {this.renderArchives()}
                    </div>
                </div>
                <TabBar />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        shop: state.shop,
        checkout: state.checkout
    };
};

export default connect(mapStateToProps, {})(withRouter(withTranslation()(orderArchive)));