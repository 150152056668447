import React from "react";
import { withTranslation } from "react-i18next";

import ShopNotFoundImg from "./../images/shop-not-found.svg";

import "./style.css";

class ExpiredShop extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div className="expired_shop_container">
                <img src={ShopNotFoundImg} alt="Expired shop" />
                <div className="title_wrapper">
                    <h1>{t("expired_shop_title")}</h1>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ExpiredShop);