import React from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

export const Card = ({ child, itemId }) => {
    return (
        <div role="button" tabIndex={0} className="card">
            {child}
        </div>
    );
};

const Arrow = ({ children, disabled, onClick, position }) => {
    const style = {
        position: "absolute",
        top: "50%",
        cursor: "pointer",
        flexDirection: "column",
        justifyContent: "center",
        // display: disabled ? "none" : "flex",
        userSelect: "none",
        zIndex: "2",
        display: "none"
    };

    if (position === "left") {
        style.left = "1%";
    } else {
        style.right = "1%";
    }
    return (
        <button disabled={disabled} onClick={onClick} style={style}>
            {children}
        </button>
    );
};

export const LeftArrow = () => {
    const {
        isFirstItemVisible,
        scrollPrev,
        visibleItemsWithoutSeparators,
        initComplete,
    } = React.useContext(VisibilityContext);

    const [disabled, setDisabled] = React.useState(
        !initComplete || (initComplete && isFirstItemVisible)
    );
    React.useEffect(() => {
        // NOTE: detect if whole component visible
        if (visibleItemsWithoutSeparators.length) {
            setDisabled(isFirstItemVisible);
        }
    }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

    return (
        <Arrow disabled={disabled} onClick={() => scrollPrev()} position="left">
            <FaAngleLeft />
        </Arrow>
    );
};

export const RightArrow = ({ limit, pushNewItems }) => {
    const {
        isLastItemVisible,
        scrollNext,
        visibleItemsWithoutSeparators,
        items,
    } = React.useContext(VisibilityContext);
    const [disabled, setDisabled] = React.useState(
        !visibleItemsWithoutSeparators.length && isLastItemVisible
    );
    React.useEffect(() => {
        if (isLastItemVisible) {
            pushNewItems();
        }
        if (items.toItemsWithoutSeparators().length >= limit) {
            setDisabled(isLastItemVisible);
        }
    }, [items, limit, isLastItemVisible, pushNewItems]);

    return (
        <Arrow disabled={disabled} onClick={() => scrollNext()} position="right">
            <FaAngleRight />
        </Arrow>
    );
};