import React from "react";
import { withTranslation } from "react-i18next";

import ShopNotFoundImg from "./assets/img/shop-not-found.svg";

import "./assets/style/shop_not_found.css";

class ShopNotFound extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div className="shop_not_found_info_container">
                <img src={ShopNotFoundImg} alt="Shop not found" />
                <div className="title_wrapper">
                    <h1>{t("marker_not_found_title")}</h1>
                    {/* <div>
                        {t("marker_not_found_desc_1")}<br/>{t("marker_not_found_desc_2")}
                    </div>     */}
                </div>
            </div>
        )
    }
}

export default withTranslation()(ShopNotFound);