import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";
import moment from "moment";

const sourceOfEvent = 2,
    platform = 3;

const TopProductDetails = (storeId, productId, isTopProduct) => {
    logEvent(analytics, "product_details_shown", {
        sourceOfEvent,
        platform,
        storeId,
        productId,
        isTopProduct
    });
};

const AddProductToCart = (storeId, source, productId) => {
    logEvent(analytics, "product_add_to_list", {
        sourceOfEvent,
        platform,
        storeId,
        source,
        productId
    });
};

const RemoveProductFromCart = (storeId, productId) => {
    logEvent(analytics, "product_removed_from_list", {
        sourceOfEvent,
        platform,
        storeId,
        productId
    });
};

const ProductCompleted = (storeId, productId, productQuantity) => {
    logEvent(analytics, "product_completed", {
        sourceOfEvent,
        platform,
        storeId,
        productId,
        productQuantity
    });
};

const RemoveProduct = (storeId, productQuantity) => {
    logEvent(analytics, "product_delete", {
        sourceOfEvent,
        platform,
        storeId,
        productQuantity
    });
};

const OrderCreate = (storeId, orderId) => {
    logEvent(analytics, "order_create", {
        sourceOfEvent,
        platform,
        storeId,
        orderId
    });
};

// TODO: Need to find solution to implement this for Shopify
const OrderFinished = (storeId, orderId) => {
    logEvent(analytics, "order_finished", {
        sourceOfEvent,
        platform,
        storeId,
        orderId
    });
};

const OrderPayingStarted = (storeId, orderId) => {
    logEvent(analytics, "order_paying_started", {
        sourceOfEvent,
        platform,
        storeId,
        orderId
    });
};

const CategoryOpen = (storeId, categoryName) => {
    logEvent(analytics, "category_open", {
        sourceOfEvent,
        platform,
        storeId,
        categoryName // sending category id
    });
};

const ShoppingListShow = (storeId) => {
    logEvent(analytics, "shopping_list_show", {
        sourceOfEvent,
        platform,
        storeId
    });
};

const ShoppingListEdit = (storeId) => {
    logEvent(analytics, "shopping_list_edit_tap", {
        sourceOfEvent,
        platform,
        storeId
    });
}

const ProductSearch = (storeId) => {
    logEvent(analytics, "product_search", {
        sourceOfEvent,
        platform,
        storeId
    });
};

const ProductSearched = (storeId) => {
    logEvent(analytics, "product_searched", {
        sourceOfEvent,
        platform,
        storeId
    });
};

const SearchSuggestionTapped = (storeId) => {
    logEvent(analytics, "product_suggestion_tap", {
        sourceOfEvent,
        platform,
        storeId
        // categoryName: String
    });
};

const CatalogueOpen = (storeId) => {
    logEvent(analytics, "catalogue_open", {
        sourceOfEvent,
        platform,
        storeId: storeId
    });
};

const ShoppingListShared = (storeId) => {
    logEvent(analytics, "shopping_list_shared", {
        sourceOfEvent,
        platform,
        storeId
    });
}

const GiveawayShown = (deviceId, storeId, giveawayId) => {
    logEvent(analytics, "giveaway_shown", {
        deviceId,
        platform,
        storeId,
        giveawayId,
        date: moment.utc().format("YYYY-MM-DD*HH:mm:ss")
    });
}

const GiveawayAction = (deviceId, storeId, giveawayId, giveawayAction) => {
    logEvent(analytics, "giveaway_action", {
        deviceId,
        platform,
        storeId,
        giveawayId,
        date: moment.utc().format("YYYY-MM-DD*HH:mm:ss"),
        giveawayAction
    });
}

const GiveawayReshown = (deviceId, storeId, giveawayId) => {
    logEvent(analytics, "giveaway_reshown", {
        deviceId,
        platform,
        storeId,
        giveawayId,
        date: moment.utc().format("YYYY-MM-DD*HH:mm:ss")
    });
}

const PromotionsShown = (deviceId, storeId) => {
    logEvent(analytics, "promotions_shown", {
        deviceId,
        sourceOfEvent,
        platform,
        storeId
    });
}

const HomeScreenShown = (deviceId, storeId) => {
    logEvent(analytics, "home_screen_shown", {
        deviceId,
        sourceOfEvent,
        platform,
        storeId
    });
}

// eslint-disable-next-line
export default {
    topProductDetails: TopProductDetails,
    addProductToCart: AddProductToCart,
    removeProductFromCart: RemoveProductFromCart,
    productCompleted: ProductCompleted,
    removeProduct: RemoveProduct,
    orderCreate: OrderCreate,
    orderFinished: OrderFinished,
    orderPayingStarted: OrderPayingStarted,
    categoryOpen: CategoryOpen,
    shoppingListShow: ShoppingListShow,
    shoppingListEdit: ShoppingListEdit,
    productSearch: ProductSearch,
    productSearched: ProductSearched,
    searchSuggestionTapped: SearchSuggestionTapped,
    catalogueOpen: CatalogueOpen,
    shoppingListShared: ShoppingListShared,
    giveawayShown: GiveawayShown,
    giveawayAction: GiveawayAction,
    giveawayReshown: GiveawayReshown,
    promotionsShown: PromotionsShown,
    homeScreenShown: HomeScreenShown
};