import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import GoogleMapReact from "google-map-react";
import { withTranslation } from "react-i18next";

import logo_image from "../catalog/assets/img/logo_image.svg";
import mapPin from "./assets/images/pinmap.svg";

import "./assets/css/info.css";

const AnyReactComponent = ({ imgPin }) => <div><img src={imgPin} alt="map_pin" /></div>;

class Info extends React.Component {

    openLocationOnMap = (coordinates) => {
        if (parseFloat(coordinates.lat) && parseFloat(coordinates.lng)) {
            const platform = navigator?.userAgentData?.platform || navigator?.platform || "unknown";
            if ((platform.indexOf("iPhone") !== -1)
                || (platform.indexOf("iPod") !== -1)
                || (platform.indexOf("iPad") !== -1)
            ) {
                window.open(`maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${parseFloat(coordinates.lat)},${parseFloat(coordinates.lng)}`);
            }
            else {
                window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${parseFloat(coordinates.lat)},${parseFloat(coordinates.lng)}`);
            }
        }
    }

    contactFunction = (type, value) => {
        if (value) {
            switch (type) {
                case "sms":
                    window.open(`sms:${value}`);
                    break;
                case "email":
                    window.open(`mailto:${value}`);
                    break;
                case "website":
                    if ((value.indexOf("http://") < 0) && (value.indexOf("https://") < 0)) {
                        value = `https://${value}`;
                    }
                    window.open(value, "_blank");
                    break;
                case "tel":
                    window.open(`tel:${value}`);
                    break;
                default:
                    break;
            }
        }
    }

    render() {
        let { shop, t, shopInfoModal } = this.props,
            center = shopInfoModal
                ? (shop.data.locations.length > 0)
                    ? shop.data.locations[0]
                    : null
                : (parseFloat(process.env.REACT_APP_CONTACT_INFO_LATITUDE) && parseFloat(process.env.REACT_APP_CONTACT_INFO_LONGITUDE))
                    ? {
                        lat: parseFloat(process.env.REACT_APP_CONTACT_INFO_LATITUDE),
                        lng: parseFloat(process.env.REACT_APP_CONTACT_INFO_LONGITUDE),
                        name: process.env.REACT_APP_CONTACT_INFO_ADDRESS
                    } : null;
        const email = shopInfoModal ? shop.data.contact_email || null : process.env.REACT_APP_CONTACT_INFO_EMAIL || null,
            phone = shopInfoModal ? shop.data.contact_phone || null : process.env.REACT_APP_CONTACT_INFO_PHONE || null,
            message = shopInfoModal ? shop.data.contact_phone || null : process.env.REACT_APP_CONTACT_INFO_MESSAGE || null,
            website = shopInfoModal ? shop.data.website || null : process.env.REACT_APP_CONTACT_INFO_WEBSITE || null,
            shop_color = shopInfoModal ? shop?.data?.shop_color || null : null;
        return (
            <div className="contactInfoContainer">
                <div className="infoLogoContainer">
                    {shopInfoModal ? (
                        (shop?.data?.catalogue_icon) ? (
                            <div className="shop_image">
                                <img src={shop.data.catalogue_icon} alt="logo" />
                            </div>
                        ) : null
                    ) : (
                        <div className="logoContainer">
                            <img src={logo_image} alt="logo" />
                        </div>
                    )}
                    <h2 className="featherTitle" style={shop_color ? {color: shop_color} : {}}>
                        {shopInfoModal ? (
                            shop?.data?.name || ""
                        ) : (
                            "Feather"
                        )}
                    </h2>
                    <h3 className="featherSubTitle">
                        {!shopInfoModal && (
                            <>
                                Powered by <a href="https://echo.com.mk/" rel="noopener noreferrer" target="_blank">Echo</a>
                            </>
                        )}
                    </h3>
                </div>
                <div className="contactBoxes">
                    <div className="contactBox">
                        <div className="contact_type_container" onClick={() => this.contactFunction("sms", message)}>
                            <div
                                className="sms_icon"
                                style={message ? shop_color ? {backgroundColor: shop_color} : {} : {backgroundColor: "#DCDCDD"}}
                            ></div>
                            <p
                                className="boxText"
                                style={message ? shop_color ? {color: shop_color} : {} : {color: "#DCDCDD"}}
                            >{t("message")}</p>
                        </div>
                    </div>
                    <div className="contactBox">
                        <div className="contact_type_container" onClick={() => this.contactFunction("email", email)}>
                            <div
                                className="email_icon"
                                style={email ? shop_color ? {backgroundColor: shop_color} : {} : {backgroundColor: "#DCDCDD"}}
                            ></div>
                            <p
                                className="boxText"
                                style={email ? shop_color ? {color: shop_color} : {} : {color: "#DCDCDD"}}
                            >{t("mail")}</p>
                        </div>
                    </div>
                    <div className="contactBox">
                        <div className="contact_type_container" onClick={() => this.contactFunction("website", website)}>
                            <div
                                className="web_icon"
                                style={website ? shop_color ? {backgroundColor: shop_color} : {} : {backgroundColor: "#DCDCDD"}}
                            ></div>
                            <p
                                className="boxText"
                                style={website ? shop_color ? {color: shop_color} : {} : {color: "#DCDCDD"}}
                            >{t("website")}</p>
                        </div>
                    </div>
                    <div className="contactBox">
                        <div className="contact_type_container" onClick={() => this.contactFunction("tel", phone)}>
                            <div
                                className="phone_icon"
                                style={phone ? shop_color ? {backgroundColor: shop_color} : {} : {backgroundColor: "#DCDCDD"}}
                            ></div>
                            <p
                                className="boxText"
                                style={phone ? shop_color ? {color: shop_color} : {} : {color: "#DCDCDD"}}
                            >{t("call")}</p>
                        </div>
                    </div>
                </div>
                {(center && parseFloat(center.lat) && parseFloat(center.lng)) && (
                    <>
                        <div className="mapContainer">
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: "AIzaSyC8dVo6pkrtvouyr-lpHWAYSAfsxuOJxRw" }}
                                center={{ lat: parseFloat(center.lat), lng: parseFloat(center.lng) }}
                                zoom={center ? 11 : 7}
                            >
                                <AnyReactComponent
                                    lat={parseFloat(center.lat)}
                                    lng={parseFloat(center.lng)}
                                    imgPin={mapPin}
                                />
                            </GoogleMapReact>
                        </div>
                        <div
                            className="infoAddress"
                            onClick={() => {
                                if (center) {
                                    this.openLocationOnMap(center);
                                }
                            }}
                        >
                            <div
                                className="locationPin"
                                style={shop_color ? {backgroundColor: shop_color} : {}}
                            ></div>
                            <p className="locationAddressText">
                                {shopInfoModal
                                    ? center?.name || shop.data.country || ""
                                    : center
                                        ? center?.name || "" : ""
                                }
                            </p>
                        </div>
                    </>
                )}
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        shop: state.shop
    };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(Info)));