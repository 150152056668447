import i18n from "./localization/i18n";
import { t } from "i18next";
import moment from "moment";

// Icons
import copyIcon from "./components/images/copy-icon.svg";
import checkMarkIcon from "./components/images/checkmark-icon.svg";
import messageIcon from "./components/images/message-icon.svg";
import whatsappIcon from "./components/images/whatsapp-icon.svg";

export const setLanguage = (lng = null) => {
    if (lng) {
        i18n.changeLanguage(lng);
    }
}

export const DynamicPriceFontSize = (elementWidth, price, maxFontSize, sizeCorrection = 0) => {
    let PriceFontSize = maxFontSize;
    if (elementWidth && price) {
        PriceFontSize = (elementWidth / price.length) + sizeCorrection;
        if (price.includes(".")) {
            PriceFontSize = PriceFontSize + 1;
        }
        if (maxFontSize && ((PriceFontSize > maxFontSize) || (price.length < 4))) {
            PriceFontSize = maxFontSize;
        }
    }

    return (
        <p style={{fontSize: `${PriceFontSize}px`, margin: 0}}>
            {price}
        </p>
    )
}

export const ActionTimeout = 2500;

// Share Options Properties
// type - Required
// name - Required (name of the share option) -> Will be used for localization if exist. Otherwise will be shown as is written
// icon - Required (icon for the chare option) -> Can be imported image or image url
// title - Required for type: "mail" (will be used as subject for mail)
// mobileUrl - Required (except type: "copy")
// successIcon - Optional (only for type: "copy") -> Can be imported image or image url
// style - Optional (style for share option background)
// imageStyle - Optional (style for the icon for share option)
// webUrl - Optional (redirect to web url if user does not have installed application for this share option)
// includeText - Add description text for shared url on the biginning of the url (do not use this property if textParam is used)
// textParam - Add query parameter (accepted from API where you share the link) for description text on the shared URL (do not use this property if includeText is used)

export const ShareOptions = [
    {
        type: "copy",
        name: "btn_copy",
        icon: copyIcon,
        successIcon: checkMarkIcon,
        style: {
            background: "#8E8E93"
        }
    }, {
        type: "message",
        name: "Message",
        mobileUrl: "sms:?&body=",
        icon: messageIcon,
        style: {
            background: "linear-gradient(180deg, #67FF81 0%, #01B41F 100%)"
        }
    }, {
        type: "whatsapp",
        name: "Whatsapp",
        mobileUrl: "whatsapp://send?text=",
        webUrl: "https://api.whatsapp.com/send?text=",
        includeText: true,
        icon: whatsappIcon,
        style: {
            background: "#25D366"
        }
    }
];

export const onSaleTo = (date, theme = null) => {
    if ((moment(date.from).isValid() && (moment(date.from).utc().diff(moment().utc()) > 0)) || (moment(date.to).isValid() && (moment(date.to).utc().diff(moment().utc()) > 0))) {
        return (
            <div
                className="on_sale_to"
                style={(theme?.custom_theme && theme?.shop_color) ? {backgroundColor: theme.shop_color} : {}}
            >
                {(moment(date.from).isValid() && (moment(date.from).utc().diff(moment().utc()) > 0))
                    ? t("on_sale_from")
                    : t("on_sale_to")
                } &nbsp;
                <span className="date">
                    {(moment(date.from).isValid() && (moment(date.from).utc().diff(moment().utc()) > 0))
                        ? moment(date.from).format("DD.MM")
                        : moment(date.to).format("DD.MM")
                    }
                </span>
            </div>
        );
    } else {
        return null;
    }
}

export const calcPercentage = (regular_price, sale_price) => {
    if (regular_price > sale_price) {
        const percentage = Math.round(((regular_price - sale_price) / regular_price) * 100);
        if (isNaN(percentage)) {
            return "";
        } else {
            return `-${percentage}%`;
        }
    } else {
        return "";
    }
}

export const removeUnnecessaryDecimals = (price) => {
    if ((price !== undefined) && (price !== null) && (price !== "")) {
        price = price.toString().replace(/\.00$/,'');
    }
    return price;
}