import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withAlert } from "react-alert";
import { withTranslation } from "react-i18next";

import { fetchCheckout } from "../../../actions/checkout/index";
import WoocommerceApi from "../../../apis/woocommerceApi";
import Spinner from "../../spinner/index";
import history from "../../history";
import { CleanCart } from "../../../actions/cart";
import { subscribeToCheckout } from "../../../apis/apiData";
import analyticsEvents from "../../../analyticsEvents";

import "./checkoutForm.css";

const BillingForm = [
    {label: "First name", type: "text", name: "first_name", required: true},
    {label: "Last name", type: "text", name: "last_name", required: true},
    {label: "Address 1", type: "text", name: "address_1", required: true},
    {label: "Address 2", type: "text", name: "address_2", required: false},
    {label: "City", type: "text", name: "city", required: true},
    {label: "State", type: "text", name: "state", required: true},
    {label: "Postcode", type: "number", name: "postcode", required: true},
    {label: "Country", type: "select", name: "country", required: true},
    {label: "Email", type: "email", name: "email", required: true},
    {label: "Phone", type: "text", name: "phone", required: true},
];

const ShippingForm = [
    {label: "First name", type: "text", name: "first_name", required: true},
    {label: "Last name", type: "text", name: "last_name", required: true},
    {label: "Address 1", type: "text", name: "address_1", required: true},
    {label: "Address 2", type: "text", name: "address_2", required: false},
    {label: "City", type: "text", name: "city", required: true},
    {label: "State", type: "text", name: "state", required: true},
    {label: "Postcode", type: "number", name: "postcode", required: true},
    {label: "Country", type: "select", name: "country", required: true},
];

class CheckoutForm extends React.Component {

    constructor() {
        super();
        this.state = {
            Loading: true,
            ShippingAddress: false,
            processing: false,
            paymentGateways: [],
            countriesData: [],
            payment_method: "",
            payment_method_title: "",
            billing: {
                first_name: "",
                last_name: "",
                address_1: "",
                address_2: "",
                city: "",
                state: "",
                postcode: "",
                country: "",
                email: "",
                phone: ""
            },
            shipping: {
                first_name: "",
                last_name: "",
                address_1: "",
                address_2: "",
                city: "",
                state: "",
                postcode: "",
                country: ""
            },
            form_errors: {}
        }
    }

    componentDidMount() {
        this.getFormData();
        const body = document.querySelector("body");
        body.style.removeProperty("overflow");
    }

    getFormData = async () => {
        const { shop } = this.props;
        let paymentGateways = [];
        let countriesData = [];

        await WoocommerceApi(shop.data).get("payment_gateways")
            .then((res) => {
                paymentGateways = res.data;
            }).catch((e) => {
                console.error(e.response.data);
            });
        
        await WoocommerceApi(shop.data).get("data/countries")
            .then((res) => {
                countriesData = res.data;
            }).catch((e) => {
                console.error(e.response.data);
            });

        this.setState({
            paymentGateways,
            countriesData,
            Loading: false
        });
    }

    renderTableBody = () => {
        const { cart, vendor } = this.props;
        if(cart.lineItems.length > 0){
            return cart.lineItems.map((item) => {
                return (
                    <tr key={item.id}>
                        <td className="cartItemBodyLeft"><img src={item.variant.image.src} alt="cart_product_img" width="100%"/></td>
                        <td className="cartItemBodyMiddle">
                            <p>{item.title}</p>
                            <p>{vendor.data}</p>
                        </td>
                        <td className="cartItemBodyRight">
                            <div>{`${item.variant.priceV2.currencyCode} ${item.variant.priceV2.amount}`}</div>
                            <p>x{item.quantity}</p>
                        </td>
                    </tr>
                );
            })
        }
    }

    handleChange = (parent, name, value, title = null) => {
        const state = this.state;
        if (parent) {
            state[parent][name] = value;
        } else {
            if (name === "payment_method") {
                state[name] = value;
                state.payment_method_title = title;
                if (title !== "") {
                    delete state.form_errors.payment_method_title;
                }
            } else {
                state[name] = value;
            }
        }
        if (value.length > 0) {
            if (parent) {
                switch (name) {
                    case "email":
                        //eslint-disable-next-line
                        if (state[parent][name].match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
                            delete state.form_errors[`${parent}_${name}`];
                        } else {
                            state.form_errors[`${parent}_${name}`] = 2;
                        }
                        break;
                    case "phone":
                        //eslint-disable-next-line
                        if (state[parent][name].match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g)) {
                            delete state.form_errors[`${parent}_${name}`];
                        } else {
                            state.form_errors[`${parent}_${name}`] = 2;
                        }
                        break;
                    default:
                        delete state.form_errors[`${parent}_${name}`];
                        break;
                }
            } else {
                delete state.form_errors[name];
            }
        } else {
            if (parent) {
                state.form_errors[`${parent}_${name}`] = true;
            } else {
                state.form_errors[name] = true;
            }
        }

        this.setState(state);
    }

    renderCheckoutFrom = () => {
        const { billing, paymentGateways, shipping, ShippingAddress, form_errors } = this.state,
            { t } = this.props;
        return (
            <>
                <div className="billingFormContainer">
                    <div className="checkoutFormTitle">{t("billing_info")}</div>
                    <form>
                        {BillingForm.map((input, index) => {
                            if (input.type === "select") {
                                return this.renderSelect("billing", billing[input.name], input.name, t(input.name), index);
                            } else {
                                return(
                                    <label key={index}>
                                        {t(input.name)} {(form_errors[`billing_${input.name}`] === 2) && (<span>{t("invalid_input_value")}</span>)}
                                        <input
                                            type={input.type}
                                            value={billing[input.name]}
                                            onChange={(e) => this.handleChange("billing", input.name, e.target.value)}
                                            required={input.required}
                                            className={form_errors[`billing_${input.name}`] ? "red_border" : ""}
                                        />
                                    </label>
                                );
                            }
                        })}
                    </form>
                </div>
                <label>
                    <input
                        name="Use Shipping Address"
                        type="checkbox"
                        checked={ShippingAddress}
                        onChange={(e) => {this.handleChange(null, "ShippingAddress", e.target.checked)}}
                    />
                    {t("other_shipping_address")}
                </label>
                {ShippingAddress && (
                    <div className="shippingFormContainer">
                        <div className="checkoutFormTitle">{t("shipping_info")}</div>
                        <form>
                            {ShippingForm.map((input, index) => {
                                if (input.type === "select") {
                                    return this.renderSelect("shipping", shipping[input.name], input.name, t(input.name), index);
                                } else {
                                    return(
                                        <label key={index}>
                                            {t(input.name)}
                                            <input
                                                type={input.type}
                                                value={shipping[input.name]}
                                                onChange={(e) => this.handleChange("shipping", input.name, e.target.value)}
                                                required={input.required}
                                                className={form_errors[`shipping_${input.name}`] ? "red_border" : ""}
                                            />
                                        </label>
                                    )
                                }
                            })}
                        </form>
                    </div>
                )}
                {paymentGateways.length > 0 && (
                    <div className="paymentGateways">
                        <div className="checkoutFormTitle">{t("payment_methods")}</div>
                        <div onChange={(e) => this.handleChange(null, e.target.name, e.target.value, e.target.title)}>
                            {paymentGateways.map((payment, index) => {
                                return(
                                    <div key={index} className={form_errors["payment_method"] ? "red_text" : ""}>
                                        <input
                                            type="radio"
                                            value={payment.id}
                                            name="payment_method"
                                            title={payment.method_title}
                                            id={payment.id}
                                        />
                                        <label htmlFor={payment.id}>{payment.method_title}</label>
                                        {payment.description && <p>{payment.description}</p>}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </>
        );
    }

    renderSelect = (form, value, name, label, i) => {
        const { countriesData, form_errors } = this.state,
            { t } = this.props;
        return(
            <label key={i}>
                {label}
                <select
                    value={value}
                    onChange={(e) => this.handleChange(form, name, e.target.value)}
                    className={form_errors[`${form}_${name}`] ? "red_border" : ""}
                >
                    <option value="">
                        {t("select_country")}
                    </option>
                    {countriesData.map((country, index) => {
                        return(
                            <option
                                key={index}
                                value={country.code}
                            >
                                {country.name}
                            </option>
                        )
                    })}
                </select>
            </label>
        )
    }

    completeCheckout = () => {
        let {
            form_errors,
            billing,
            shipping,
            ShippingAddress,
            payment_method,
            payment_method_title,
        } = this.state;
        const { shop, t, checkout } = this.props;
        let hasError = false;

        this.setState({
            processing: true
        });

        if (ShippingAddress) {
            ShippingForm.map((input) => {
                if (input.required && (shipping[input.name] === "")) {
                    form_errors[`shipping_${input.name}`] = true;
                    hasError = true;
                } else {
                    delete form_errors[`shipping_${input.name}`];
                }
                return "";
            });
        }

        BillingForm.map((input) => {
            if (input.required && (billing[input.name] === "")) {
                form_errors[`billing_${input.name}`] = true;
                hasError = true;
            } else {
                delete form_errors[`billing_${input.name}`];
            }
            return "";
        });

        ["payment_method_title", "payment_method"].map((method) => {
            if (this.state[method] === "") {
                form_errors[method] = true;
                hasError = true;
            } else {
                delete form_errors[method];
            }
            return "";
        });

        this.setState({
            form_errors
        });

        if (!hasError) {
            const dataToSend = {
                payment_method,
                payment_method_title,
                billing,
                shipping,
                status: "on-hold"
            };
            WoocommerceApi(shop.data).put(`orders/${checkout.data.id}`, dataToSend)
            .then(async (response) => {
                if (response.status === 200) {
                    if (sessionStorage.getItem(shop.data.id)) {
                        sessionStorage.removeItem(shop.data.id);
                    }
                    subscribeToCheckout(null, checkout.data.id, response.data.id);
                    analyticsEvents.orderFinished(shop.data.id, checkout.data.id);
                    await this.props.CleanCart();
                    history.push("/order-complete", {data: response.data});
                } else {
                    this.props.alert.show(response.message);
                }
            })
            .catch((e) => {
                console.error(e.response.data);
            })
            .finally(() => {
                this.setState({
                    processing: false
                });
            });
        } else {
            this.props.alert.show(t("submit_error"));
            this.setState({
                processing: false
            });
        }
    }

    render() {
        const { history, shop, t } = this.props,
            { Loading, processing } = this.state,
            { shop_color } = shop.data,
            change_color = (shop.data.custom_theme && shop_color) || false;
        return(
            <div className="checkout_container">
                <div className="checkoutHeader">
                    <div className="go_back" onClick={() => history.goBack()}>
                        <div
                            className="angle_left_icon"
                            style={change_color ? {background: shop_color} : {}}
                        ></div>
                        <span
                            style={change_color ? {color: shop_color} : {}}
                        >
                            {t("btn_back")}
                        </span>
                    </div>
                </div>
                <h2 className="pageTitle">{t("checkout")}</h2>
                {Loading ? (
                    <div className="spinnerContainer">
                        <Spinner/>
                    </div>
                ) : (
                    <>
                        <div className="checkoutFromContainer">
                            {this.renderCheckoutFrom()}
                        </div>
                        <div className="cartItemsContainer">
                            <div className="cartItemsTitle">{t("cart_items")}</div>
                            <table className="cartItemsTable" cellSpacing="0" cellPadding="0">
                                    <tbody className="cartItemsTableBody">
                                        {this.renderTableBody()}
                                    </tbody>
                                </table>
                            <div className="totalBottom">
                                <div className="total">
                                    <p className="totalText">{t("total")}</p>
                                    <p className="totalSum">{history.location.state.total}</p>
                                </div>
                                <div className="checkoutButtonBottom">
                                    <button
                                        className="CheckoutButton"
                                        onClick={() => this.completeCheckout()}
                                        style={change_color ? {background: shop_color} : {}}
                                        disabled={processing}
                                    >
                                        {(processing) ? t("btn_processing") : t("checkout")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart,
        vendor: state.vendor,
        shop: state.shop,
        checkout: state.checkout
    };
};

export default connect(mapStateToProps, {
    fetchCheckout,
    CleanCart
})(withRouter(withAlert()(withTranslation()(CheckoutForm))));