import shopifyClient from "../../apis/shopifyApi";
import WooCommerceAPI from "./../../apis/woocommerceApi";

import { createCheckout, fetchCheckout } from "../checkout";
import { orderNotifications, NotificatinCheckoutTokens } from "../../apis/apiData";

import { ActionTimeout } from "../../globalFunctions";

const addItem = async (stateItems, newItems) => {
    if (stateItems) {
        await newItems.map((item) => {
            const index = stateItems.findIndex(i => i.id === item.product.variants[0].id);
            if (index > -1) {
                return stateItems[index].quantity = stateItems[index].quantity + item.quantity;
            } else {
                return stateItems.push({
                    id: item.product.id,
                    quantity: item.quantity,
                    title: item.product.title,
                    meta_data: item.product.meta_data || null,
                    variant: {
                        id: item.product.variants[0].id,
                        compareAtPrice: item.product.variants[0].compareAtPrice,
                        priceV2: {
                            amount: item.product.variants[0].price !== "" ? item.product.variants[0].price : 0,
                            currencyCode: item.product.variants[0].priceV2.currencyCode
                        },
                        image: {src: item.product.images[0].src},
                        product: {
                            collections: [{
                                id: item?.product?.variants[0]?.product?.collections[0]?.id || null,
                                title: item?.product?.variants[0]?.product?.collections[0]?.title || ""
                            }]
                        }
                    }
                });
            }
        });
        return stateItems;
    } else {
        return newItems.map((item) => {
            return {
                id: item.product.id,
                quantity: item.quantity,
                title: item.product.title,
                meta_data: item.product.meta_data || null,
                variant: {
                    id: item.product.variants[0].id,
                    compareAtPrice: item.product.variants[0].compareAtPrice,
                    priceV2: {
                        amount: item.product.variants[0].price !== "" ? item.product.variants[0].price : 0,
                        currencyCode: item.product.variants[0].priceV2.currencyCode
                    },
                    image: {src: item.product.images[0].src},
                    product: {
                        collections: [{
                            id: item?.product?.variants[0]?.product?.collections[0]?.id || null,
                            title: item?.product?.variants[0]?.product?.collections[0]?.title || ""
                        }]
                    }
                }
            };
        });
    }
}

const updateItemQuantity = (stateItems, updateItem) => {
    if (stateItems && updateItem) {
        updateItem.map((item) => {
            const index = stateItems.findIndex(i => i.id === item.id);
            if (index > -1) {
                stateItems[index].quantity = item.quantity;
            }
            return true;
        });
    }
    return stateItems;
}

let creatingCheckout = false;
let Items = {line_items: []};
let newItems = [];

let itemsToBe = {
    add: [],
    remove: []
};
let itemsActionTimeout = null;

export const addRemoveItem = (action = null, item, reRender = true) => {
    return async function (dispatch, getState) {
        if (action !== null) {
            const cart = getState().cart;
            switch (action) {
                case "add":
                    const isOnCartAdd = (cart.lineItems.length > 0) ? cart.lineItems.findIndex(i => i.id === item.product.id) : -1;
                    const toBeRemoved = itemsToBe.remove.findIndex(i => i.id === item.product.id);
                    if ((isOnCartAdd === -1) && (toBeRemoved === -1)) {
                        itemsToBe.add.push(item);
                    } else {
                        itemsToBe.remove.splice(toBeRemoved, 1);
                    }
                    await dispatch(addToCart([item]));
                    break;
                case "remove":
                    if (Array.isArray(item)) {
                        await item.forEach((p) => {
                            const isOnCartRemove = (cart.lineItems.length > 0) ? cart.lineItems.findIndex(i => i.id === p.id) : -1;
                            const toBeAdded = itemsToBe.add.findIndex(i => i.product.id === p.id);
                            if ((isOnCartRemove > -1) && (toBeAdded === -1)) {
                                itemsToBe.remove.push(p);
                            } else {
                                itemsToBe.add.splice(toBeAdded, 1);
                            }
                        });
                        await dispatch(removeCartItem(item));
                    } else {
                        const isOnCartRemove = (cart.lineItems.length > 0) ? cart.lineItems.findIndex(i => i.id === item.id) : -1;
                        const toBeAdded = itemsToBe.add.findIndex(i => i.product.id === item.id);
                        if ((isOnCartRemove > -1) && (toBeAdded === -1)) {
                            itemsToBe.remove.push(item);
                        } else {
                            itemsToBe.add.splice(toBeAdded, 1);
                        }
                        await dispatch(removeCartItem([item]));
                    }
                    break;
                default:
                    break;
            }
    
            if (itemsActionTimeout) {
                clearTimeout(itemsActionTimeout)
                itemsActionTimeout = null;
            }
            itemsActionTimeout = setTimeout(() => {dispatch(d => updateCart(d, reRender))}, ActionTimeout);
    
        }
    }
}

const updateCart = (dispatch, reRender) => {
    ["add", "remove"].forEach(async (action) => {
       if (itemsToBe[action].length > 0) {
            if (action === "add") {
                await dispatch(addToCart(itemsToBe[action], false));
                itemsToBe.add = [];
            } else {
                await dispatch(removeCartItem(itemsToBe[action], false, reRender));
                itemsToBe.remove = [];
            }
        }
    });
}

//add item to cart
export const addToCart = (items, updateCart = true) => {
    return async function (dispatch, getState){
        const shop = getState().shop,
            cart = getState().cart,
            checkout = getState().checkout;

        const line_items = cart.lineItems || [];

        // dispatch({
        //     type: "ADDING_TO_CART",
        //     payload: true
        // });

        if ((items !== null) && updateCart) {
            items.forEach(item => {
                Items.line_items.push({
                    product_id: (shop.data.store_type === "Woocommerce") ? item.product.id : item.product.variants[0].id,
                    quantity: item.quantity
                });
            });

            newItems = await addItem((cart.lineItems.length > 0) ? JSON.parse(JSON.stringify(cart.lineItems)) : null, items);

            dispatch({
                type: "ADD_ITEM_TO_CART",
                payload: newItems
            });
        }

        if (!updateCart && !creatingCheckout) {
            if (checkout?.data?.id) {

                orderNotifications(checkout.data.id);
                
                creatingCheckout = false;            
    
                if (shop.data.store_type === "Woocommerce") {
                    WooCommerceAPI(shop.data).put(`orders/${checkout.data.id}`, Items)
                    .then((res) => {
                        if (res.status !== 200) {
                            dispatch({
                                type: "ADD_ITEM_TO_CART",
                                payload: line_items
                            });
                        } else {
                            if (sessionStorage.getItem(shop?.data?.id)) {
                                const storedShop = JSON.parse(sessionStorage.getItem(shop.data.id));
                                const checkout_id = storedShop.id;
                                NotificatinCheckoutTokens(checkout_id, shop);
                            }
                            if (itemsToBe.remove.length === 0) {
                                dispatch(fetchCheckout());
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        dispatch({
                            type: "ADD_ITEM_TO_CART",
                            payload: line_items
                        });
                    }).finally(() => {
                        Items.line_items = [];
                        newItems = [];
                    });
                } else {
        
                    const shopifyApi = shopifyClient(shop.data);
        
                    shopifyApi.checkout.addLineItems(
                        checkout.data.id,
                        Items.line_items.map((item) => {
                            return {
                                variantId: item.product_id,
                                quantity: item.quantity
                            }
                        })
                    )
                    .then(async (res) => {
                        if(res.errors) {
                            dispatch({
                                type: "ADD_ITEM_TO_CART",
                                payload: line_items
                            });
                        } else {
                            if (sessionStorage.getItem(shop?.data?.id)) {
                                const storedShop = JSON.parse(sessionStorage.getItem(shop.data.id));
                                const checkout_id = storedShop.id;
                                NotificatinCheckoutTokens(checkout_id, shop);
                            }
                            if (itemsToBe.remove.length === 0) {
                                dispatch(fetchCheckout());
                            }
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        dispatch({
                            type: "ADD_ITEM_TO_CART",
                            payload: line_items
                        });
                    }).finally(() => {
                        Items.line_items = [];
                        newItems = [];
                    });
        
                }
            } else if (creatingCheckout !== true) {
                creatingCheckout = true;
                await dispatch(createCheckout());
                creatingCheckout = false;
                dispatch(addToCart(items, false));
            }
        }

        // dispatch({
        //     type: "ADDING_TO_CART",
        //     payload: false
        // });

    }
};

//update item
export const updateCartItem = (items) => {
    return async function (dispatch, getState){
        const shop = getState().shop,
            cart = getState().cart,
            checkout = getState().checkout;

        let callback = false;

        let line_items = cart.lineItems || [];


        // dispatch({
        //     type: "UPDATING_CART",
        //     payload: true
        // });

        const updatedItem = await updateItemQuantity(cart.lineItems ? JSON.parse(JSON.stringify(cart.lineItems)) : null, items);

        dispatch({
            type: "ADD_ITEM_TO_CART",
            payload: updatedItem
        });

        orderNotifications(checkout.data.id);

        if (shop.data.store_type === "Woocommerce") {
            await WooCommerceAPI(shop.data).put(`orders/${checkout.data.id}`, {
                line_items: items.map((item) => {
                    return {
                        id: item.order_id,
                        product_id: item.id,
                        quantity: item.quantity
                    }
                })
            })
            .then((res) => {
                if (res.status !== 200) {
                    dispatch({
                        type: "ADD_ITEM_TO_CART",
                        payload: line_items
                    });
                    callback = true;
                } else {
                    if (sessionStorage.getItem(shop?.data?.id)) {
                        const storedShop = JSON.parse(sessionStorage.getItem(shop.data.id));
                        const checkout_id = storedShop.id;
                        NotificatinCheckoutTokens(checkout_id, shop);
                    }
                }
            })
            .catch((error) => {
                console.error(error.response.data);
                dispatch({
                    type: "ADD_ITEM_TO_CART",
                    payload: line_items
                });
                callback = true;
            });
        } else {

            const shopifyApi = shopifyClient(shop.data);
            
            await shopifyApi.checkout.updateLineItems(
                checkout.data.id,
                items.map((item) => {
                    return {
                        id: item.id,
                        quantity: item.quantity
                    }
                })
            )
            .then((res) => {
                if (res.errors) {
                    dispatch({
                        type: "ADD_ITEM_TO_CART",
                        payload: line_items
                    });
                    callback = true;
                } else {
                    if (sessionStorage.getItem(shop?.data?.id)) {
                        const storedShop = JSON.parse(sessionStorage.getItem(shop.data.id));
                        const checkout_id = storedShop.id;
                        NotificatinCheckoutTokens(checkout_id, shop);
                    }
                }
            })
            .catch((e) => {
                console.error(e);
                dispatch({
                    type: "ADD_ITEM_TO_CART",
                    payload: line_items
                });
                callback = true;
            });

        }

        // dispatch({
        //     type: "UPDATING_CART",
        //     payload: false
        // });

        return callback;
    }
};

// Remove item
export const removeCartItem = (items, updateCart = true, reRender = true) => {
    return async function (dispatch, getState){
        const shop = getState().shop,
            cart = getState().cart,
            checkout = getState().checkout;

        let line_items = cart.lineItems || [];

        if (updateCart) {

            const newItems = items.map((item) => {
                return item.id;
            });
            const filteredItems = await cart.lineItems ? cart.lineItems.filter(item => !newItems.includes(item.id)) : [];

            // dispatch({
            //     type: "REMOVING_ITEM_FROM_CART",
            //     payload: true
            // });

            dispatch({
                type: "ADD_ITEM_TO_CART",
                payload: filteredItems
            });

        } else {

            orderNotifications(checkout.data.id);

            if (shop.data.store_type === "Woocommerce") {
                WooCommerceAPI(shop.data).put(`orders/${checkout.data.id}`,
                    {line_items: items.map((item) => {
                        return {
                            id: item.order_id,
                            product_id: null
                        }
                    })
                })
                .then((res) => {
                    if (res.status !== 200) {
                        dispatch({
                            type: "ADD_ITEM_TO_CART",
                            payload: line_items
                        });
                    } else {
                        if (sessionStorage.getItem(shop?.data?.id)) {
                            const storedShop = JSON.parse(sessionStorage.getItem(shop.data.id));
                            const checkout_id = storedShop.id;
                            NotificatinCheckoutTokens(checkout_id, shop);
                        }
                        if ((itemsToBe.add.length === 0) && reRender) {
                            dispatch(fetchCheckout());
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                    dispatch({
                        type: "ADD_ITEM_TO_CART",
                        payload: line_items
                    });
                })
            } else {
                const shopifyApi = shopifyClient(shop.data);
                
                shopifyApi.checkout.removeLineItems(checkout.data.id, items.map((item) => item.id))
                .then((res) => {
                    if(res.errors) {
                        dispatch({
                            type: "ADD_ITEM_TO_CART",
                            payload: line_items
                        });
                    } else {
                        if (sessionStorage.getItem(shop?.data?.id)) {
                            const storedShop = JSON.parse(sessionStorage.getItem(shop.data.id));
                            const checkout_id = storedShop.id;
                            NotificatinCheckoutTokens(checkout_id, shop);
                        }
                        if ((itemsToBe.add.length === 0) && reRender) {
                            dispatch(fetchCheckout());
                        }
                    }
                })
                .catch((e) => {
                    console.error(e);
                    dispatch({
                        type: "ADD_ITEM_TO_CART",
                        payload: line_items
                    });
                });
            }
        }

        // dispatch({
        //     type: "REMOVING_ITEM_FROM_CART",
        //     payload: false
        // });
    }
}

// Clean cart
export const CleanCart = () => {
    return async function (dispatch, getState){

        dispatch({
            type: "REMOVING_ITEM_FROM_CART",
            payload: true
        });

        dispatch({
            type: "ADD_ITEM_TO_CART",
            payload: {
                lineItems: []
            }
        });

        dispatch({
            type: "CREATE_CHECKOUT",
            payload: null
        });

        dispatch({
            type: "REMOVING_ITEM_FROM_CART",
            payload: false
        });
    }
}