import React from "react";
import { withTranslation } from "react-i18next";

import searchIcon from "./assets/img/search_icon.svg";

import "./assets/style/style.css";

class EmptySearch extends React.Component {
    render() {
        const {
            custom_theme,
            shop_color,
            no_promotions,
            t
        } = this.props;
        return (
            <div className={`empty_search_container ${custom_theme ? "custom_theme" : ""}`}>
                <div className="image_wrapper">
                    <div
                        className={no_promotions ? "" : "bg_image"}
                        style={(custom_theme && shop_color) ? { background: shop_color } : {}}
                    ></div>
                    <div className="search_img">
                        <img src={searchIcon} alt="Empty search" />
                    </div>
                </div>
                <div className="text_wrapper">
                    <h1>{no_promotions ? t("search_no_promotions_title") : t("no_search_result_title")}</h1>
                    <div className="subtitle">
                        {no_promotions ? t("search_no_promotions_desc") : t("no_search_result_desc")}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(EmptySearch);