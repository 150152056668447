import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import TextTruncate from "react-text-truncate";
import { Link } from "react-router-dom";

import TabBar from "../tabBar/tabBar";

import { fetchSubCollections } from "./../../actions/categories/index";
import history from "../history";

import noImg from "../catalog/assets/img/no_img.png";

import "react-loading-skeleton/dist/skeleton.css";
import "./assets/style/subcategories.css";

class subCategories extends React.Component {

    constructor() {
        super();
        this.state = {
            subCategories_page: 1
        }
    }

    componentDidMount() {
        this.collectionId = this.props.match.params.category;
        if((this.props.shop?.data?.store_type === "Woocommerce") && this.collectionId) {
            this.props.fetchSubCollections(this.collectionId);
        }
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        this.props.fetchSubCollections(this.collectionId, false);
    }

    fetchMoreSubCollections = () => {
        this.setState((state) => ({
            subCategories_page: state.subCategories_page + 1
        }), () => {
            this.props.fetchSubCollections(this.collectionId, this.state.subCategories_page);
        });
    }

    renderSubCollections = () => {
        const { subCollections_data, LOADING } = this.props.subCollections,
            SkeletonData = [1,2,3,4,5,6].map((i) => {
                return {
                    id: i,
                    skeleton: true,
                    products: [i]
                }
            }),
            subCollections = (subCollections_data?.subCollections.length === 0 )? SkeletonData : subCollections_data?.subCollections || [];
        return (
            subCollections.map((collection) => {
                return (
                    <Link
                        key={collection.id}
                        className="subcategory_wrapper"
                        to={`/category/${collection.id}`}
                        style={LOADING ? {pointerEvents: "none"} : null}
                    >
                        <div className="subcategory_title" style={LOADING ? {minHeight: "auto"} : {}}>
                            {LOADING
                                ? (<Skeleton containerClassName="title_skeleton"/>)
                                : (
                                    <TextTruncate
                                        line={2}
                                        truncateText="..."
                                        text={collection.title}
                                    />
                                )
                            }
                        </div>
                        <div className="subcategory_img_wrapper">
                            {LOADING
                                ? (
                                    <Skeleton
                                        containerClassName="image_skeleton"
                                    />
                                )
                                : (
                                    <img
                                        className="subcategory_img"
                                        src={collection.image ? (collection.image.src) : noImg}
                                        alt="Subcategory"
                                    />
                                )
                            }
                        </div>
                    </Link>
                );
            })
        )
    }

    render() {
        const { shop, subCollections, t } = this.props,
            { shop_color } = shop.data,
            change_color = (shop.data.custom_theme && shop_color) || false;
        return (
            <>
                <div className="subcategories_container">
                    <div className="subcategories_header">
                        <div className="go_back" onClick={() => history.goBack()}>
                            <div
                                className="angle_left_icon"
                                style={change_color ? {background: shop_color} : {}}
                            ></div>
                            <span
                                style={change_color ? {color: shop_color} : {}}
                            >
                                {t("btn_back")}
                            </span>
                        </div>
                    </div>
                    <h2 className="subcategoriesTitle">
                        {t("sub_categories_title")}
                    </h2>
                    <InfiniteScroll
                        dataLength={subCollections?.subCollections_data?.subCollections?.length || 0}
                        next={this.fetchMoreSubCollections}
                        hasMore={(subCollections.subCollections_data.total_pages !== 0)}
                        loader={subCollections.LOADING ? <div className="subcategories_loader"></div> : null}
                        scrollThreshold={1}
                        className="subcategoriesWrapper"
                    >
                        {this.renderSubCollections()}
                    </InfiniteScroll>
                </div>
                <TabBar/>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        subCollections: state.subCollections,
        shop: state.shop
    };
};

export default connect(mapStateToProps, {
    fetchSubCollections,
})(withRouter(withTranslation()(subCategories)));