const initialState = {
    data: []
}

const vendorReducer = (state = initialState, action) => {
    if (action.type === "SET_VENDOR"){
        return {...state, data: action.payload};
    }

    return state;
}

export default vendorReducer;