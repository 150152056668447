import shopifyClient from "../../apis/shopifyApi";
import WoocommerceApi from "../../apis/woocommerceApi";
import { apiData } from "../../apis/apiData";

//Fetch - collections with top offers
export const fetchCollectionsWithTopOffers = () => {
    return async function (dispatch, getState) {

        const shop = getState().shop,
            token = sessionStorage.getItem("token");

        dispatch({
            type: "FETCHING_COLLECTIONS",
            payload: true
        });

        if (shop.data.id && token) {

            await apiData.get(`v2/shop/sale-categories?shop_id=${shop.data.id}`, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(token)}`
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: "FETCH_COLLECTIONS",
                            payload: {
                                collections: response.data.map((collection) => {
                                    return {
                                        id: collection.category_id,
                                        title: collection.title,
                                        image: {
                                            src: collection.image
                                        }
                                    }
                                })
                            }
                        });
                    } else {
                        dispatch({
                            type: "FETCH_COLLECTIONS",
                            payload: {
                                collections: []
                            }
                        });
                    }
                }).catch(() => {
                    dispatch({
                        type: "FETCH_COLLECTIONS",
                        payload: {
                            collections: []
                        }
                    });
                });
        }

        dispatch({
            type: "FETCHING_COLLECTIONS",
            payload: false
        });

    }
};

//Fetch - collections
export const fetchCollections = (page_number = 0) => {
    return async function (dispatch, getState) {

        const shop = getState().shop,
            categories_per_page = 10;
        let collections = getState().collections,
            categories = {
                total_pages: 0,
                collections: []
            };

        dispatch({
            type: "FETCHING_COLLECTIONS",
            payload: true
        });

        if ((page_number !== false) && !shop.ERROR) {

            switch (page_number) {
                case 0:
                    page_number = (shop.data.store_type === "Woocommerce") ? 1 : 0;
                    collections = { collections_data: categories }
                    break;
                case false:
                    dispatch({
                        type: "FETCH_COLLECTIONS",
                        payload: categories
                    });
                    break;
                default:
                    break;
            }

            if (shop.data.store_type === "Woocommerce") {
                await WoocommerceApi(shop.data).get("products/categories", {
                    per_page: categories_per_page,
                    page: page_number,
                    hide_empty: true,
                    parent: 0
                }).then((res) => {
                    if (res.data) {
                        const data = res.data.map((collection) => {
                            return {
                                id: collection.id,
                                title: collection.name,
                                image: collection.image ? {
                                    src: collection.image.src
                                } : null,
                                products: collection.count ? [collection.count] : []

                            }
                        });

                        categories = {
                            total_pages: res.headers['x-wp-totalpages'] > page_number ? res.headers['x-wp-totalpages'] : 0,
                            collections: [...collections.collections_data.collections, ...data]
                        };
                    }

                }).then(() => {
                    dispatch({
                        type: "FETCH_COLLECTIONS",
                        payload: categories
                    });
                }).catch(() => {
                    dispatch({
                        type: "FETCH_COLLECTIONS",
                        payload: categories
                    });
                });
            } else {
                const shopifyApi = shopifyClient(shop.data);

                const args = { first: categories_per_page, reverse: false, sortKey: shopifyApi.graphQLClient.enum('UPDATED_AT') };

                if (page_number !== 0) {
                    args.after = collections.collections_data.total_pages;
                }

                const collectionQuery = shopifyApi.graphQLClient.query((collRoot) => {
                    collRoot.addConnection('collections', { args }, (collection) => {
                        collection.add('id');
                        collection.add('title');
                        collection.add('image', (image) => {
                            image.add('src');
                        });
                        collection.addConnection("products", { args: { first: 5, reverse: true } }, (product) => {
                            product.add("id");
                            product.add("title");
                            product.add("description");
                            product.addConnection("images", { args: { first: 5 } }, (image) => {
                                image.add("src");
                            });
                            product.addConnection("variants", { args: { first: 5 } }, (variant) => {
                                variant.add("compareAtPrice");
                                variant.add("price");
                                variant.add("priceV2", (price) => {
                                    price.add("currencyCode");
                                });
                            });
                        });
                    });
                });

                await shopifyApi.graphQLClient.send(collectionQuery).then((collModel, collData) => {
                    if (collModel.model && !collModel.error) {
                        const { edges, pageInfo } = collModel.data.collections;
                        categories = {
                            total_pages: pageInfo.hasNextPage ? edges[edges.length - 1].cursor : 0,
                            collections: [...collections.collections_data.collections, ...collModel.model.collections]
                        };
                    }
                }).then(() => {
                    dispatch({
                        type: "FETCH_COLLECTIONS",
                        payload: categories
                    });
                }).catch(() => {
                    dispatch({
                        type: "FETCH_COLLECTIONS",
                        payload: categories
                    });
                });
            }
        }

        dispatch({
            type: "FETCHING_COLLECTIONS",
            payload: false
        });

    }
};

//Fetch - subCollections
export const fetchSubCollections = (collection_id, page_number = 0) => {
    return async function (dispatch, getState) {

        const shop = getState().shop,
            subCategories_per_page = 10;
        let subCollections = getState().subCollections,
            subCategories = {
                total_pages: 0,
                subCollections: []
            };

        dispatch({
            type: "FETCHING_SUBCOLLECTIONS",
            payload: true
        });

        switch (page_number) {
            case 0:
                page_number = (shop.data.store_type === "Woocommerce") ? 1 : 0;
                break;
            case false:
                dispatch({
                    type: "FETCH_SUBCOLLECTIONS",
                    payload: subCategories
                });
                break;
            default:
                break;
        }

        if ((page_number !== false) && !shop.ERROR) {

            if (shop.data.store_type === "Woocommerce") {
                await WoocommerceApi(shop.data).get("products/categories", {
                    per_page: subCategories_per_page,
                    page: page_number,
                    parent: collection_id,
                    hide_empty: true
                }).then((res) => {
                    if (res.data) {
                        const data = res.data.map((subCollection) => {
                            return {
                                id: subCollection.id,
                                title: subCollection.name,
                                image: subCollection.image ? {
                                    src: subCollection.image.src
                                } : null,
                                products: subCollection.count ? [subCollection.count] : []
                            }
                        });

                        subCategories = {
                            total_pages: res.headers['x-wp-totalpages'] > page_number ? res.headers['x-wp-totalpages'] : 0,
                            subCollections: [...subCollections.subCollections_data.subCollections, ...data]
                        };
                    }

                }).then(() => {
                    dispatch({
                        type: "FETCH_SUBCOLLECTIONS",
                        payload: subCategories
                    });
                }).catch(() => {
                    dispatch({
                        type: "FETCH_SUBCOLLECTIONS",
                        payload: subCategories
                    });
                });
            }
        }

        dispatch({
            type: "FETCHING_SUBCOLLECTIONS",
            payload: false
        });
    }
}

//Fetch - subCollection from Category with Top Offer
export const fetchSubCollectionsWithTopOffers = (category_id) => {
    return async function (dispatch, getState) {

        const shop = getState().shop,
            token = sessionStorage.getItem("token");

        dispatch({
            type: "FETCHING_SUBCOLLECTIONS",
            payload: true
        });

        if (shop.data.id && token && category_id) {

            await apiData.get(`v2/shop/sale-categories?shop_id=${shop.data.id}&category_id=${category_id}`, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(token)}`
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: "FETCH_SUBCOLLECTIONS",
                            payload: {
                                subCollections: response.data.map((subCollection) => {
                                    return {
                                        id: subCollection.category_id,
                                        description: subCollection.description,
                                        title: subCollection.title,
                                        image: {
                                            src: subCollection.image
                                        }
                                    };
                                })
                            }
                        });
                    } else {
                        dispatch({
                            type: "FETCH_SUBCOLLECTIONS",
                            payload: {
                                subCollections: []
                            }
                        });
                    }
                }).catch(() => {
                    dispatch({
                        type: "FETCH_SUBCOLLECTIONS",
                        payload: {
                            subCollections: []
                        }
                    });
                });
        }

        dispatch({
            type: "FETCHING_SUBCOLLECTIONS",
            payload: false
        });

    }
}