const defaultValues = {
    data: {
        total_pages: 0,
        products: []
    },
    LOADING: true,
};

const customProductsReducer = (state = defaultValues, action) => {
    if (action.type === "FETCH_CUSTOM_PRODUCTS"){
        return {...state, data: action.payload};
    }

    if (action.type === "FETCHING_CUSTOM_PRODUCTS"){
        return { ...state, LOADING: action.payload };
    }

    return state;
}

export default customProductsReducer;