import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import TextTruncate from "react-text-truncate";
import Skeleton from "react-loading-skeleton";
import {
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    Type as ListType,
} from "react-swipeable-list";
import { FaTrashAlt } from "react-icons/fa";
import SlidingPane from "react-sliding-pane";
import { confirmAlert } from "react-confirm-alert";
import { withTranslation } from "react-i18next";
import { withAlert } from "react-alert";
import moment from "moment";
import Tour from "reactour";

import TabBar from "../tabBar/tabBar";
import ShopOptions from "./shoppingMethods/shopOptions";
import EmptyShoppingCart from "../infoScreens/emptyShoppingCart/index";
import ShareOrderButton from "../shareButton/index";
import ExpiredShop from "../infoScreens/expiredShop";

import { fetchCheckout } from "../../actions/checkout/index";
import { updateCartItem, addRemoveItem } from "../../actions/cart/index";
import { fetchShop } from "../../actions/shop";
import { ActionTimeout } from "../../globalFunctions";
import history from "../history";
import analyticsEvents from "../../analyticsEvents";

import no_image from "../images/no-image.png";

import "react-confirm-alert/src/react-confirm-alert.css";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-swipeable-list/dist/styles.css";
import "./assets/css/cart.css";

class Cart extends React.Component {
    RemovingItem = false;
    itemQuantityTimeout = null;
    steps = [];
    IsMounted = false;
    constructor() {
        super();
        this.state = {
            showShopOptions: false,
            ItemsQuantity: [],
            completeItems: false,
            selectedItems: [],
            selectedAll: false,
            isTourOpen: false,
            regular_price: 0,
            current_price: 0,
            saved: 0,
            currency: "",
            showNavigateOption: false,
            showPaymentOption: false,
            showApplePayOption: false
        }
    }

    async componentDidMount() {

        this.IsMounted = true;

        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has("cart-id")) {
            await this.props.fetchCheckout(searchParams.get("cart-id"));
        } else {
            await this.props.fetchCheckout();
        }
        this.getItemsQuantity();

        if ((this.props.cart.lineItems.length > 0) && !localStorage.getItem("showTour") && (this.props.shop?.data?.category?.name !== 'Menu')) {
            this.steps = [{
                selector: '.share_icon',
                content: <div>{this.props.t("share_cart_tour_title")}<br />{this.props.t("share_cart_tour_text")}</div>,
                position: "right"
            }];
            this.setState({
                isTourOpen: true
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.cart.lineItems) !== JSON.stringify(this.props.cart.lineItems)) {
            this.getItemsQuantity();
        }
    }

    componentWillUnmount() {

        this.IsMounted = false;

        if (this.state.showShopOptions) {
            this.setState({
                showShopOptions: false
            });
        }

        if (this.props.alert.alerts.length > 0) {
            const customAlert = this.props.alert.alerts.find((a) => a.options.type === "custom");
            if (customAlert) {
                customAlert.close();
            }
        }
    }

    getItemsQuantity = () => {
        const { cart } = this.props;

        if (cart.lineItems && (cart.lineItems.length > 0)) {
            const ItemsQuantity = cart.lineItems.map((item) => {
                return {
                    order_id: item.order_id,
                    id: item.id,
                    quantity: item.quantity,
                    unit: item.meta_data ? this.getProductUnit(item).unit : ""
                }
            });
            this.setState({
                ItemsQuantity
            }, () => this.getTotalSum());
        }
    };

    ConfirmDelete = (item) => {
        const { shop, t } = this.props;
        confirmAlert({
            closeOnClickOutside: false,
            closeOnEscape: false,
            customUI: ({ onClose }) => {
                return (
                    <div className="alert_custome_ui">
                        <h1>{t("remove_dialog_title")}</h1>
                        <p>{t("remove_dialog_desc")}</p>
                        <div className="button-group">
                            <button
                                onClick={onClose}
                                style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? { color: shop.data.shop_color } : {}}
                            >
                                {t("btn_no")}
                            </button>
                            <button
                                style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? { color: shop.data.shop_color } : {}}
                                onClick={() => {
                                    onClose();
                                    this.props.addRemoveItem("remove", item, false);
                                    analyticsEvents.removeProduct(shop.data.id, item.quantity);
                                    this.getTotalSum();
                                }}
                            >
                                {t("btn_yes")}
                            </button>
                        </div>
                    </div>
                );
            }
        });
    };

    deleteItemAction = (item) => (
        <TrailingActions>
            <SwipeAction
                destructive={false}
                onClick={() => this.ConfirmDelete(item)}
            >
                <div className="removeIcon">
                    <FaTrashAlt style={{ fontSize: "19px", color: "white" }} />
                </div>
            </SwipeAction>
        </TrailingActions>
    );

    renderCartSkeleton = () => {
        const SkeletonData = [1, 2, 3].map((i) => {
            return { id: i };
        });

        return SkeletonData.map((item) => (
            <div className="item_container skeleton" key={item.id}>
                <div className="cartBodyLeft">
                    <Skeleton containerClassName="item_image_skeleton" />
                </div>
                <div className="item_info_wrapper">
                    <div className="cartBodyMiddle">
                        <div className="item_title">
                            <Skeleton containerClassName="item_title_skeleton" />
                        </div>
                        <div className="item_price_container">
                            <div className="item_price_wrapper">
                                <div className="item_compare_price">
                                    <Skeleton containerClassName="item_compareAtPrice_skeleton" />

                                </div>
                                <div className="item_price">
                                    <Skeleton containerClassName="item_price_skeleton" />
                                </div>
                            </div>
                            <Skeleton containerClassName="cart_right_items_skeleton" />
                        </div>
                    </div>
                </div>
            </div>
        ));
    };

    onSelectItem = (event, item) => {
        let { selectedItems, selectedAll, completeItems } = this.state;
        analyticsEvents.shoppingListEdit(this.props.shop.data.id);
        if (event.target.checked) {
            selectedItems.push({
                id: item.id,
                order_id: item.order_id,
                quantity: item.quantity,
                title: item.title,
                variant: {
                    compareAtPrice: item.variant.compareAtPrice,
                    image: {
                        src: item.variant.image.src
                    },
                    priceV2: {
                        amount: item.variant.priceV2.amount,
                        currencyCode: item.variant.priceV2.currencyCode
                    }
                }
            });
            if (selectedItems.length === this.props.cart.lineItems.length) {
                selectedAll = true;
            }
        } else {
            selectedItems = selectedItems.filter(i => i.id !== item.id);
            selectedAll = false;
        }

        if ((selectedItems.length > 0) && !completeItems) {
            completeItems = true;
        } else if ((selectedItems.length === 0) && completeItems) {
            completeItems = false;
        }

        this.setState({
            selectedItems,
            selectedAll,
            completeItems
        });
    }

    onSelectAllItem = () => {
        let selectedItems = [],
            selectedAll = false;

        if (!this.state.selectedAll) {
            analyticsEvents.shoppingListEdit(this.props.shop.data.id);
            this.props.cart.lineItems.forEach((item) => {
                selectedItems.push({
                    id: item.id,
                    order_id: item.order_id,
                    quantity: item.quantity,
                    title: item.title,
                    variant: {
                        compareAtPrice: item.variant.compareAtPrice,
                        image: {
                            src: item.variant.image.src
                        },
                        priceV2: {
                            amount: item.variant.priceV2.amount,
                            currencyCode: item.variant.priceV2.currencyCode
                        }
                    }
                });
            });
            selectedAll = true;
        }

        this.setState({
            selectedItems,
            selectedAll
        });
    }

    onCompletedItems = () => {
        const { shop, t } = this.props;
        confirmAlert({
            closeOnClickOutside: false,
            closeOnEscape: false,
            customUI: ({ onClose }) => {
                return (
                    <div className="alert_custome_ui">
                        <h1>{t("complete_items_dialog_title")}</h1>
                        <p>{t("complete_items_dialog_desc")}</p>
                        <div className="button-group">
                            <button
                                onClick={onClose}
                                style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? { color: shop.data.shop_color } : {}}
                            >
                                {t("btn_cancel")}
                            </button>
                            <button
                                style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? { color: shop.data.shop_color } : {}}
                                onClick={() => {
                                    onClose();
                                    this.arhiveCompletedItems();
                                }}
                            >
                                {t("btn_complete")}
                            </button>
                        </div>
                    </div>
                );
            }
        });
    }

    arhiveCompletedItems = () => {
        this.props.addRemoveItem("remove", this.state.selectedItems);
        const { shop, checkout } = this.props;
        let cart = localStorage.getItem(shop.data.id),
            { selectedItems } = this.state;

        selectedItems = selectedItems.map((item) => {
            analyticsEvents.productCompleted(shop.data.id, item.id, item.quantity);
            item.date_completed = moment();
            return item;
        });

        if (cart) {
            cart = JSON.parse(cart);
            const cartIndex = cart.findIndex(a => a.cart_id === checkout.data.id);
            if (cartIndex > -1) {
                cart[cartIndex].completeItems = cart[cartIndex].completeItems ? cart[cartIndex].completeItems.concat(selectedItems) : selectedItems;
            } else {
                cart.push({
                    cart_id: checkout.data.id,
                    completeItems: selectedItems
                });
            }
        } else {
            cart = [{
                cart_id: checkout.data.id,
                completeItems: selectedItems
            }]
        }

        try {
            localStorage.setItem(shop.data.id, JSON.stringify(cart));
        } catch (e) {
            if ((e.code === "22") || (e.code === "1024")) {
                Object.keys(localStorage).forEach((o) => {
                    if (parseInt(o)) {
                        localStorage.removeItem(o);
                    }
                });
            }
        }

        this.props.alert.show(
            this.props.t("confirm_completed_items"), {
            type: "custom",
            position: "top center",
            timeout: 5000
        }
        );

        setTimeout(() => {
            if (this.IsMounted) {
                this.getItemsQuantity();
                this.forceUpdate();
            }
        }, ActionTimeout);

        this.setState({
            selectedItems: [],
            selectedAll: false,
            completeItems: false
        });
    }

    renderCartItems = () => {
        const { cart, shop /*, vendor*/ } = this.props,
            { custom_theme, shop_color, category } = shop.data,
            shop_category = category,
            LOADING = ((cart.UPDATING_CART || cart.REMOVING_ITEM_FROM_CART) && !this.RemovingItem) ? true : false,
            { ItemsQuantity, selectedAll, selectedItems } = this.state;
        let cartItems = cart.lineItems || [],
            categorizedData = {};

        if ((LOADING === false) && (cartItems.length > 0)) {
            categorizedData = cartItems.reduce((acc, curr) => {
                const { variant } = curr;
                let category = null;

                if (variant?.product?.collections && variant?.product?.collections[0]?.id && variant?.product?.collections[0]?.title) {
                    category = { id: (variant?.product?.collections[0]?.id || null), title: (variant?.product?.collections[0]?.title || "") };
                }

                if (category && category.id && category.title) {
                    if (!acc[category.id]) {
                        acc[category.id] = {
                            title: category.title,
                            items: [],
                        };
                    }
                    acc[category.id].items.push(curr);
                } else {
                    const item = {
                        ...curr,
                        variant: {
                            ...curr.variant,
                            product: {
                                collections: [{
                                    id: "Uncategorized",
                                    title: "Uncategorized"
                                }]
                            }
                        }
                    }
                    if (!acc["Uncategorized"]) {
                        acc["Uncategorized"] = {
                            title: "Uncategorized",
                            items: [],
                        }
                    }
                    acc["Uncategorized"].items.push(item);
                }

                return acc;
            }, {});
        }

        if (LOADING) {
            return this.renderCartSkeleton();
        } else if (Object.keys(categorizedData).length > 0) {
            return Object.keys(categorizedData).map((category, index) => {
                return (
                    <React.Fragment key={`cat_${index}`}>
                        <div className="cat_title">{categorizedData[category].title}</div>
                        {(categorizedData[category].items && (categorizedData[category].items.length > 0)) && (
                            <SwipeableList
                                type={ListType.ANDROID}
                            >
                                {categorizedData[category].items.map((item, i) => {
                                    const ItemQ = ItemsQuantity.find(i => i.id === item.id);
                                    return (
                                        <SwipeableListItem
                                            key={item.id}
                                            trailingActions={this.deleteItemAction(item)}
                                            blockSwipe={LOADING || this.RemovingItem}
                                        >
                                            <div
                                                className={`item_container ${(this.RemovingItem && (this.RemovingItem === item.id)) ? "removing" : ""}`}
                                                style={(!shop_category || (shop_category?.id !== 3)) ? { gridTemplateColumns: "40px 75px calc(100% - 110px)" } : {}}
                                            >
                                                {/* <div>{vendor.data}</div> */}
                                                {(!shop_category || (shop_category?.id !== 3)) && (
                                                    <div className="checkbox_wrapper">
                                                        <input
                                                            type="checkbox"
                                                            name={item.id}
                                                            onChange={(e) => this.onSelectItem(e, item)}
                                                            style={(custom_theme && shop_color) ? { "--shop-color-var": shop_color } : { "--shop-color-var": "#05BD97" }}
                                                            checked={selectedAll || (selectedItems.findIndex(i => i.id === item.id) > -1)}
                                                        />
                                                    </div>
                                                )}
                                                <div className="cartBodyLeft">
                                                    <img
                                                        src={item?.variant?.image?.src || no_image}
                                                        alt="cart_product_img"
                                                        width="100%"
                                                    />
                                                </div>
                                                <div className="item_info_wrapper">
                                                    <div className="cartBodyMiddle">
                                                        <div className="item_title">
                                                            <TextTruncate
                                                                line={2}
                                                                truncateText="..."
                                                                text={item.title}
                                                            />
                                                        </div>
                                                        <div className="price_container">
                                                            <div className="price_wrapper">
                                                                <div className="item_compare_price">
                                                                    {(item.variant.compareAtPrice && (item.variant.compareAtPrice !== item.variant.priceV2.amount)) &&
                                                                        (`${item.variant.compareAtPrice} ${item.variant.priceV2.currencyCode}`)
                                                                    }
                                                                </div>
                                                                <div className="item_price">
                                                                    {`${item.variant.priceV2.amount} ${item.variant.priceV2.currencyCode}`}
                                                                </div>
                                                            </div>
                                                            <div className="cartBodyRight">
                                                                <>
                                                                    <button
                                                                        className="btnDecrease"
                                                                        onClick={() => this.decreaseQuantity(item)}
                                                                        disabled={(ItemQ && (ItemQ.quantity === 1))}
                                                                    ></button>
                                                                    {(ItemQ && ItemQ.quantity) && (
                                                                        <span className="item_quantity">
                                                                            {ItemQ.quantity} {ItemQ.unit && ItemQ.unit}
                                                                        </span>
                                                                    )}
                                                                    <button
                                                                        className="btnIncrease"
                                                                        onClick={() => this.increaseQuantity(item)}
                                                                        style={(custom_theme && shop_color) ? { backgroundColor: shop_color } : {}}
                                                                    ></button>
                                                                </>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwipeableListItem>
                                    );
                                })}
                            </SwipeableList>
                        )}
                    </React.Fragment>
                )
            })
        } else {
            return (
                <div className="empty_cart">
                    <EmptyShoppingCart
                        custom_theme={custom_theme}
                        shop_color={shop_color}
                    />
                </div>
            );
        }
    }

    getProductUnit = (product) => {
        if (product.meta_data && product.meta_data.filter(m => m.key === "_woo_uom_input").length > 0) {
            const meta_data = product.meta_data.filter(m => m.key === "_woo_uom_input");
            if (meta_data[0]?.value.length > 0) {
                return {
                    unit: meta_data[0]?.value || null
                };
            } else {
                return {
                    unit: null
                }
            }
        } else {
            return {
                unit: null
            };
        }
    }

    increaseQuantity = (product) => {
        if (product) {
            const { ItemsQuantity } = this.state;
            const index = ItemsQuantity.findIndex(i => i.id === product.id);
            if (index > -1) {
                ItemsQuantity[index].quantity = ItemsQuantity[index].quantity + 1;
                this.setState({
                    ItemsQuantity
                }, () => {
                    if (this.itemQuantityTimeout) {
                        clearTimeout(this.itemQuantityTimeout)
                        this.itemQuantityTimeout = null;
                    }
                    this.itemQuantityTimeout = setTimeout(this.updateItemsQuantity, ActionTimeout);
                });
            }
        }
    }

    decreaseQuantity(product) {
        if (product) {
            const { ItemsQuantity } = this.state;
            const index = ItemsQuantity.findIndex(i => i.id === product.id);
            if (index > -1) {
                ItemsQuantity[index].quantity = ItemsQuantity[index].quantity - 1;
                this.setState({
                    ItemsQuantity
                }, () => {
                    if (this.itemQuantityTimeout) {
                        clearTimeout(this.itemQuantityTimeout)
                        this.itemQuantityTimeout = null;
                    }
                    this.itemQuantityTimeout = setTimeout(this.updateItemsQuantity, ActionTimeout);
                });
            }
        }
    }

    updateItemsQuantity = () => {
        analyticsEvents.shoppingListEdit(this.props.shop.data.id);
        this.props.updateCartItem(this.state.ItemsQuantity)
            .then((callback) => {
                if (callback) {
                    this.getItemsQuantity();
                }
            });
    }

    checkPaymentOptions = () => {
        const { locations, is_payment_supported, is_apple_pay_supported, merchant_id } = this.props.shop.data;
        let { showNavigateOption, showPaymentOption, showApplePayOption } = this.state;

        switch (true) {
            case ((!locations || (locations.length === 0)) && !is_payment_supported && !is_apple_pay_supported):
                showNavigateOption = false;
                showPaymentOption = false;
                showApplePayOption = false;
                break;
            case ((!locations || (locations.length === 0)) && !is_payment_supported && (is_apple_pay_supported && (merchant_id && (merchant_id.length > 0)))):
                showNavigateOption = false;
                showPaymentOption = false;
                showApplePayOption = true;
                break;
            case ((!locations || (locations.length === 0)) && is_payment_supported && !is_apple_pay_supported):
                showNavigateOption = false;
                showPaymentOption = true;
                showApplePayOption = false;
                break;
            case ((!locations || (locations.length === 0)) && is_payment_supported && (is_apple_pay_supported && (merchant_id && (merchant_id.length > 0)))):
                showNavigateOption = false;
                showPaymentOption = true;
                showApplePayOption = true;
                break;
            case ((locations && (locations.length > 0)) && !is_payment_supported && !is_apple_pay_supported):
                showNavigateOption = true;
                showPaymentOption = false;
                showApplePayOption = false;
                break;
            case ((locations && (locations.length > 0)) && !is_payment_supported && (is_apple_pay_supported && (merchant_id && (merchant_id.length > 0)))):
                showNavigateOption = true;
                showPaymentOption = false;
                showApplePayOption = true;
                break;
            case ((locations && (locations.length > 0)) && is_payment_supported && !is_apple_pay_supported):
                showNavigateOption = true;
                showPaymentOption = true;
                showApplePayOption = false;
                break;
            case ((locations && (locations.length > 0)) && is_payment_supported && (is_apple_pay_supported && (merchant_id && (merchant_id.length > 0)))):
                showNavigateOption = true;
                showPaymentOption = true;
                showApplePayOption = true;
                break;
            default:
                break;
        }

        this.setState({
            showNavigateOption,
            showPaymentOption,
            showApplePayOption
        });
    }

    showShopOptions = async () => {
        if (this.props.cart.lineItems && (this.props.cart.lineItems.length > 0)) {
            await this.checkPaymentOptions();
            this.setState({
                showShopOptions: !this.state.showShopOptions
            }, () => {
                const body = document.querySelector("body");
                if (this.state.showShopOptions) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.removeProperty("overflow");
                }
            });
        }
    }

    getTotalSum = () => {
        const { cart } = this.props,
            ItemsPrice = {
                regular_price: 0,
                current_price: 0,
                saved: 0,
                currency: ""
            }
        if (cart.lineItems && (cart.lineItems.length > 0)) {
            cart.lineItems.forEach((item) => {
                ItemsPrice.current_price += item.variant.priceV2.amount ? (parseFloat(item.variant.priceV2.amount) * item.quantity) : 0;
                ItemsPrice.regular_price += item.variant.compareAtPrice ? (parseFloat(item.variant.compareAtPrice) * item.quantity) : (parseFloat(item.variant.priceV2.amount) * item.quantity);
                if (item.variant.compareAtPrice) {
                    ItemsPrice.saved += (item.variant.compareAtPrice * item.quantity) - (item.variant.priceV2.amount * item.quantity);
                }
                ItemsPrice.currency = item.variant.priceV2.currencyCode;
            });
        }

        this.setState({
            regular_price: ItemsPrice.regular_price.toFixed(2).replace(/\.00$/, ''),
            current_price: ItemsPrice.current_price.toFixed(2).replace(/\.00$/, ''),
            saved: ItemsPrice.saved.toFixed(2).replace(/\.00$/, ''),
            currency: ItemsPrice.currency
        });
    }

    render() {
        const {
            completeItems,
            selectedItems,
            selectedAll,
            showShopOptions,
            regular_price,
            current_price,
            saved,
            currency,
            showNavigateOption,
            showPaymentOption,
            showApplePayOption
        } = this.state,
            { cart, shop, t } = this.props;
        return (
            <div className="shoppingCartContainer">
                <div className="shoppingCartHeader">
                    {shop?.data?.category?.name === "Menu" ? null : (
                        <button
                            className="archive_button"
                            onClick={() => history.push("/order-archive")}
                        >
                            <div
                                className="archive_icon"
                                style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? { backgroundColor: shop.data.shop_color } : {}}
                            ></div>
                        </button>
                    )}
                    <h2 className="cartTitle">{shop?.data?.category?.name === "Menu" ? t('items') : t("title_shopping_cart")}</h2>
                    <div
                        className="edit_products"
                        style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? { color: shop.data.shop_color } : {}}
                    >
                        {completeItems && (
                            <div
                                className="cancel"
                                style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? { color: shop.data.shop_color } : {}}
                                onClick={() => this.setState({
                                    completeItems: false,
                                    selectedAll: false,
                                    selectedItems: []
                                })}
                            >
                                {t("btn_cancel")}
                            </div>
                        )}
                        {shop?.data?.category?.name === "Menu" ? null : (
                            <div className="share_cart_btn">
                                <ShareOrderButton disabled={cart.lineItems.length === 0} />
                            </div>
                        )}
                    </div>
                </div>

                <div className="body_container">
                    {(shop?.data?.catalogue_limit_expired === true) ? (
                        <ExpiredShop />
                    ) : (
                        <div className="items_container">
                            {this.renderCartItems()}
                        </div>
                    )}
                </div>
                {completeItems ? (
                    <div className="complete_items_container">
                        <button
                            className="select_all"
                            style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? { color: shop.data.shop_color } : {}}
                            onClick={this.onSelectAllItem}
                            disabled={selectedAll}
                        >
                            {t("btn_select_all")}
                        </button>
                        <button
                            className="complete"
                            style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? { color: shop.data.shop_color } : {}}
                            disabled={selectedItems.length === 0}
                            onClick={this.onCompletedItems}
                        >
                            {t("btn_complete")}
                        </button>
                    </div>
                ) : (
                    <TabBar
                        activeTab="cart"
                        showShopOptions={this.showShopOptions}
                        current_price={current_price}
                        saved={saved}
                        currency={currency}
                    />
                )}
                <SlidingPane
                    isOpen={showShopOptions}
                    from="bottom"
                    onRequestClose={this.showShopOptions}
                    className="shop_options_modal_wrapper"
                    shouldCloseOnEsc={false}
                >
                    <ShopOptions
                        regular_price={regular_price}
                        current_price={current_price}
                        saved={saved}
                        currency={currency}
                        showNavigateOption={showNavigateOption}
                        showPaymentOption={showPaymentOption}
                        showApplePayOption={showApplePayOption}
                    />
                </SlidingPane>
                <Tour
                    steps={this.steps}
                    isOpen={this.state.isTourOpen}
                    onRequestClose={() => {
                        this.setState({ isTourOpen: false });
                        localStorage.setItem("showTour", "false");
                    }}
                    showNumber={false}
                    showNavigationNumber={false}
                    showNavigation={false}
                    showButtons={false}
                    className="helper"
                    rounded={5}
                    onAfterOpen={(target) => (document.body.style.overflowY = 'hidden')}
                    onBeforeClose={(target) => (document.body.style.overflowY = 'auto')}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        checkout: state.checkout,
        cart: state.cart,
        vendor: state.vendor,
        shop: state.shop
    };
};

export default connect(mapStateToProps, {
    fetchCheckout,
    updateCartItem,
    addRemoveItem,
    fetchShop
})(withRouter(withAlert()(withTranslation()(Cart))));