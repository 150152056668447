const defaultValues = {
    variations_data: {
        variations: [],
        on_sale: [],
        compare_variations: null
    },
    LOADING: true
};

const productVariationsReducer = (state = defaultValues, action) => {
    if (action.type === "FETCH_PRODUCTS_VARIATIONS"){
        return { ...state, variations_data: action.payload };
    }

    if (action.type === "FETCHING_PRODUCTS_VARIATIONS"){
        return { ...state, LOADING: action.payload };
    }

    return state;
}

export default productVariationsReducer;