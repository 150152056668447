import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

const API = (store_info) => new WooCommerceRestApi({
  url: store_info.url,
  consumerKey: store_info.consumer_key,
  consumerSecret: store_info.secret,
  version: "wc/v3",
  axiosConfig: {
	  headers: {
      "Content-Type": "application/json"
    }
	}
});

export default API;