import React from "react";
import { withTranslation } from "react-i18next";

import ProductNotFoundImg from "./assets/img/not-found-img.svg";

import "./assets/style/style.css";

class ProductNotFound extends React.Component {
    render() {
        const {
            custom_theme,
            shop_color,
            t
        } = this.props;
        return (
            <div className={`product_not_found_container ${custom_theme ? "custom_theme" : ""}`}>
                <div className="image_wrapper">
                    <div
                        className="bg_image"
                        style={(custom_theme && shop_color) ? {background: shop_color} : {}}
                    ></div>
                    <div className="search_img">
                        <img src={ProductNotFoundImg} alt="Product not found" />
                    </div>    
                </div>
                <div className="message_container">
                    <h1>{t("product_not_found_title")}</h1>
                    <div className="message">
                        {t("product_not_found_desc")}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ProductNotFound);