import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import TextTruncate from "react-text-truncate";
import Skeleton from "react-loading-skeleton";
import { withTranslation } from "react-i18next";

import TabBar from "../tabBar/tabBar";
import ProductNotFound from "../infoScreens/productNotFound/index";

import { fetchProduct, fetchRecommendedProducts } from "../../actions/products";
import { addRemoveItem } from "../../actions/cart";
import history from "../history";
import { DynamicPriceFontSize, onSaleTo, calcPercentage, removeUnnecessaryDecimals } from "../../globalFunctions";
import { fetchCheckout } from "../../actions/checkout";
import analyticsEvents from "../../analyticsEvents";

import noImg from "../catalog/assets/img/no_img.png";

import "react-loading-skeleton/dist/skeleton.css";
import "./assets/css/product.css";


class Product extends React.Component {

    categoryId = null;
    productId = null;

    constructor() {
        super();
        this.state = {
            variations: null,
            disableAddToCartBtn: false,
            disabled: {},
            loading_variants: true,
            productPrice: {
                compareAtPrice: null,
                price: null
            }
        }
    }

    componentDidMount() {
        this.productId = this.props.match.params.id;
        this.categoryId = this.props.match.params.category || null;
        this.props.fetchProduct(this.productId);
        if (this.props.cart.lineItems.length === 0) {
            this.props.fetchCheckout();
        }
        const shop = this.props.shop;
        if (((shop.data.store_type === "Shopify") && this.productId) || ((shop.data.store_type === "Woocommerce") && this.categoryId)) {
            this.props.fetchRecommendedProducts(this.productId, this.categoryId);
        }
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.match.url !== prevProps.match.url) && !this.state.loading_variants) {
            this.productId = this.props.match.params.id;
            this.categoryId = this.props.match.params.category || "null";
            this.setState({
                loading_variants: true
            }, () => {
                this.props.fetchProduct(this.productId);
                const shop = this.props.shop;
                if (((shop.data.store_type === "Shopify") && this.productId) || ((shop.data.store_type === "Woocommerce") && this.categoryId)) {
                    this.props.fetchRecommendedProducts(this.productId, this.categoryId);
                }
            });
            window.scrollTo(0, 0);
        }
    }

    getProduct = (productId) => {
        history.push(`/product/${productId}/${this.categoryId}`);
    }

    renderRecomendedProducts = () => {
        if (this.props.recommendedProducts.ERROR === false) {
            const SkeletonData = [1,2,3,4,5].map((i) => {
                    return {
                        id: i
                    }
                }),
                { LOADING, products_data } = this.props.recommendedProducts,
                products = LOADING ? SkeletonData : products_data || [],
                { cart, shop, t } = this.props,
                { custom_theme, shop_color, discount_price_color, reqular_price_color } = shop.data;
            if (products.length > 0) {
                return (
                    <div className="recomendedProductsContainer">
                        <h3>{t("recommended")}</h3>
                        <div className="recomendedProducts">
                            {products.map((prod) => {
                                if (prod.variants) {
                                    prod.variants[0].product = {
                                        collections: [{
                                            id: this.categoryId,
                                            title: products_data.category_title
                                        }]
                                    }
                                }
                                const isOnCart = (cart?.lineItems && (cart?.lineItems?.length > 0) && prod?.variants) ? cart.lineItems.find(p => p.variant.id === prod.variants[0].id) : false;
                                return (
                                    <div className="productBox" key={prod.id}>
                                        <div className="body_container">
                                            <div className="productLink" onClick={() => this.getProduct(prod.id)}>
                                                <div className="productCategoryTitle">
                                                    {LOADING
                                                        ? (<Skeleton containerClassName="product_title_skeleton"/>)
                                                        : (
                                                            <TextTruncate
                                                                line={2}
                                                                truncateText="..."
                                                                text={prod.title}
                                                            />
                                                        )
                                                    }
                                                </div>
                                                <div className="productImg">
                                                    {LOADING
                                                        ? (<Skeleton containerClassName="product_image_skeleton"/>)
                                                        : (
                                                            <>
                                                                <img
                                                                    src={prod?.images[0]?.src || noImg}
                                                                    alt="Product"
                                                                />
                                                                {(prod.date_on_sale_from_gmt || prod.date_on_sale_to_gmt) && (
                                                                    onSaleTo(
                                                                        {
                                                                            from: prod.date_on_sale_from_gmt,
                                                                            to: prod.date_on_sale_to_gmt
                                                                        },
                                                                        shop.data
                                                                    )
                                                                )}
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="productPriceContainer">
                                            <div className="vendor-price-wrapper">
                                                {LOADING
                                                    ? (<Skeleton containerClassName="product_price_skeleton"/>)
                                                    : (
                                                        <>
                                                            {(prod.variants[0].compareAtPrice != null) ? (
                                                                <div
                                                                    className="discountPercentage"
                                                                    style={(custom_theme && reqular_price_color) ? {backgroundColor: reqular_price_color} : {}}
                                                                >
                                                                    {calcPercentage(prod.variants[0].compareAtPrice, prod.variants[0].price)}
                                                                </div>
                                                            ) : (
                                                                <div style={{height: "30px"}}></div>
                                                            )}
                                                            <div
                                                                className="productPrice"
                                                                style={(custom_theme && discount_price_color) ? {backgroundColor: discount_price_color} : {}}
                                                            >
                                                                {DynamicPriceFontSize(
                                                                    55,
                                                                    removeUnnecessaryDecimals(prod.variants[0].price),
                                                                    20
                                                                )}
                                                                <span>{prod.variants[0].priceV2.currencyCode}</span>
                                                            </div>
                                                            {(prod.variants[0].compareAtPrice != null) && (
                                                                <div
                                                                    className="comparePrice"
                                                                    style={(custom_theme && reqular_price_color) ? {backgroundColor: reqular_price_color} : {}}
                                                                >
                                                                    {DynamicPriceFontSize(
                                                                        41,
                                                                        removeUnnecessaryDecimals(prod.variants[0].compareAtPrice),
                                                                        9
                                                                    )}
                                                                    <span>{prod.variants[0].priceV2.currencyCode}</span>
                                                                </div>
                                                            )}
                                                        </>
                                                    )
                                                }
                                            </div>
                                            {LOADING
                                                ? (
                                                    <div className="product-toCart-skeleton-wrapper">
                                                        <Skeleton containerClassName="product-addToCart-skeleton" />
                                                    </div>
                                                )
                                                : (
                                                    <div
                                                        className={`productCartButton ${isOnCart ? "added": ""}`}
                                                        style={(custom_theme && shop_color  && isOnCart) ? {background: shop_color} : {}}
                                                    >
                                                        <button
                                                            className="addButton"
                                                            onClick={async () => {
                                                                if (isOnCart) {
                                                                    analyticsEvents.removeProductFromCart(shop.data.id, prod.id);
                                                                    await this.props.addRemoveItem("remove", isOnCart);
                                                                } else {
                                                                    analyticsEvents.addProductToCart(shop.data.id, 3, prod.id);
                                                                    await this.props.addRemoveItem("add", {product: prod, quantity: 1});
                                                                }
                                                            }}
                                                        >
                                                            <div
                                                                className={isOnCart ? "checkmark_icon" : "plus_icon"}
                                                                style={(custom_theme && shop_color && !isOnCart) ? {backgroundColor: shop_color} : {}}
                                                            ></div>
                                                        </button>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>  
                                )
                            })}
                        </div>
                    </div>
                )
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    disableOptions = (name, value) => {
        const disabledOpt = Object.assign({}, this.state.disabled);
        const { variations_data } = this.props.productVariations;
        let { variations, productPrice } = this.state;
        let disableAddToCartBtn = false;

        if (name && value) {
            name = name.toLowerCase();
                        
            let _selected_variation = variations_data.variations.filter(v => v.options[name] === value).filter(v => JSON.stringify(v.options) === JSON.stringify(variations));
            
            const _variations = variations_data.variations.filter(v => v.options[name] === value).map((option) => option.options);

            const _options = Object.keys(disabledOpt).filter(d => !d.includes(name));

            if (_options.length > 0) {
                Object.keys(disabledOpt[_options[0]]).forEach((option) => {
                    const opts = _variations.filter(v => v[_options[0]] === option);
                    if (opts.length > 0) {
                        opts.forEach(opt => {
                            disabledOpt[_options[0]] = {...disabledOpt[_options[0]], [opt[_options[0]]]: false};
                        });
                    } else {
                        disabledOpt[_options[0]] = {...disabledOpt[_options[0]], [option]: true};
                        if (variations && variations[_options[0]] && (variations[_options[0]] === option)) {
                            variations[_options[0]] = "";
                            disableAddToCartBtn = true;
                            productPrice = {
                                compareAtPrice: null,
                                price: null
                            };
                        }
                    }
                });
            }

            if (_selected_variation.length > 0) {
                const _variation = _selected_variation[0];
                _selected_variation = {
                    compareAtPrice: _variation.sale_price,
                    price: _variation.price
                };
            } else {
                _selected_variation = productPrice;
            }

            this.setState({
                disabled: disabledOpt,
                variations,
                disableAddToCartBtn,
                productPrice: _selected_variation
            });
        };
    }

    renderProduct = () => {
        let { variations, disabled, loading_variants, productPrice } = this.state;
        let { singleProduct, cart, shop, t, productVariations } = this.props,
            { custom_theme, shop_color, discount_price_color, reqular_price_color } = shop.data,
            change_color = (custom_theme && shop_color) || false;
        const loading = singleProduct.LOADING;
        const isOnCart = (cart?.lineItems && (cart?.lineItems?.length > 0) && singleProduct?.product_data?.variants) ? cart.lineItems.find(p => p.variant.id === singleProduct.product_data.variants[0].id) : false;
        if (singleProduct.ERROR === false) {
            if (this.productId && singleProduct.product_data.id && (singleProduct.product_data.id.toString() === this.productId.toString())) {
                if (singleProduct.product_data.compare_attr && productVariations.variations_data.compare_variations && loading_variants && !singleProduct.LOADING) {

                    Object.keys(singleProduct.product_data.compare_attr).forEach((attr) => {
                        if (!disabled[attr]) {
                            disabled[attr] = {}
                        }
                        singleProduct.product_data.compare_attr[attr].forEach((option) => {
                            if (productVariations.variations_data.compare_variations[attr].includes(option)) {
                                disabled[attr] = {...disabled[attr], [option]: false};
                            } else {
                                disabled[attr] = {...disabled[attr], [option]: true};
                            }
                        })
                    });
    
                    if (singleProduct.product_data.variants[0]) {
                        productPrice.compareAtPrice = singleProduct.product_data.variants[0].compareAtPrice || null;
                        productPrice.price = singleProduct.product_data.variants[0].price || null;
                    }
    
                    this.setState({
                        loading_variants: false,
                        disabled,
                        productPrice
                    });
    
                } else if (!singleProduct.product_data.compare_attr && !productVariations.variations_data.compare_variations && loading_variants && !singleProduct.LOADING) {
    
                    if (singleProduct.product_data.variants[0]) {
                        productPrice.compareAtPrice = singleProduct.product_data.variants[0].compareAtPrice || null;
                        productPrice.price = singleProduct.product_data.variants[0].price || null;
                    }
    
                    this.setState({
                        loading_variants: false,
                        productPrice
                    });
    
                }

            }
            return (
                <div className="productContainer">
                    <div className="productImage">
                        {loading
                            ? (<Skeleton containerClassName="single_product_image_skeleton"/>)
                            : (
                                <>
                                    <img src={singleProduct?.product_data.images[0]?.src || noImg} alt="product_image" />
                                    {(singleProduct?.product_data?.date_on_sale_from_gmt || singleProduct?.product_data?.date_on_sale_to_gmt) && (
                                        onSaleTo(
                                            {
                                                from: singleProduct?.product_data?.date_on_sale_from_gmt,
                                                to: singleProduct?.product_data?.date_on_sale_to_gmt
                                            },
                                            shop.data
                                        )
                                    )}
                                </>
                            )
                        }
                    </div>
                    <div className="singleProductPrice">
                        <div className="product-price-wrapper">
                            {loading
                                ? (<Skeleton containerClassName="single_product_price_skeleton"/>)
                                : (
                                    <>
                                        {((productPrice.compareAtPrice != null) && (productPrice.compareAtPrice > productPrice.price)) ? (
                                            <div
                                                className="discountPercentage"
                                                style={(change_color && reqular_price_color) ? {backgroundColor: reqular_price_color} : {}}
                                            >
                                                {calcPercentage(productPrice.compareAtPrice, productPrice.price)}
                                            </div>
                                        ) : (
                                            <div style={{height: "50px"}}></div>
                                        )}
                                        {(productPrice.price !== null) ? (
                                            <div
                                                className="productPrice"
                                                style={(change_color && discount_price_color) ? {backgroundColor: discount_price_color} : {}}
                                            >
                                                {DynamicPriceFontSize(
                                                    114,
                                                    removeUnnecessaryDecimals(productPrice.price),
                                                    42,
                                                    3
                                                )}
                                                <span>{singleProduct.product_data.variants[0].priceV2.currencyCode}</span>
                                            </div>
                                        ) : (
                                            <div style={{height: "50px"}}></div>
                                        )}
                                        {((productPrice.compareAtPrice != null) && (productPrice.compareAtPrice > productPrice.price)) && (
                                            <div
                                                className="comparePrice"
                                                style={(change_color && reqular_price_color) ? {backgroundColor: reqular_price_color} : {}}
                                            >
                                                {DynamicPriceFontSize(
                                                    50,
                                                    removeUnnecessaryDecimals(productPrice.compareAtPrice),
                                                    15
                                                )}
                                                <span>{singleProduct.product_data.variants[0].priceV2.currencyCode}</span>
                                            </div>
                                        )}
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div className="singleProductTitle">
                        {loading
                            ? (
                                <div className="title_skeleton_wrapper">
                                    <Skeleton containerClassName="single_product_title_skeleton"/>
                                </div>
                            )
                            : (
                                <div className="title_wrapper">
                                    <p className="title">{singleProduct.product_data.title}</p>
                                    <p className="vendor">{singleProduct.product_data?.vendor || ""}</p>
                                </div>
                            )
                        }
                    </div>
                    <div className="addToCartSingle">
                        {loading
                            ? (
                                <div className="single_product_cart_btn_skeleton">
                                    <Skeleton/>
                                </div>
                            )
                            : (
                                <button
                                    className={`addToCartButtonSingle ${isOnCart ? "added": ""}`}
                                    style={(change_color && shop_color)
                                            ? isOnCart
                                                ? {borderColor: shop_color, color: shop_color}
                                                : {backgroundColor: shop_color, borderColor: shop_color}
                                            : {}
                                    }
                                    onClick={() => {
                                        if (isOnCart) {
                                            analyticsEvents.removeProductFromCart(shop.data.id, this.productId);
                                            this.props.addRemoveItem("remove", isOnCart);
                                        } else {
                                            analyticsEvents.addProductToCart(shop.data.id, 3, this.productId);
                                            this.props.addRemoveItem("add", {product: singleProduct.product_data, quantity: 1});
                                        }
                                    }}
                                >
                                    {isOnCart ? t("added_to_cart") : t("add_to_card")}
                                </button>
                            )
                        }
                    </div>
                    {(productVariations.variations_data.on_sale && (productVariations.variations_data.on_sale.length > 0)) && (
                        <div className="on_sale_wrapper">
                            {productVariations.variations_data.on_sale.map((variation, i) => {
                                let onClickVals = {};
                                let btnText = "";
                                const attributes = variation.attributes;
                                attributes.forEach((attribute, i) => {
                                    btnText += attribute.option + (i < (attributes.length - 1) ? "/" : "");
                                    onClickVals[attribute.name.toLowerCase()] = attribute.option;
                                });
                                return (
                                    <button
                                    key={`v_${i}`}
                                        className="on_sale_btn"
                                        onClick={() => 
                                            this.setState({
                                                variations: onClickVals
                                            }, () => this.disableOptions(attributes[0].name, attributes[0].option))
                                        }
                                    >
                                        {btnText}
                                    </button>
                                );
                            })}
                        </div>
                    )}
                    {(singleProduct.product_data.attributes && (singleProduct.product_data.attributes.length > 0)) && (
                        <div className="product_variations">
                            {(singleProduct.product_data.attributes.length > 0) && (
                                singleProduct.product_data.attributes.map((attribute, i) => {
                                    return (
                                        <div key={i} className={`variation_${attribute.name.toLowerCase()}`}>
                                            <div className="title">{t(attribute.name)}</div>
                                            <div className={`${attribute.name.toLowerCase()}_wrapper`}>
                                                {attribute.options.map((option, i) => {
                                                    switch (attribute.name.toLowerCase()) {
                                                        case "size":
                                                            return (
                                                                <button
                                                                    key={`${attribute.name}_${i}`}
                                                                    className={`${attribute.name.toLowerCase()} ${(variations && variations[attribute.name.toLowerCase()] && (variations[attribute.name.toLowerCase()] === option)) ? "selected" : ""}`}
                                                                    onClick={() => {
                                                                        let changedVariations = variations;
                                                                        if (variations) {
                                                                            changedVariations = {...variations, [attribute.name.toLowerCase()]: option}
                                                                        } else {
                                                                            changedVariations = { [attribute.name.toLowerCase()]: option }
                                                                        }

                                                                        this.setState({ variations: changedVariations }, () => this.disableOptions(attribute.name, option));
                                                                    }}
                                                                    disabled={
                                                                        (disabled[attribute.name.toLowerCase()] && disabled[attribute.name.toLowerCase()][option])
                                                                            ? disabled[attribute.name.toLowerCase()][option] : false
                                                                    }
                                                                >
                                                                    {option}
                                                                </button>
                                                            );
                                                        case "color":
                                                            return (
                                                                <button
                                                                    key={`${attribute.name}_${i}`}
                                                                    className={`wrapper ${(variations && variations[attribute.name.toLowerCase()] && (variations[attribute.name.toLowerCase()] === option)) ? "selected" : ""}`}
                                                                    onClick={() => {
                                                                        let changedVariations = variations;
                                                                        if (variations) {
                                                                            changedVariations = {...variations, [attribute.name.toLowerCase()]: option}
                                                                        } else {
                                                                            changedVariations = { [attribute.name.toLowerCase()]: option }
                                                                        }

                                                                        this.setState({ variations: changedVariations }, () => this.disableOptions(attribute.name, option));
                                                                    }}
                                                                    disabled={
                                                                        (disabled[attribute.name.toLowerCase()] && disabled[attribute.name.toLowerCase()][option])
                                                                            ? disabled[attribute.name.toLowerCase()][option] : false
                                                                    }
                                                                >
                                                                    <div className="color" style={{background: option}}></div>
                                                                    <div className="text">{option}</div>
                                                                </button>
                                                            )
                                                        default:
                                                            return null;
                                                    }
                                                    
                                                })}
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    )}
                    {singleProduct.product_data.descriptionHtml && (
                        <div className="about_this_product">
                            <hr />
                            <h2>{t("about")}</h2>
                            <div dangerouslySetInnerHTML={{ __html: singleProduct.product_data.descriptionHtml }}></div>
                            <hr />
                        </div>
                    )}
                </div>
            );
        } else {
            return <ProductNotFound
                custom_theme={custom_theme}
                shop_color={shop_color}
            />;
        }
    }

    render() {
        const { shop, t } = this.props;
        return(
            <>
                <div className="productBodyContainer">
                    <div className="product-header">
                        <div className="go_back" onClick={() => history.goBack()}>
                            <div
                                className="angle_left_icon"
                                style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? {background: shop.data.shop_color} : {}}
                            ></div>
                            <span
                                style={(shop?.data?.custom_theme && shop?.data?.shop_color) ? {color: shop.data.shop_color} : {}}
                            >
                                {t("btn_back")}
                            </span>
                        </div>
                    </div>
                    {this.renderProduct()}
                    {this.renderRecomendedProducts()}
                </div>
                <TabBar />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        singleProduct: state.singleProduct,
        cart: state.cart,
        shop: state.shop,
        recommendedProducts: state.recommendedProducts,
        checkout: state.checkout,
        productVariations: state.productVariations
    };
};

export default connect(mapStateToProps, {
    fetchProduct,
    fetchRecommendedProducts,
    addRemoveItem,
    fetchCheckout
})(withRouter(withTranslation()(Product)));