import React from "react";
import { withTranslation } from "react-i18next";

import searchIcon from "./../images/empty-archive-img.svg";

import "./style.css";

class EmptyArchivePage extends React.Component {
    render() {
        const { t, custom_theme, shop_color } = this.props;
        return (
            <div className={`empty_archive_container ${custom_theme ? "custom_theme" : ""}`}>
                <div className="image_wrapper">
                    <div
                        className="bg_image"
                        style={(custom_theme && shop_color) ? { background: shop_color } : {}}
                    ></div>
                    <div className="empty_archive_img">
                        <img src={searchIcon} alt="Empty archive" />
                    </div>
                </div>
                <div className="text_wrapper">
                    <h1>{t("empty_archive_title")}</h1>
                    <div className="subtitle">
                        {t("empty_archive_desc")}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(EmptyArchivePage);