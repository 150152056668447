import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import moment from "moment";
import TextTruncate from "react-text-truncate";

import TabBar from "../../tabBar/tabBar";

import history from "../../history";

import no_image from "../../catalog/assets/img/no_img.png";

import "./style.css";

class ArchivedItems extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    getTotalSum = (items, saved = false, total = false) => {
        const ItemsPrice = {
            current_price: 0,
            saved: 0,
            currency: ""
        };

        items.forEach((item) => {
            ItemsPrice.current_price += item.variant.priceV2.amount ? (parseFloat(item.variant.priceV2.amount) * item.quantity) : 0;
            if (item.variant.compareAtPrice) {
                ItemsPrice.saved += (item.variant.compareAtPrice * item.quantity) - (item.variant.priceV2.amount * item.quantity);
            }
            ItemsPrice.currency = item.variant.priceV2.currencyCode;
        });

        if (saved) {
            return ItemsPrice.saved.toFixed(2).replace(/\.00$/,'') + " "+ ItemsPrice.currency;
        } else if (total) {
            return ItemsPrice.current_price.toFixed(2).replace(/\.00$/,'') + " " + ItemsPrice.currency;
        }
    }

    renderItems = (items) => {
        if (items) {
            const { shop, t } = this.props;
            return(
                <div className="items_container">
                    {items.map((item, index) => {
                        return(
                            <div className="item_wrapper" key={index}>
                                <div className="item_image">
                                    <span
                                        style={(shop.data.custom_theme && shop.data.shop_color) ? {background: shop.data.shop_color} : {}}
                                    >
                                        {item.quantity}
                                    </span>
                                    <img src={item?.variant?.image?.src || no_image} alt="item" />
                                </div>
                                <div className="item_description">
                                    <div className="title">
                                        <TextTruncate
                                            line={2}
                                            truncateText="..."
                                            text={item.title}
                                        />
                                    </div>
                                    <div className="price_date">
                                        <div className="price">
                                            {parseInt(item?.variant?.compareAtPrice) !== parseInt(item?.variant?.priceV2?.amount) && (
                                                <div className="regular">{item?.variant?.compareAtPrice  + " " + item?.variant?.priceV2?.currencyCode}</div>
                                            )}
                                            <div className="current">{item?.variant?.priceV2?.amount + " " + item?.variant?.priceV2?.currencyCode}</div>
                                        </div>
                                        <div className="date_wrapper">
                                            <div className="text">{t("date_completed")}</div>
                                            <div className="date">{moment(item.date_completed).format("DD MMMM YYYY HH:mm")}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        } else {
            return null;
        }
    }

    renderTotal = (items) => {
        const { shop, t } = this.props;
        return(
            <div className="total_container">
                <div
                    className="discount"
                    style={(shop.data.custom_theme && shop.data.shop_color) ? {color: shop.data.shop_color} : {}}
                >
                    <div className="text">{t("discount")}</div>
                    <div className="number">{items ? this.getTotalSum(items, true) : 0}</div>
                </div>
                <div className="total">
                    <div className="text">{t("total")}</div>
                    <div className="number">{items ? this.getTotalSum(items, false, true) : 0}</div>
                </div>
            </div>
        )
    }

    render() {
        const { t, shop, location } = this.props;
        return (
            <div className="order_items_container">
                <div className="header">
                    <div className="go_back" onClick={() => history.goBack()}>
                        <div
                            className="angle_left_icon"
                            style={(shop.data.custom_theme && shop.data.shop_color) ? {background: shop.data.shop_color} : {}}
                        ></div>
                        <span
                            style={(shop.data.custom_theme && shop.data.shop_color) ? {color: shop.data.shop_color} : {}}
                        >
                            {t("btn_back")}
                        </span>
                    </div>
                </div>
                <div className="body_container">
                    {this.renderItems(location?.state?.items || null)}
                    {this.renderTotal(location?.state?.items || null)}
                    <TabBar />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        shop: state.shop
    };
};

export default connect(mapStateToProps, {})(withRouter(withTranslation()(ArchivedItems)));