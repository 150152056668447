import React from "react";
import { withTranslation } from "react-i18next";
import TextTruncate from "react-text-truncate";

import featherIcon from "../../images/feather_icon.svg";

// Style
import "./style.css";

class DownloadAppBanner extends React.Component {

    downloadApp = () => {
        const url = (this.props.device === "iOS")
            ? `${process.env.REACT_APP_DOWNLOAD_iOS_APP}?shop_id=${this.props.shop_id}`
            : `${process.env.REACT_APP_DOWNLOAD_ANDROID_APP}&referrer=id%3D${this.props.shop_id}`
        ;
        window.open(url, "_blank")
    }

    render() {
        const { t, shop_icon, shop_name } = this.props;
        return (
            <div className="download_app_banner_wrapper">
                <div className="icon_name_wrapper">
                    <div
                        className="icon_wrapper"
                        style={shop_icon ? {backgroundImage: "none"} : {}}
                    >
                        <div
                            className="icon"
                            style={shop_icon
                                ? {
                                    backgroundImage: `url(${shop_icon})`,
                                    width: "35px",
                                    height: "35px"
                                } : {
                                    backgroundImage: `url(${featherIcon})`
                                }
                            }
                        ></div>
                    </div>
                    <div className="shop_name">
                        <TextTruncate
                            text={shop_name}
                            line={1}
                            truncateText="..."
                        />
                        <span>
                            {t("download_app")}
                        </span>
                    </div>
                </div>
                <div className="download_btn" onClick={this.downloadApp}>
                    {t("btn_download")}
                </div>
            </div>
        )
    }
}

export default withTranslation()(DownloadAppBanner);