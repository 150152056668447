import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import SlidingPane from "react-sliding-pane";

// Analytic events
import analyticsEvents from "../../analyticsEvents";

// Global functions
import { ShareOptions } from "./../../globalFunctions";

// Style
import "./assets/css/style.css";

class ShareOrderButton extends React.Component {

    constructor() {
        super();
        this.state = {
            showModal: false,
            isCoppied: false
        }
    }

    ShareModal = () => {
        const those = this;
        const { checkout, shop, t } = this.props;
        const { isCoppied } = this.state;
        const url = window.location.origin + "/?id=" + shop?.data?.id + "&cart=" + checkout?.id;
        const cartTitle = (shop?.data?.category && (shop?.data?.category?.id === 3)) ? t("share_cart_title_1") : t("share_cart_title");

        function copy(url) {
            const el = document.createElement("input");
            el.value = url;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);

            those.setState({
                isCoppied: true,
            }, () => {
                setTimeout(() => {
                    those.setState({
                        isCoppied: false
                    });
                }, 2000);
            });
        }

        function openLink(option, shareLink) {
            if (option.webUrl) {
                // Wait 5s and redirect to web if app was not opened
                const now = new Date().valueOf();
                setTimeout(() => {
                    if (!document.webkitHidden) {
                        if (new Date().valueOf() - now > 6000) {
                            return;
                        } else if (option.type === "viber") {
                            window.open(option.webUrl, (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) ? "_self" : "_blank");
                        } else {
                            window.open(`${option.webUrl}${shareLink}${option.textParam ? `&${option.textParam}=${encodeURIComponent(cartTitle)}` : ""}`, (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) ? "_self" : "_blank");
                        }
                    }
                }, 5000);
            }
            window.open(option.mobileUrl + shareLink, "_self");
        }

        return (
            <>
                <div
                    className="share_modal_container"
                >
                    <div className="share_modal_body">
                        <h3 className="title">{t("share_title")}</h3>
                        {(ShareOptions && (ShareOptions.length > 0)) && (
                            <div className="share_options_container">
                                {ShareOptions.map((option, index) => {
                                    switch (option.type) {
                                        case "copy":
                                            return (
                                                <div
                                                    className="share_option_wrapper"
                                                    key={index}
                                                >
                                                    <div
                                                        className={`share_option ${option.type}`}
                                                        onClick={() => copy(url)}
                                                        style={option.style ? option.style : {}}
                                                    >
                                                        <img
                                                            src={(isCoppied && option.successIcon) ? option.successIcon : option.icon}
                                                            className={(isCoppied && option.successIcon) ? "success": ""}
                                                            alt="share cart"
                                                        />
                                                    </div>
                                                    <div className="title">{isCoppied ? t("text_copied") : t(option.name)}</div>
                                                </div>
                                            )
                                        case "mail":
                                            return (
                                                <div
                                                    className="share_option_wrapper"
                                                    key={index}
                                                >
                                                    <div
                                                        className={`share_option ${option.type}`}
                                                        onClick={() => openLink(
                                                            option,
                                                            `subject=${option.title}&body=${cartTitle} ${encodeURIComponent(url)}`
                                                        )}
                                                        style={option.style ? option.style : {}}
                                                    >
                                                        <img src={option.icon} alt="share cart" />
                                                    </div>
                                                    <div className="title">{t(option.name)}</div>
                                                </div>
                                            )
                                        case "message":
                                            return (
                                                <div
                                                    className="share_option_wrapper"
                                                    key={index}
                                                >
                                                    <div
                                                        className={`share_option ${option.type}`}
                                                        onClick={() => {
                                                            openLink(
                                                                option,
                                                                cartTitle + " " + encodeURIComponent(encodeURIComponent(url))
                                                            )
                                                        }}
                                                        style={option.style ? option.style : {}}
                                                    >
                                                        <img src={option.icon} alt="share cart" />
                                                    </div>
                                                    <div className="title">{t(option.name)}</div>
                                                </div>
                                            )
                                        default:
                                            return (
                                                <div
                                                    className="share_option_wrapper"
                                                    key={index}
                                                >
                                                    <div
                                                        className={`share_option ${option.type}`}
                                                        onClick={() => openLink(
                                                            option,
                                                            `${option.includeText ? encodeURIComponent(cartTitle) + " " : ""}${encodeURIComponent(url)}`
                                                        )}
                                                        style={option.style ? option.style : {}}
                                                    >
                                                        <img src={option.icon} style={option.imageStyle ? option.imageStyle : {}} alt="share cart" />
                                                    </div>
                                                    <div className="title">{t(option.name)}</div>
                                                </div>
                                            )
                                    }
                                })}
                            </div>
                        )}
                    </div>
                    <div className="share_modal_footer">
                        <div
                            className="close_btn"
                            onClick={() => this.setState({ showModal: false })}
                            style={
                                (shop.data.custom_theme && shop.data.shop_color) ? { color: shop.data.shop_color } : {}
                            }
                        >
                            {t("btn_close")}
                        </div>
                    </div>
                    <div className="share_modal_hidden" style={{height: "55px", background: "transparent"}}></div>
                </div>
            </>
        );
    };

    render() {
        const { checkout, shop, t, disabled } = this.props;
        const { custom_theme, shop_color, id, category } = shop.data;
        const cartTitle = (category && (category?.id === 3)) ? t("share_cart_title_1") : t("share_cart_title");
        const data = {
            text: cartTitle,
            url: `${window.location.origin}/?id=${id}&cart=${checkout?.id}`,
            title: "Feather",
        };
        return (
            <div className="share_order_container">
                <>
                    <button
                        onClick={async () => {
                            analyticsEvents.shoppingListShared(id);
                            if (navigator.share) {
                                try {
                                    await navigator.share(data);
                                } catch (error) {
                                    console.error(`Share cart error: ${error}`);
                                }
                            } else {
                                this.setState({
                                    showModal: true
                                });
                            }
                        }}
                        disabled={disabled || !checkout?.id || !id}
                        className="share_btn"
                    >
                        <div
                            className="share_icon"
                            style={
                                custom_theme && shop_color ? { background: shop_color } : {}
                            }
                        ></div>
                    </button>
                    <SlidingPane
                        isOpen={this.state.showModal && ShareOptions && (ShareOptions.length > 0)}
                        from="bottom"
                        onRequestClose={() => true}
                        className="share_cart_modal_wrapper"
                        shouldCloseOnEsc={false}
                        
                    >
                        {this.ShareModal()}
                    </SlidingPane>
                </>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        shop: state.shop,
        checkout: state.checkout.data,
    };
};

export default connect(mapStateToProps, {})(withTranslation()(ShareOrderButton));
