const initialState = {
    data: []
}
const checkoutReducer = (state = initialState, action) => {
    if (action.type === "CREATE_CHECKOUT"){
        return {...state, data: action.payload};
    }

    if (action.type === "STORE_CHECKOUT_ITEMS"){
        return {...state, stored_items: action.payload}
    }

    return state;
}

export default checkoutReducer;