import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import storage from "redux-persist/lib/storage";
import thunk from 'redux-thunk';
import './index.css';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import App from './App';
import reducers from "./reducers/index";

import checkmarkCircleIcon from "./components/images/checkmark-circle-icon.svg";
import closeIcon from "./components/images/close-icon.svg";

// import i18n (needs to be bundled) 
import "./localization/i18n";

const options = {
  position: positions.MIDDLE,
  timeout: 2000,
  offset: "30px",
  transition: transitions.FADE,
}

const AlertTemp = ({ message, options, close }) => {
  switch (options.type) {
    case "custom":
      return (
        <div className="custom_alert">
          <img
            alt="checkmark"
            src={checkmarkCircleIcon}
          />
          <div>
            {message}
          </div>
        </div>
      );
    case "general_msg":
      return (
        <div className="general_msg_alert">
          <img src={message.shop_url} alt="Feather icon" />
          <div className="content">
            <span className="close_icon" onClick={close}>
              <img src={closeIcon} alt="Close icon" />
            </span>
            <div className="title">{message.title}</div>
            <div className="body">{message.body}</div>
          </div>
        </div>
      );
    default:
      return <div className="alert alertContainer">{message}</div>;
  }
};

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_STORAGE_KEY,
  onError: function (error) {
    console.log(error);
  }
});

const persistConfig = {
  key: 'persist',
  storage: storage,
  transforms: [encryptor],
  whitelist: ['checkout', 'vendor', 'shop']
};

const pReducer = persistReducer(persistConfig, reducers);
const middleware = applyMiddleware(thunk);
const store = createStore(pReducer, middleware);
const persistor = persistStore(store);

// const composeEnhancers = compose;
// const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <AlertProvider template={AlertTemp} {...options}>
        <App />
      </AlertProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

