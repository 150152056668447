import { apiData, getAPIToken } from "../../apis/apiData";
import { fetchProductsCustom } from "../../actions/products/index";
import { fetchCollectionsWithTopOffers, fetchCollections } from "../../actions/categories/index";
import { fetchCheckout } from "../checkout";

//Fetch - products
export const fetchShop = (shopId) => {
    return async function (dispatch, getState) {

        dispatch({
            type: "FETCH_SHOP_LOADING",
            loading: true,
            error: false,
        });


        let error = false;
        const token = sessionStorage.getItem("token");
        const resp = await apiData
            .get("v2/shop/get/" + shopId, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(token)}`
                }
            })
            .catch((err) => (error = true));

        if (!error && (resp.data.url && resp.data.url.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)) // eslint-disable-line
            && (resp.data.auth_key || resp.data.consumer_key)) {

            sessionStorage.setItem("lng", resp.data.language);

            dispatch({
                type: "FETCH_SHOP",
                payload: resp.data,
                loading: false,
                error: false,
            });

            dispatch({
                type: "SET_VENDOR",
                payload: resp.data.name
            });

            if (sessionStorage.getItem(resp.data.id)) {
                const checkout = JSON.parse(sessionStorage.getItem(resp.data.id));
                await dispatch({
                    type: "CREATE_CHECKOUT",
                    payload: checkout
                });

                dispatch(fetchCheckout());
            }

            if (!sessionStorage.getItem("token")) {
                await getAPIToken();
            }

            dispatch(fetchProductsCustom());
            if (resp.data.store_type === "Woocommerce") {
                if (resp.data.category && (resp.data.category.id === 3)) {
                    dispatch(fetchCollections());
                } else {
                    dispatch(fetchCollectionsWithTopOffers());
                }
            } else {
                dispatch(fetchCollections());
            }

            return {
                lng: resp.data.language
            };

        } else {

            dispatch({
                type: "FETCH_SHOP",
                payload: { id: resp?.data?.id || shopId },
                loading: false,
                error: true,
            });

        }
    }
};