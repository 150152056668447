import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import history from "../history";
import analyticsEvents from "../../analyticsEvents";

import ShoppingCart from "./assets/img/shopping_cart.svg";

import "./assets/css/tabBar.css";


class TabBar extends React.Component {

    componentDidMount() {
        setTimeout(() => {
            this.forceUpdate();
        }, 500);
    }

    render() {
        const cartItems = this.props.cart.lineItems ? this.props.cart.lineItems.length || 0 : 0,
            { activeTab, showShopOptions, t, shop, current_price, saved, currency } = this.props,
            { custom_theme, shop_color, category } = shop.data,
            change_color = (custom_theme && shop_color) || false;
        return (
            <div className="tabBarContainer">
                <div className="bgContainer">
                    {(activeTab === "cart") ? (
                        <>
                            {(cartItems > 0) && (
                                <div className="shop_ammount">
                                    <div className="total_wrapper">
                                        {t("total")}
                                        <span>{current_price} {currency}</span>
                                    </div>
                                    <div className="save_wrapper">
                                        {t("you_save")}
                                        <span style={change_color ? { color: shop_color } : {}}>
                                            {saved} {currency}
                                        </span>
                                    </div>
                                </div>
                            )}
                            <div
                                className="cartBg"
                            >
                                <div className="cartIcon" onClick={showShopOptions}>
                                    <img src={ShoppingCart} alt="cart" />
                                    <span
                                        key={Date.now()}
                                        className="number_of_items"
                                    >
                                        {(cartItems > 99) ? <>99<sup>+</sup></> : cartItems}
                                    </span>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div
                            className="cartBg"
                            style={change_color ? { background: shop_color } : {}}
                        >
                            <button
                                className="cartIcon"
                                onClick={() => {
                                    analyticsEvents.shoppingListShow(shop.data.id);
                                    history.push("/cart");
                                }}
                            >
                                <img src={ShoppingCart} alt="cart" />
                                <span
                                    key={Date.now()}
                                    style={(custom_theme && shop_color) ? { borderColor: shop_color } : {}}
                                >
                                    {cartItems > 99 ? <>99<sup>+</sup></> : cartItems}
                                </span>
                            </button>
                        </div>
                    )}
                    <div className="iconsContainer">
                        <div
                            className="icon_wrapper"
                            onClick={() => history.push("/")}
                        >
                            <div
                                className={`categoryIcon ${activeTab === "category" ? "active" : ""}`}
                                style={(change_color && (activeTab === "category")) ? { background: shop_color } : {}}
                            ></div>
                            <div
                                className={`label ${activeTab === "category" ? "active" : ""}`}
                                style={(change_color && (activeTab === "category")) ? { color: shop_color } : {}}
                            >
                                {(category && (category?.id === 3)) ? t("menu_menu") : t("menu_promotions")}
                            </div>
                        </div>
                        <div
                            className="icon_wrapper search"
                            onClick={() => {
                                analyticsEvents.productSearch(shop.data.id);
                                history.push("/search");
                            }}
                        >
                            <div
                                className={`searchIcon ${activeTab === "search" ? "active" : ""}`}
                                style={(change_color && (activeTab === "search")) ? { background: shop_color } : {}}
                            ></div>
                            <div
                                className={`label ${activeTab === "search" ? "active" : ""}`}
                                style={(change_color && (activeTab === "search")) ? { color: shop_color } : {}}
                            >
                                {t("menu_search")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart,
        shop: state.shop
    };
};

export default connect(mapStateToProps)(withTranslation()(TabBar));