const defaultValues = {
    ERROR: false,
    LOADING: true,
    product_data: {}
};

const singleProductReducer = (state = defaultValues, action) => {
    if (action.type === "FETCH_PRODUCT"){
        return {...state, product_data: action.payload};
    }

    if (action.type === "FETCH_PRODUCT_ERROR"){
        return { ...state, ERROR: action.payload };
    }

    if (action.type === "FETCHING_PRODUCT"){
        return { ...state, LOADING: action.payload };
    }

    return {...defaultValues, ...state};
}

export default singleProductReducer;