const initialState = {
    search_data: {
        total_pages: 0,
        products:[]
    },
    LOADING: false,
}

const searchProductsReducer = (state = initialState, action) => {
    if (action.type === "FETCH_SEARCH_PRODUCTS"){
        return { ...state, search_data: action.payload };
    }

    if (action.type === "FETCHING_SEARCH_PRODUCTS"){
        return { ...state, LOADING: action.payload };
    }

    return state;
}

export default searchProductsReducer;