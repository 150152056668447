import React from "react";

import history from "../../history";

import Koala from "./img/Koala.png";
import Wombat from "./img/Wombat.png";
import Turtle from "./img/Turtle.png";
import Platypus from "./img/Platypus.png";

import "./style.css";

export default class ModalWithBgImg extends React.Component {

    animal = null;

    componentDidMount() {
        document.body.style.overflow = "hidden";
        switch (this.props.product) {
            case "1":
                this.animal = {
                    img: Koala,
                    name: "Koala"
                };
                break;
            case "2":
                this.animal = {
                    img: Wombat,
                    name: "Wombat"
                };
                break;
            case "3":
                this.animal = {
                    img: Turtle,
                    name: "Turtle"
                };
                break;
            case "4":
                this.animal = {
                    img: Platypus,
                    name: "Platypus"
                };
                break;
            default:
                break;
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = "auto";
    }

    render() {
        return (
            <div id="modal_with_bg_img">
                {this.animal && (
                    <div className="modal_wrapper" style={{ backgroundImage: `url(${this.animal.img})` }}>
                        <div className="modal_header">
                            <div className="title">Congratulations!</div>
                            <div className="description">You have just helped save the life of a {this.animal.name}</div>
                        </div>
                        <div className="modal_footer">
                            <div
                                className="button"
                                onClick={() => {
                                    if (this.props.has_products) {
                                        this.props.closeModal();
                                        const url = new URL(window.location.href);
                                        url.searchParams.delete('show-popup');
                                        window.history.pushState(null, document.title, url);
                                    } else {
                                        history.push("/");
                                    }
                                }}
                            >
                                Back to products
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
